<div class="container">
    <div class="bouwapp-header">
        <div class="bouwapp-header__text">
            <h1>{{ 'smart_reporting.settings.title' | translate }}</h1>
            <p>{{ 'smart_reporting.settings.description' | translate }}</p>
        </div>
    </div>
</div>

<div class="container">
    <app-loader *ngIf="!projectList"></app-loader>
    <form *ngIf="!!projectList" [formGroup]="form" (submit)="submit($event)">
        <div *ngIf="formError" class="alert alert-danger">
            {{ 'smart_reporting.settings.form.save' | translate }}
        </div>
        <div *ngIf="formSubmitted" class="alert alert-success">
            {{ 'smart_reporting.settings.form.saved' | translate }}
        </div>

        <app-form-group [form]="form" formArrayName="projects">
            <label>{{ 'smart_reporting.settings.form.projects' | translate }}</label>

            <p>
                {{ 'smart_reporting.settings.form.projects.description' | translate }}
            </p>

            <div
                class="checkbox"
                *ngFor="let project of projectList; let index = index"
            >
                <input
                    type="checkbox"
                    id="project{{ project.id }}"
                    [formControlName]="index"
                />
                <label for="project{{ project.id }}">{{ project.name }}</label>
            </div>
        </app-form-group>

        <br />

        <button class="btn btn-primary btn-lg" [appLoading]="formLoading">
            {{ 'smart_reporting.settings.form.save' | translate }}
        </button>
    </form>
</div>
