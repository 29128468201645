import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QuillService {
  private uniqueIdentifier: number = 0;

  public getIdentifier() {
    this.uniqueIdentifier++;

    return this.uniqueIdentifier;
  }
}
