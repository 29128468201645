import { Component } from '@angular/core';
import { Project } from '../../../../interfaces/project';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ErrorService } from '../../../../services/error.service';
import { ProjectDataBusService } from '../../../../services/project-data-bus.service';
import * as moment from 'moment';
import { Announcement } from '../../../../interfaces/announcement';
import { AnnouncementService } from '../../../../services/announcement.service';
import { ProjectService } from '../../../../services/project.service';
import { PastTime } from '../../../../shared/past-time';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingDirective } from '../../../../directives/loading.directive';
import { TargetPickerComponent } from '../../../../components/target-picker.component';
import { MultiAttachmentControlComponent } from '../../../../components/multi-attachment-control.component';
import { DatePickerComponent } from '../../../../components/date-picker.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { QuillComponent } from '../../../../components/quill.component';
import { FormGroupComponent } from '../../../../components/form-group.component';
import { LoaderComponent } from '../../../../components/loader.component';
import { NgIf, NgFor } from '@angular/common';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';

@Component({
  selector: 'app-default-projects-detail-announcements-edit',
  templateUrl: 'edit.component.html',
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    FormChangeDetectorDirective,
    ReactiveFormsModule,
    FormGroupComponent,
    QuillComponent,
    NgFor,
    InlineSVGModule,
    DatePickerComponent,
    MultiAttachmentControlComponent,
    TargetPickerComponent,
    RouterLink,
    LoadingDirective,
    TranslateModule,
  ],
})
export class DetailAnnouncementsEditComponent {
  public project: Project;
  public edit = false;
  public announcementForm: FormGroup;
  public announcement: Announcement;
  public loading = false;
  public hasPush = false;
  public pushInPast = false;
  public uploading = false;
  public dateRangeValid = false;
  public phase: number;
  public pushSent: boolean = false;
  private id;

  public announcementTypes = [
    'ATTENTION',
    'HIGHLIGHT',
    'DETOUR',
    'ANNOUNCEMENT',
    'ROADBLOCK',
    'WARNING',
    'NUISANCE',
  ];

  constructor(
    private projectDataBusService: ProjectDataBusService,
    private projectService: ProjectService,
    private formBuilder: FormBuilder,
    private announcementService: AnnouncementService,
    private errorService: ErrorService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.createAnnouncementForm();
    this.projectDataBusService.projectObservable.subscribe(
      (project) => (this.project = project)
    );

    this.activatedRoute.params.subscribe((params) => {
      if (params['id'] != null) {
        this.id = params['id'];
        this.edit = true;

        this.loadAnnouncement(this.id);
      } else {
        this.id = null;
        this.edit = false;
      }
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.phase = params.phase;
    });

    window.scroll(0, 0);
  }

  private createAnnouncementForm(): void {
    this.announcementForm = this.formBuilder.group({
      title: ['', Validators.required],
      type: ['', Validators.required],
      content: ['', Validators.required],
      startDate: [moment().format(), Validators.required],
      endDate: [moment().format(), Validators.required],
      startType: ['EXACT_DATE', Validators.required],
      endType: ['EXACT_DATE', Validators.required],
      sendPush: [false],
      attachments: [[]],
      pushDateTime: [moment()],
      targets: [[]],
    });

    this.announcementForm.get('sendPush').valueChanges.subscribe((value) => {
      this.hasPush = value;
    });

    // check if push will be sent immediately
    this.announcementForm.valueChanges.subscribe((value) => {
      this.checkPushPast();
    });

    this.announcementForm
      .get('startDate')
      .valueChanges.subscribe((startDate) => {
        this.validateDatesForProject(
          startDate,
          this.announcementForm.get('endDate').value
        );
      });

    this.announcementForm.get('endDate').valueChanges.subscribe((endDate) => {
      this.validateDatesForProject(
        this.announcementForm.get('startDate').value,
        endDate
      );
    });
  }

  /**
   * Check if push is in the past
   */
  private checkPushPast(): void {
    this.pushInPast =
      PastTime.hasPast(this.announcementForm.get('pushDateTime').value) &&
      this.announcementForm.get('sendPush').value;
  }

  async saveAnnouncement(): Promise<void> {
    if (this.uploading) {
      return;
    }

    const oldPushInPast = this.pushInPast;

    this.errorService.markFormGroupTouchedAndDirty(this.announcementForm);
    this.validateDatesForProject(
      this.announcementForm.get('startDate'),
      this.announcementForm.get('endDate')
    );

    if (this.announcementForm.valid && this.dateRangeValid) {
      if (!oldPushInPast && this.pushInPast) {
        // show the user a quick warning
        return;
      }

      this.loading = true;

      try {
        const data = this.announcementForm.getRawValue();

        if (this.edit) {
          data.id = this.id;

          const announcement: Announcement =
            await this.announcementService.update(data);
        } else {
          const announcement: Announcement =
            await this.announcementService.create(this.phase, data);
        }

        this.router.navigate(['/projects', this.project.slug, 'phases']);
        this.projectService.updateLastEdited(this.project);
      } catch (error) {
        this.errorService.parseErrorsToForm(this.announcementForm, error.error);
      } finally {
        this.loading = false;
      }
    } else {
      this.errorService.scrollToInvalidFormGroup();
    }
  }

  async loadAnnouncement(id: number): Promise<void> {
    try {
      this.announcement = await this.announcementService.fetch(id);

      this.announcementForm.patchValue(this.announcement);
      this.pushSent =
        this.announcement.pushStatus === 'sending' ||
        this.announcement.pushStatus === 'sent';
    } catch (error) {
      this.router.navigate(['/404']);
    }
  }

  public startUploading() {
    this.uploading = true;
  }

  public stopUploading() {
    this.uploading = false;
  }

  public togglePush() {
    this.announcementForm.get('sendPush').patchValue(!this.hasPush);
  }

  /**
   * Validate announcement date ranges
   */
  public validateDatesForProject(startDate, endDate) {
    this.dateRangeValid = true;

    if (moment(endDate) < moment(startDate)) {
      const nextDay = moment(startDate).add(1, 'days').format();
      this.announcementForm.get('endDate').setValue(nextDay);

      this.dateRangeValid = false;
    }
  }
}
