<div class="app">
  <app-header></app-header>

  <main class="page">
    <section class="content">
      <div class="container">
        <h2>{{ 'page-not-found.title' | translate }}</h2>
        <div class="holder row">
          <div class="col-md-6">
            <p>
              {{ 'page-not-found.text' | translate }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>

  <app-footer></app-footer>
</div>
