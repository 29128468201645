import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { DatePickerComponent } from '../../../../components/date-picker.component';
import { FormGroupComponent } from '../../../../components/form-group.component';

@Component({
    selector: 'app-detail-general-planning',
    templateUrl: './detail-general-planning.component.html',
    styles: [],
    standalone: true,
    imports: [ReactiveFormsModule, FormGroupComponent, DatePickerComponent, NgIf, TranslateModule]
})
export class DetailGeneralPlanningComponent implements OnInit {
  @Input() dateRangeValid: boolean;
  @Input() generalForm: FormGroup;

  constructor() {}

  ngOnInit(): void {}
}
