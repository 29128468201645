import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import * as moment from 'moment';

export class PastTime {
  /**
   * Check if date has past
   * @param time
   * @returns {any}
   */
  public static hasPast(time: string): boolean {
    const now = moment();

    return now.isSameOrAfter(time);
  }
}
