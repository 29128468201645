import { enableProdMode, QueryList, ErrorHandler, APP_INITIALIZER, LOCALE_ID, importProvidersFrom } from '@angular/core';

import { loadLocale, storageFactory, createTranslateLoader } from './app/app.module';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClipboardModule } from 'ngx-clipboard';
import { DragulaModule } from 'ng2-dragula';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { FileUploadModule } from 'ng2-file-upload';
import { QuillModule } from 'ngx-quill';
import { NgxSpinnerModule } from 'ngx-spinner';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AngularIntlPhoneModule } from 'angular-intl-phone';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { AppRoutingModule } from './app/app-routing.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { SentryModule } from './app/shared/sentry.module';
import * as Sentry from '@sentry/angular'
import { NgChartsModule } from 'ng2-charts';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { OAuthStorage, OAuthModule } from 'angular-oauth2-oidc';
import { AuthInterceptor } from './app/interceptors/auth.interceptor';
import { OAuthInterceptor } from './app/interceptors/oauth.interceptor';
import { HeaderInterceptor } from './app/interceptors/header.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NewsService } from './app/services/news.service';
import { AiTextService } from './app/services/ai-text.service';
import { QuillService } from './app/services/quill.service';
import { NgPipesModule } from 'ngx-pipes';
import { ThemeService } from './app/services/theme.service';
import { ExportService } from './app/services/export.service';
import { SmartReportingService } from './app/services/smart-reporting.service';
import { ModuleService } from './app/services/module.service';
import { DateTypePipe } from './app/pipes/date-type.pipe';
import { DatePipe } from '@angular/common';
import { SurveyService } from './app/services/survey.service';
import { PhaseService } from './app/services/phase.service';
import { LocaleService } from './app/services/locale.service';
import { ChartJsService } from './app/services/chart-js.service';
import { TargetService } from './app/services/target.service';
import { LinkyPipe } from 'ngx-linky';
import { SatisfactionMonitorService } from './app/services/satisfaction-monitor.service';
import { TicketService } from './app/services/ticket.service';
import { CsvService } from './app/services/csv.service';
import { ProjectDocumentService } from './app/services/project-document.service';
import { StatisticsService } from './app/services/statistics.service';
import { RatingThemeService } from './app/services/rating-theme.service';
import { UploadService } from './app/services/upload.service';
import { ProjectService } from './app/services/project.service';
import { AccessService } from './app/services/access.service';
import { ProjectUserService } from './app/services/project-user.service';
import { CustomerService } from './app/services/customer.service';
import { DocumentCategoryService } from './app/services/document-category.service';
import { UserService } from './app/services/user.service';
import { ErrorService } from './app/services/error.service';
import { UserDataService } from './app/services/user-data.service';
import { LivestreamService } from './app/services/livestream.service';
import { TwoFactorService } from './app/services/two-factor.service';
import { UpdateService } from './app/services/update.service';
import { ProjectDataBusService } from './app/services/project-data-bus.service';
import { AnnouncementService } from './app/services/announcement.service';
import { PlatformService } from './app/services/platform.service';


if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, ReactiveFormsModule, NgChartsModule, SentryModule, NgxMapboxGLModule.withConfig({
            accessToken: environment.mapboxToken,
        }), TruncateModule, NgxWebstorageModule.forRoot({ prefix: 'bouwapp-project-user' }), InfiniteScrollModule, AppRoutingModule, InlineSVGModule.forRoot({ baseUrl: '' }), TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }), OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.apiUrl],
                sendAccessToken: false, // custom interceptor
            },
        }), AngularIntlPhoneModule, NgxSpinnerModule, QuillModule.forRoot({
            theme: 'snow',
        }), FileUploadModule, ProgressbarModule, DragulaModule.forRoot(), ClipboardModule, NgPipesModule, NgSelectModule),
        AnnouncementService,
        ProjectDataBusService,
        UpdateService,
        TwoFactorService,
        LivestreamService,
        UserDataService,
        ErrorService,
        UserService,
        DocumentCategoryService,
        CustomerService,
        ProjectUserService,
        AccessService,
        ProjectDataBusService,
        ProjectService,
        UploadService,
        RatingThemeService,
        StatisticsService,
        ProjectDocumentService,
        CsvService,
        TicketService,
        SatisfactionMonitorService,
        LinkyPipe,
        QueryList,
        TargetService,
        ChartJsService,
        LocaleService,
        PlatformService,
        PhaseService,
        SurveyService,
        DatePipe,
        DateTypePipe,
        ModuleService,
        SmartReportingService,
        ExportService,
        ThemeService,
        NgPipesModule,
        QuillService,
        AiTextService,
        NewsService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: LOCALE_ID,
            deps: [LocaleService],
            useFactory: loadLocale,
        },
        // {provide: ErrorHandler, useClass: AppErrorHandler},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OAuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        { provide: OAuthStorage, useFactory: storageFactory },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations()
    ]
});
