import { Injectable } from '@angular/core';
import { ProjectUser } from '../interfaces/project-user';
import { Project } from '../interfaces/project';
import { Announcement } from '../interfaces/announcement';
import { UserDataService } from './user-data.service';
import * as moment from 'moment';
import { ProjectUserPermissions } from '../interfaces/project-user-permissions';
import { CustomerService } from './customer.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CollectionResponse } from '../interfaces/collection-response';
import { environment } from '../../environments/environment';
import { Sort } from '../interfaces/sort';
import { DocumentCategory } from '../interfaces/document-category';
import { Target } from '../interfaces/target';
import { firstValueFrom, Subscription } from 'rxjs';

@Injectable()
export class ProjectService {
  static base = '/projects';
  private currentProject: Project;
  private listRequest: Subscription = null;
  private statusRequest: Subscription = null;

  constructor(
    private http: HttpClient,
    private userDataService: UserDataService
  ) {}

  /**
   * @param {number} page
   * @param {number} perPage
   * @param {string} sort
   * @param {string|null} search
   * @param customerIds
   * @param statuses
   * @returns {Promise<Project>}
   */
  public list(
    page: number,
    perPage: number = 10,
    sort: Sort = null,
    search?: string,
    statuses?: string[],
    customerIds: number[] | null = null
  ): Promise<CollectionResponse<Project>> {
    return new Promise((resolve, reject) => {
      this.listRequest?.unsubscribe();

      let params: HttpParams = new HttpParams();

      params = params.set('perPage', String(perPage));
      params = params.set('page', String(page));

      if (sort !== null) {
        params = params.set('order[' + sort.field + ']', sort.direction);
      }

      let urlEnd = '?';

      if (search != null && search !== '') {
        params = params.set('name', search);
      }

      if (statuses != null && statuses.length > 0) {
        for (const status of statuses) {
          urlEnd += `status[]=${status}&`;
        }
      }

      if (customerIds != null && customerIds.length > 0) {
        for (const customerId of customerIds) {
          urlEnd += `customer[]=${customerId}&`;
        }
      }

      this.listRequest = this.http
        .get<CollectionResponse<Project>>(
          environment.apiUrl + ProjectService.base + urlEnd.slice(0, -1),
          { params }
        )
        .subscribe({
          next: (response) => resolve(response),
          error: (error) => reject(error),
        });
    });
  }

  /**
   * @param {string} slug
   * @returns {Promise<Project>}
   */
  public fetch(slug: string): Promise<Project> {
    return this.http
      .get<Project>(environment.apiUrl + ProjectService.base + '/' + slug)
      .toPromise();
  }

  /**
   * @param {string} slug
   * @param {Project} data
   * @returns {Promise<Project>}
   */
  public update(slug: string, data: Project): Promise<Project> {
    (data as any).customer =
      '/api' + CustomerService.base + '/' + data.customer.id;

    return this.http
      .put<Project>(environment.apiUrl + ProjectService.base + '/' + slug, data)
      .toPromise();
  }

  public updateModules(slug: string, data: Project): Promise<Project> {
    return this.http
      .put<Project>(
        environment.apiUrl +
          ProjectService.base +
          '/' +
          slug +
          '/change-modules',
        { enabledModules: data.enabledModules }
      )
      .toPromise();
  }

  public updateDocumentCategorySortOrder(
    slug: string,
    data
  ): Promise<DocumentCategory[]> {
    return this.http
      .post(
        environment.apiUrl +
          ProjectService.base +
          '/' +
          slug +
          '/document-categories/sort',
        data
      )
      .toPromise()
      .then((response) => {
        return response as Promise<DocumentCategory[]>;
      });
  }

  public updateTargetSortOrder(slug: string, data): Promise<Target[]> {
    return this.http
      .post(
        environment.apiUrl + ProjectService.base + '/' + slug + '/targets/sort',
        data
      )
      .toPromise()
      .then((response) => {
        return response as Promise<Target[]>;
      });
  }

  /**
   * @param {Project} project
   * @returns {Promise<Project>}
   */
  public create(project: Project): Promise<Project> {
    const data: any = project;
    data.customer = '/api' + CustomerService.base + '/' + data.customer.id;

    return this.http
      .post<Project>(environment.apiUrl + ProjectService.base, data)
      .toPromise();
  }

  /**
   * @param {Project} project
   * @param data
   * @returns {Promise<Project>}
   */
  public updateSettings(project: Project, data: string[]): Promise<any> {
    return this.http
      .put(
        environment.apiUrl +
          ProjectService.base +
          '/' +
          project.slug +
          '/settings',
        data
      )
      .toPromise();
  }

  /**
   * @param {Project} project
   * @param data
   * @returns {Promise<Project>}
   */
  public updateTicketSettings(project: Project, data: string[]): Promise<any> {
    return this.http
      .put(
        environment.apiUrl +
          ProjectService.base +
          '/' +
          project.slug +
          '/settings/tickets',
        data
      )
      .toPromise();
  }

  /**
   * @returns {Promise<ProjectUser>}
   */
  public shortList(): Promise<CollectionResponse<Project>> {
    let params: HttpParams = new HttpParams();
    params = params.set('pagination', String(false));

    return this.http
      .get<CollectionResponse<Project>>(
        environment.apiUrl + ProjectService.base + '/short-list',
        { params }
      )
      .toPromise();
  }

  public getStatusCount(
    search?: string,
    customerIds: number[] | null = null
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.statusRequest?.unsubscribe();

      let params: HttpParams = new HttpParams();

      let urlEnd = '?';

      if (search != null && search !== '') {
        params = params.set('name', search);
      }

      if (customerIds != null && customerIds.length > 0) {
        for (const customerId of customerIds) {
          urlEnd += `customer[]=${customerId}&`;
        }
      }

      this.statusRequest = this.http
        .get<CollectionResponse<Project>>(
          environment.apiUrl +
            ProjectService.base +
            '/status-count' +
            urlEnd.slice(0, -1),
          { params }
        )
        .subscribe({
          next: (response) => resolve(response),
          error: (error) => reject(error),
        });
    });
  }

  public async updateLastEdited(project: Project) {
    project.lastChangedBy = await this.userDataService.retrieveProjectUser();
    project.updatedAt = moment().toISOString();
  }

  public setCurrentProject(project: Project) {
    this.currentProject = project;
  }

  public getCurrentProject(): Project {
    return this.currentProject;
  }
}
