import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-form-group',
    templateUrl: 'form-group.component.html',
    standalone: true,
    imports: [NgClass, NgIf, NgFor, TranslateModule]
})
export class FormGroupComponent implements OnInit {
  @Input() public form: FormGroup;
  @Input() public errorPath: string;
  @Input() public asText = true;
  @HostBinding('class') @Input() public cssClass: string = null;

  public control: AbstractControl;

  /**
   * @returns {void}
   */
  ngOnInit(): void {
    this.loadFormControl();
  }

  /**
   * @returns {void}
   */
  loadFormControl(): void {
    if (this.errorPath == null) {
      return;
    }

    const pathParts = this.errorPath.split('.');
    let control: AbstractControl = this.form;

    for (const pathPart of pathParts) {
      control = control.get(pathPart);
    }

    this.control = control;
  }

  /**
   * @returns {boolean}
   */
  hasInvalidState(): boolean {
    if (this.control == null) {
      return false;
    }

    return (
      this.control.errors != null && this.control.touched && this.control.dirty
    );
  }
}
