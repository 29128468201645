import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { UserDataService } from './user-data.service';
import { User } from '../interfaces/user';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ThemeService {
  private version: number;
  public cssLoadedSubject = new BehaviorSubject<number>(0);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private userDataService: UserDataService,
    private http: HttpClient
  ) {}

  loadCSS(fileName: string, version) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = fileName;
    link.onload = () => {
      this.cssLoadedSubject.next(version);
    };
    this.document.head.appendChild(link);
  }

  async setVersion(version: number) {
    this.version = version;
    this.removeVersionedCSS();

    try {
      const sentryConfig = await this.http
        .get('assets/config/config-map.json')
        .toPromise()
        .catch(() => null);

      const cssFiles = sentryConfig.styles;
      cssFiles.forEach((fileName: string) => {
        if (fileName.includes(`styles-v${version}`)) {
          this.loadCSS(fileName, version);
          return;
        }
      });
    } catch (error) {}
  }

  removeVersionedCSS() {
    const links = document.querySelectorAll('link[rel="stylesheet"]');
    links.forEach((link: HTMLLinkElement) => {
      if (link.href.includes('styles-v')) {
        document.head.removeChild(link);
      }
    });
  }

  async getVersion(): Promise<number> {
    if (this.version) return this.version;
    return new Promise(async (resolve, reject) => {
      if (this.version) resolve(this.version);
      else {
        let user: User = await this.userDataService.retrieveUser();
        if (!user.id) {
          this.userDataService.userLoaded.subscribe(async () => {
            user = await this.userDataService.retrieveUser();
            resolve(user.projectUser?.beta === true ? 2 : 1);
          });
        } else {
          resolve(user.projectUser?.beta === true ? 2 : 1);
        }
      }
    });
  }
}
