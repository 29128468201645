import { Injectable } from '@angular/core';
import { ProjectUser } from '../interfaces/project-user';
import { Customer } from '../interfaces/customer';
import { CollectionResponse } from '../interfaces/collection-response';
import { Project } from '../interfaces/project';
import { ProjectUserPermissions } from '../interfaces/project-user-permissions';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../interfaces/user';

@Injectable()
export class ProjectUserService {
  static base = '/project-users';

  constructor(private http: HttpClient) {}

  /**
   * @param {number} id
   * @returns {Promise<ProjectUser>}
   */
  public fetch(id: number): Promise<ProjectUser> {
    return this.http
      .get<ProjectUser>(environment.apiUrl + ProjectUserService.base + '/' + id)
      .toPromise();
  }

  /**
   * @param projectUser
   * @returns {Promise<ProjectUser>}
   */
  public update(projectUser: ProjectUser): Promise<ProjectUser> {
    return this.http
      .put<ProjectUser>(
        environment.apiUrl + ProjectUserService.base + '/' + projectUser.id,
        projectUser
      )
      .toPromise();
  }

  public fetchListForCustomer(
    customer: Customer,
    adminsOnly?: Boolean
  ): Promise<ProjectUser[]> {
    let url =
      environment.apiUrl +
      `/customers/${customer.id}${ProjectUserService.base}?pagination=false`;

    if (adminsOnly) {
      url += `&admins=true`;
    }

    return this.http.get<ProjectUser[]>(url).toPromise();
  }

  public fetchListForProject(
    project: Project
  ): Promise<ProjectUserPermissions[]> {
    return this.http
      .get<ProjectUserPermissions[]>(
        environment.apiUrl +
          `/projects/${project.slug}${ProjectUserService.base}?pagination=false`
      )
      .toPromise();
  }

  public fetchListForProjectUser(
    projectUser: ProjectUser
  ): Promise<ProjectUserPermissions[]> {
    return this.http
      .get<ProjectUserPermissions[]>(
        environment.apiUrl +
          `${ProjectUserService.base}/${projectUser.id}/projects?pagination=false`
      )
      .toPromise();
  }

  public check(email: string): Promise<User> {
    return this.http
      .post<User>(environment.apiUrl + ProjectUserService.base + '/check', {
        email,
      })
      .toPromise();
  }

  public create(projectUser: ProjectUser): Promise<ProjectUser> {
    projectUser.user.email = projectUser.user.email.toLowerCase();

    return this.http
      .post<ProjectUser>(
        environment.apiUrl + ProjectUserService.base,
        projectUser
      )
      .toPromise();
  }
}
