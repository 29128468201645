<div class="container">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <h1>{{ 'smart_reporting.notice_detail.title' | translate }}</h1>
    </div>
  </div>
</div>

<div class="container">
  <app-loader *ngIf="!notice || !actualProject"></app-loader>

  <div *ngIf="notice && actualProject" class="row">
    <div class="col-md-6">
      <h3>{{ 'smart_reporting.notice_detail.info' | translate }}</h3>
      <div class="smart-reporting__notice-detail__properties">
        <div class="smart-reporting__notice-detail__property">
          <div class="smart-reporting__notice-detail__label">
            {{ 'smart_reporting.notice_detail.info.project' | translate }}
          </div>
          {{ project?.name }}
        </div>
        <div class="smart-reporting__notice-detail__property">
          <div class="smart-reporting__notice-detail__label">
            {{ 'smart_reporting.notice_detail.info.created_at' | translate }}
          </div>
          {{ notice.createdAt | date : 'd MMM y' }}
        </div>
        <div class="smart-reporting__notice-detail__property">
          <div class="smart-reporting__notice-detail__label">
            {{ 'smart_reporting.notice_detail.info.status' | translate }}
          </div>

          <div
            class="smart-reporting__notice-detail__status-change"
            appAccess
            role="manager"
            [project]="actualProject"
            property="readonly"
          >
            <select
              [formControl]="statusControl"
              class="smart-reporting__notice-detail__status-change__control"
            >
              <option [value]="SmartReportingNoticeStatus.OPEN">
                {{ 'smart_reporting.notices.table.status.open' | translate }}
              </option>
              <option [value]="SmartReportingNoticeStatus.IN_PROGRESS">
                {{
                  'smart_reporting.notices.table.status.in_progress' | translate
                }}
              </option>
              <option [value]="SmartReportingNoticeStatus.CLOSED">
                {{ 'smart_reporting.notices.table.status.closed' | translate }}
              </option>
              <option [value]="SmartReportingNoticeStatus.DELETED">
                {{ 'smart_reporting.notices.table.status.deleted' | translate }}
              </option>
            </select>

            <span
              class="label smart-reporting__notice-detail__status-change__label"
              [class.label-primary]="
                SmartReportingNoticeStatus.OPEN === statusControl.value
              "
              [class.label-success]="
                SmartReportingNoticeStatus.CLOSED === statusControl.value
              "
              [class.label-info]="
                SmartReportingNoticeStatus.IN_PROGRESS === statusControl.value
              "
              [class.label-secondary]="
                SmartReportingNoticeStatus.DELETED === statusControl.value
              "
              >{{
                'smart_reporting.notices.table.status.' + statusControl.value
                  | translate
              }}</span
            >
          </div>
        </div>
        <div *ngIf="statusError" class="alert alert-danger">
          {{ 'smart_reporting.notice_detail.status.error' | translate }}
        </div>
        <div
          class="smart-reporting__notice-detail__property"
          *ngIf="notice.status === SmartReportingNoticeStatus.DELETED"
        >
          <div class="smart-reporting__notice-detail__label">
            {{
              'smart_reporting.notice_detail.info.removal_reason' | translate
            }}
          </div>
          {{ notice.removalReason }}
        </div>
        <div class="smart-reporting__notice-detail__property">
          <div class="smart-reporting__notice-detail__label">
            {{ 'smart_reporting.notice_detail.info.response_time' | translate }}
          </div>
          {{
            'smart_reporting.notice_detail.info.response_time.day' +
              (notice.theme.responseTimeDays !== 1 ? 's' : '')
              | translate : { days: notice.theme.responseTimeDays }
          }}
        </div>

        <div
          class="smart-reporting__notice-detail__property smart-reporting__notice-detail__property__separator"
        >
          <div class="smart-reporting__notice-detail__label">
            {{ 'smart_reporting.notice_detail.info.theme' | translate }}
          </div>
          {{ notice.theme.title }}
        </div>

        <div class="smart-reporting__notice-detail__property">
          <div class="smart-reporting__notice-detail__label">
            {{ 'smart_reporting.notice_detail.info.subscriptions' | translate }}
          </div>
          {{ notice.amountOfSubscribers }}
        </div>

        <div class="smart-reporting__notice-detail__property">
          <div class="smart-reporting__notice-detail__label">
            {{ 'smart_reporting.notice_detail.info.description' | translate }}
          </div>
          {{ notice.description }}
        </div>

        <div
          class="smart-reporting__notice-detail__property"
          *ngIf="notice.attachment"
        >
          <div class="smart-reporting__notice-detail__label">
            {{ 'smart_reporting.notice_detail.info.attachment' | translate }}
          </div>
          <div class="smart-reporting__notice-detail__property__image">
            <img
              [src]="getImage(notice)"
              (click)="viewPhotoModal.open(getImage(notice))"
            />
          </div>
        </div>

        <div
          class="smart-reporting__notice-detail__property smart-reporting__notice-detail__property__separator"
        >
          <div class="smart-reporting__notice-detail__label">
            {{ 'smart_reporting.notice_detail.info.name' | translate }}
          </div>
          {{ notice.name }}
        </div>
        <div class="smart-reporting__notice-detail__property">
          <div class="smart-reporting__notice-detail__label">
            {{ 'smart_reporting.notice_detail.info.phone_number' | translate }}
          </div>
          {{ notice.phoneNumber }}
        </div>
        <div class="smart-reporting__notice-detail__property">
          <div class="smart-reporting__notice-detail__label">
            {{ 'smart_reporting.notice_detail.info.email' | translate }}
          </div>
          <a [attr.href]="'mailto:' + notice.email">{{ notice.email }}</a>
        </div>

        <div
          *ngIf="notice.feedbackSolution > 0"
          class="smart-reporting__notice-detail__property smart-reporting__notice-detail__property__separator"
        >
          <div class="smart-reporting__notice-detail__label">
            {{
              'smart_reporting.notice_detail.info.feedbackSolution' | translate
            }}
          </div>
          <div
            [inlineSVG]="
              '/assets/img/icons/smiley' + notice.feedbackSolution + '.svg'
            "
            class="inline-svg__item smart-reporting__notice-detail__feedback"
          ></div>
        </div>
        <div
          *ngIf="notice.feedbackProcess > 0"
          class="smart-reporting__notice-detail__property"
        >
          <div class="smart-reporting__notice-detail__label">
            {{
              'smart_reporting.notice_detail.info.feedbackProcess' | translate
            }}
          </div>
          <div
            [inlineSVG]="
              '/assets/img/icons/smiley' + notice.feedbackProcess + '.svg'
            "
            class="inline-svg__item smart-reporting__notice-detail__feedback"
          ></div>
        </div>
      </div>

      <div class="smart-reporting__notice-detail__map">
        <mgl-map
          #map
          *ngIf="mapReady"
          class="map"
          [style]="'mapbox://styles/mapbox/streets-v11'"
          [zoom]="14"
          [maxZoom]="20"
          [center]="[notice.locationLong, notice.locationLat]"
          (mapLoad)="initMap()"
        >
          <mgl-control mglNavigation [showCompass]="false"></mgl-control>
        </mgl-map>
      </div>
    </div>
    <div class="col-md-6">
      <h3>{{ 'smart_reporting.notice_detail.responses' | translate }}</h3>

      <div class="smart-reporting__responses">
        <span *ngIf="notice.responses.length === 0">{{
          'smart_reporting.notice_detail.responses.empty' | translate
        }}</span>

        <div
          *ngFor="let response of responses"
          class="smart-reporting__responses__message"
          [class.smart-reporting__responses__message__response]="
            response.isResponse
          "
        >
          <div>
            <div class="smart-reporting__responses__message__top">
              <ng-container *ngIf="response.projectUser">
                {{ response.projectUser?.firstName }}
                {{ response.projectUser?.lastName }}
              </ng-container>
              <ng-container *ngIf="!response.projectUser">
                {{ notice.name }}
              </ng-container>
              <span
                class="smart-reporting__responses__message__separator"
              ></span>
              {{ response.createdAt | timeAgo }}
            </div>
            {{ response.message }}
          </div>
        </div>
      </div>

      <form
        appFormChangeDetector
        *ngIf="!isClosed"
        class="smart-reporting__notice-detail__write"
        [formGroup]="form"
        (ngSubmit)="submit()"
        appAccess
        role="manager"
        [project]="project"
      >
        <div *ngIf="error" class="alert alert-danger">
          {{ 'smart_reporting.notice_detail.responses.error' | translate }}
        </div>
        <div class="form-group">
          <textarea
            [attr.placeholder]="
              'smart_reporting.notice_detail.responses.placeholder' | translate
            "
            rows="3"
            class="smart-reporting__notice-detail__write__input"
            formControlName="message"
          ></textarea>
          <button
            type="submit"
            class="btn btn-primary smart-reporting__notice-detail__write__button"
            [appLoading]="loading"
          >
            {{ 'smart_reporting.notice_detail.responses.send' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>

  <app-modal
    #removalReasonModal
    [title]="'smart_reporting.notice_detail.removal_reason.title' | translate"
    (onClose)="closeRemoval()"
  >
    <p>
      {{
        'smart_reporting.notice_detail.removal_reason.description' | translate
      }}
    </p>

    <form
      appFormChangeDetector
      [formGroup]="removalForm"
      (ngSubmit)="submitRemoval()"
    >
      <app-form-group errorPath="removalReason" [form]="removalForm">
        <label>{{
          'smart_reporting.notice_detail.removal_reason.reason' | translate
        }}</label>
        <input type="text" formControlName="removalReason" />
      </app-form-group>

      <div class="form-group">
        <button type="submit" class="btn btn-primary" (click)="setSubmitted()">
          {{
            'smart_reporting.notice_detail.removal_reason.submit' | translate
          }}
        </button>
      </div>
    </form>
  </app-modal>

  <app-modal #viewPhotoModal [image]="true"></app-modal>
</div>
