import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { NgIf } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { VersionDirective } from '../directives/version.directive';

@Component({
  selector: 'app-modal',
  templateUrl: 'modal.component.html',
  standalone: true,
  imports: [InlineSVGModule, NgIf, VersionDirective],
})
export class ModalComponent {
  /**
   * @type {boolean}
   */
  @Input() isOpen = false;

  /**
   * @type {boolean}
   */
  @Input() image = false;

  /**
   * @type {string}
   */
  @Input() title;

  @Input() svg;

  @Input() subtitle: string;

  /**
   * @type {string}
   */
  @Input() scroll = true;

  /**
   * @type {string}
   */
  public imageUrl: string | SafeResourceUrl;

  @Output() public onClose = new EventEmitter<void>();

  /**
   * @returns {void}
   */
  close(): void {
    this.isOpen = false;

    this.onClose.emit();
  }

  /**
   * @param image
   * @returns {void}
   */
  open(image?: string | SafeResourceUrl): void {
    this.imageUrl = image;
    this.isOpen = true;
  }
}
