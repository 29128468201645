import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dateType',
    standalone: true
})
export class DateTypePipe implements PipeTransform {
  /**
   * @param datePipe
   */
  constructor(private datePipe: DatePipe) {}

  /**
   * @param date
   * @param parameters, first is type: can be Q1, Q2, Q3, Q4, MONTH, YEAR or EXACT_DATE
   */
  transform(date: string | Date, ...parameters: any[]): any {
    const type = parameters[0];
    const short: boolean = parameters[1] && parameters[1] === 'short';

    switch (type) {
      case 'Q1':
      case 'Q2':
      case 'Q3':
      case 'Q4':
        return type + ' ' + this.datePipe.transform(date, 'y');
      case 'MONTH':
        return this.datePipe.transform(date, short ? 'MMM' : 'MMM y');
      case 'YEAR':
        return this.datePipe.transform(date, 'y');
      case 'EXACT_DATE':
        return this.datePipe.transform(date, short ? 'd MMM' : 'd MMM y');
    }
  }
}
