<div class="bouwapp-header">
    <div class="bouwapp-header__text">
        <h1>{{ 'projects.detail.general.title' | translate }}</h1>
    </div>
</div>

<div class="nav-concept" *ngIf="isConcept">
    <div class="nav-concept__left">
        <div class="header__logo">
            <a routerLink="/">
                <img [src]="logo" />
            </a>
        </div>
    </div>
    <div class="nav-concept__right">
        <button
            class="button button--m button--flat bg--orange margin"
            [appLoading]="loading"
            (click)="saveConcept(false, true)"
        >
            {{ 'projects.detail.concept.save' | translate }}
        </button>
    </div>
</div>
<section
    [hidden]="isSuccess"
    class="general projects-detail"
    [class.is-concept]="isConcept && currentIndex < steps.length"
>
    <form
        #formRef="ngForm" ngForm
        appFormChangeDetector
        [formGroup]="generalForm"
        appAccess
        role="manager"
        [project]="project"
        property="readonly"
    >
        <app-success-message [hidden]="!generalFormSaved" [onScreen]="true">
            {{ 'projects.detail.general.success' | translate }}
        </app-success-message>

        <!-- 01 General -->
        <div class="bouwapp-group" [hidden]="isConcept && ['info', 'summary'].indexOf(steps[currentIndex]) == -1">
            <div class="bouwapp-group__header">
                <h2>{{ 'projects.detail.general.step.title' | translate }}</h2>
            </div>

            <app-detail-general-info
                (startUploading)="startUploading()"
                (stopUploading)="stopUploading($event)"
                [isConcept]="isConcept"
                [project]="project"
                [generalForm]="generalForm"
            ></app-detail-general-info>
        </div>

        <!-- 02 Planning -->
        <div class="bouwapp-group" [hidden]="isConcept && ['planning', 'summary'].indexOf(steps[currentIndex]) == -1">
            <div class="bouwapp-group__header">
                <h2>{{ 'projects.detail.general.planning.title' | translate }}</h2>
                <p>{{ 'projects.detail.general.planning.subtitle' | translate }}</p>
            </div>

            <div class="bouwapp-box__self">
                <app-detail-general-planning
                    [dateRangeValid]="dateRangeValid"
                    [generalForm]="generalForm"
                ></app-detail-general-planning>
            </div>
        </div>

        <!-- 03 Location -->
        <div *ngIf="project" class="bouwapp-group"
             [hidden]="isConcept && ['location', 'summary'].indexOf(steps[currentIndex]) == -1">
            <div class="bouwapp-group__header">
                <h2>{{ 'projects.detail.general.location.title' | translate }}</h2>
                <p>{{ 'projects.detail.general.location.subtitle' | translate }}</p>
            </div>
            <div class="bouwapp-box__self">
                <app-detail-general-location
                    *ngIf="!(isConcept && ['location', 'summary'].indexOf(steps[currentIndex]) == -1)"
                    [project]="project"
                    [generalForm]="generalForm"
                ></app-detail-general-location>
            </div>
        </div>

        <ng-container *ngIf="isConcept">
            <!-- 04 Contact -->
            <div class="bouwapp-group" *ngIf="['contact', 'summary'].indexOf(steps[currentIndex]) > -1">
                <div class="bouwapp-group__header">
                    <h2>{{ 'project.detail.contact.title' | translate }}</h2>
                </div>
                <div class="bouwapp-box__self">
                    <app-detail-general-contact
                        [withSave]="false"
                    ></app-detail-general-contact>
                </div>
            </div>

            <!-- 05 Targets -->
            <div class="bouwapp-group" *ngIf="['targets', 'summary'].indexOf(steps[currentIndex]) > -1">
                <div class="bouwapp-group__header">
                    <h2>{{ 'project.detail.targets.title' | translate }}</h2>
                </div>
                <div class="bouwapp-box__self">
                    <app-detail-general-targets
                        [project]="project"
                        [step]="currentIndex"
                    ></app-detail-general-targets>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="!isConcept">
            <!-- 04 Documents -->
            <div class="bouwapp-group">
                <div class="bouwapp-group__header">
                    <h2>{{ 'projects.detail.general.documents.title' | translate }}</h2>
                    <p>{{ 'projects.detail.general.documents.subtitle' | translate }}</p>
                </div>
                <div class="bouwapp-box__self">
                    <app-default-projects-detail-documents></app-default-projects-detail-documents>
                </div>
            </div>

            <!-- 05 Projects -->
            <div class="bouwapp-group">
                <div class="bouwapp-group__header">
                    <h2>{{ 'projects.detail.general.projects.title' | translate }}</h2>
                    <p>{{ 'projects.detail.general.projects.subtitle' | translate }}</p>
                </div>

                <ng-container *ngIf="!project.enabledModules?.includes(Module.CHILD_PROJECTS)">
                    <div class="bouwapp-box__self">
                        <p
                            [innerHTML]="
                'projects.detail.general.projects.disabled' | translate
              "
                        ></p>
                    </div>
                </ng-container>
                <ng-container
                    *ngIf="project.enabledModules?.includes(Module.CHILD_PROJECTS)"
                >
                    <div class="bouwapp-box__self" *ngIf="!project.parentProject">
                        <app-default-projects-detail-projects></app-default-projects-detail-projects>
                    </div>
                    <div class="bouwapp-box__self" *ngIf="project.parentProject">
                        <div class="project-child__empty">
                            <p
                                [innerHTML]="
                  'projects.detail.general.projects.is_child_project'
                    | translate : { name: project.parentProject.name }
                "
                            ></p>
                            <div
                                appAccess
                                role="viewer"
                                [project]="project.parentProject"
                                class="button button--m button--outlined bg--grey--hover"
                                (click)="openProject(project.parentProject)"
                            >
                                {{ 'projects.detail.general.projects.go_to' | translate }}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <!-- 06 WFS -->
            <div class="bouwapp-group"
                 *ngIf="(!isConcept && project.enabledModules?.includes(Module.WFS)) || (isConcept && project.customer.enabledModules?.includes(Module.WFS))">
                <div class="bouwapp-group__header">
                    <h2>{{ 'projects.detail.general.wfs.title' | translate }}</h2>
                    <p>{{ 'projects.detail.general.wfs.subtitle' | translate }}</p>
                </div>

                <app-detail-general-wfs
                    [generalForm]="generalForm"
                    [project]="project"
                ></app-detail-general-wfs>
            </div>
        </ng-container>

        <!-- Actionbar -->
        <div class="bouwapp-bottombar" appAccess role="manager" [project]="project">
            <div class="bouwapp-bottombar__creation" *ngIf="isConcept">
                <p>
                    <span>0{{ currentIndex + 1 }}</span>
                    {{
                        'projects.detail.concept.step.' + steps[currentIndex] | translate
                    }}
                </p>

                <div class="bouwapp-bottombar__creation__progress">
                    <p>
                        {{
                            'projects.detail.concept.step.process'
                                | translate
                                : { currentStep: currentIndex + 1, totalSteps: steps.length }
                        }}
                        -
                        <span
                        >{{
                                ((currentIndex + 1) / steps.length) * 100 | number : '1.0-0'
                            }}%
                            {{ 'projects.detail.concept.step.completed' | translate }}</span
                        >
                    </p>
                    <progressbar
                        [value]="((currentIndex + 1) / steps.length) * 100"
                    ></progressbar>
                </div>

                <div class="bouwapp-bottombar__creation__button">
                    <button
                        class="button button--m button--flat bg--grey"
                        [appLoading]="loading || uploading"
                        color="dark"
                        *ngIf="currentIndex + 1 < steps.length"
                        (click)="saveConcept(true, false)"
                    >
                        {{ 'projects.detail.concept.step.next' | translate }}
                    </button>

                    <button
                        class="button button-m button--ghost bg--grey--hover"
                        [appLoading]="loading || uploading"
                        *ngIf="currentIndex + 1 == steps.length"
                        (click)="saveConcept(false, true)"
                    >
                        {{ 'projects.detail.concept.save' | translate }}
                    </button>

                    <button
                        class="button button--m button--flat bg--orange"
                        [appLoading]="loading || uploading"
                        *ngIf="currentIndex + 1 == steps.length"
                        (click)="save()"
                    >
                        {{ 'projects.detail.concept.save_unpublished' | translate }}
                    </button>
                </div>
            </div>

            <button
                *ngIf="!isConcept"
                class="button button--m button--flat bg--orange"
                [appLoading]="loading || uploading"
                (click)="saveGeneral()"
            >
                {{ 'projects.detail.general.save' | translate }}
            </button>
        </div>
    </form>
</section>

<!-- Success -->
<section *ngIf="isSuccess" class="project-success">
    <div class="project-success__close" [routerLink]="['/']">
        <span [inlineSVG]="'/assets/img/icons/close--dark.svg'"></span>
    </div>
    <div class="project-success__inner">
        <img [src]="logo" width="212px" height="42" alt="Logo" />
        <h2>{{ 'projects.detail.concept.success.title' | translate }}</h2>
        <p
            [innerHTML]="
        'projects.detail.concept.success.description'
          | translate : { projectName: project.name }
      "
        ></p>
        <div class="flex buttons">
            <button
                class="button button--l button--flat bg--orange"
                [routerLink]="['/projects', project.slug, 'updates']"
            >
                {{ 'projects.detail.concept.success.link.updates' | translate }}
            </button>
            <button
                class="button button--l button--ghost bg--grey--hover"
                [routerLink]="['/']"
            >
                {{ 'projects.detail.concept.success.link.overview' | translate }}
            </button>
        </div>
    </div>
</section>

<app-modal
    [title]="'projects.detail.concept.preview.title' | translate"
    #previewModal
    class="project-previewModal"
>
    <p class="modal-text">
        {{ 'projects.detail.concept.preview.description' | translate }}
    </p>
    <input
        #codeToCopy
        type="text"
        class="input input--m"
        readonly
        [value]="previewUrl"
        [placeholder]="'projects.detail.concept.preview.save' | translate"
    />
    <div class="flex buttons">
        <button
            [disabled]="!project.id"
            (click)="openPreview()"
            class="button button--m button--flat bg--orange"
        >
            {{ 'projects.detail.concept.preview.title' | translate }}
        </button>
        <button
            [disabled]="!project.id"
            [ngxClipboard]="codeToCopy"
            (cbOnSuccess)="textCopied()"
            class="button button--m button--flat bg--grey"
            [ngClass]="{ isCopied: copied }"
        >
      <span
          [inlineSVG]="'/assets/img/icons/check.svg'"
          [hidden]="!copied"
      ></span>
            {{ 'projects.detail.concept.preview.copy' | translate }}
        </button>
    </div>
</app-modal>
