import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectService } from '../../../services/project.service';
import { Project } from '../../../interfaces/project';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { Customer } from '../../../interfaces/customer';
import { ProjectDataBusService } from '../../../services/project-data-bus.service';
import { ModalComponent } from '../../../components/modal.component';
import { ProjectStatus } from 'src/app/enums/project-status';
import { Module } from 'src/app/enums/module';
import { AccessService } from 'src/app/services/access.service';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { AccessDirective } from '../../../directives/access.directive';
import { LoaderComponent } from '../../../components/loader.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'app-default-projects-detail',
  templateUrl: 'detail.component.html',
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    NgClass,
    RouterLink,
    AccessDirective,
    InlineSVGModule,
    RouterOutlet,
    TranslateModule,
  ],
})
export class DetailComponent implements OnInit {
  public project: Project;

  /**
   * @type {string}
   */
  private slug: string;

  /**
   * @type {string}
   */
  public currentTab = 'general';

  /**
   * @type {boolean}
   */
  public create = false;

  /**
   * @type {ModalComponent}
   */
  @ViewChild('invite') inviteModal: ModalComponent;

  public projectCustomer: Customer;
  ProjectStatus = ProjectStatus;
  public Module = Module;

  /**
   * @param projectService
   * @param router
   * @param userDataService
   * @param projectDataBusService
   * @param route
   * @param errorService
   */
  constructor(
    private projectService: ProjectService,
    private router: Router,
    private projectDataBusService: ProjectDataBusService,
    private route: ActivatedRoute,
    private accessService: AccessService
  ) {
    this.route.queryParams.subscribe(async (params) => {
      if (params['customerId'] != null) {
        this.accessService.accessControlList.subscribe((list) => {
          if (list == null) {
            return;
          }

          let customers = [];

          const mappings = list.mappings.map((m) => m.project.customer);

          customers = list.customers.map((item) => {
            return item.customer;
          });

          if (undefined !== mappings) {
            customers = [...customers, ...mappings];
          }

          this.projectCustomer = customers.find(
            (item) => item.id === parseInt(params['customerId'])
          );

          if (!this.projectCustomer) {
            this.router.navigate(['/404']);
            return;
          }
        });
      }
    });

    this.route.params.subscribe((params) => {
      if (params['slug'] != null) {
        this.slug = params['slug'];

        this.loadProject(this.slug);
      } else {
        this.create = true;
        this.projectDataBusService.emitIsConcept(true);
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const matches = event.url.match(
          /^\/projects\/([a-zA-Z0-9-]+)\/([a-zA-Z0-9-]+)/
        );

        if (matches) {
          this.currentTab = matches[2];
        } else {
          this.currentTab = 'general';
        }
      }
    });
  }

  /**
   * @returns {Promise<void>}
   */
  async ngOnInit(): Promise<void> {
    if (this.create) {
      this.createEmtpyProject();
    }
  }

  /**
   * @returns boolean
   */
  isActive(tab: string): boolean {
    return this.currentTab === tab;
  }

  /**
   * @returns Promise<void>
   */
  async createEmtpyProject(): Promise<void> {
    const customer: Customer = this.projectCustomer;

    this.project = {
      name: '',
      slug: '',
      status: 'CONCEPT',
      description: '',
      participants: customer.name,
      customer,
      create: true,
      projectUsers: [],
      chatPlaceholderName: '',
      chatPlaceholderFunction: '',
      chatPlaceholderPhoneNumber: '',
      chatEnabled: true,
      openTicketCount: 0,
      startType: 'EXACT_DATE',
      endType: 'EXACT_DATE',
      isCompact: false,
      childrenProjects: [],
      hasParentProject: false,
      isParentProject: false,
    };

    this.projectDataBusService.emitProject(this.project);
  }

  /**
   * Loads one projects
   * @param {string} slug
   * @returns {Promise<void>}
   */
  async loadProject(slug: string): Promise<void> {
    try {
      this.project = await this.projectService.fetch(slug);

      this.projectDataBusService.emitProject(this.project);
      this.projectDataBusService.emitIsConcept(
        this.project.status == ProjectStatus.CONCEPT
      );
    } catch (error) {
      this.router.navigate(['/404']);
    }
  }

  ngOnDestroy() {
    this.projectDataBusService.emitIsConcept(false);
  }
}
