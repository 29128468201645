import { Component } from '@angular/core';
import { Project } from '../../../interfaces/project';
import { ProjectDataBusService } from '../../../services/project-data-bus.service';
import { TranslateModule } from '@ngx-translate/core';
import { DetailGeneralTargetsComponent } from './detail-general/detail-general-targets.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-default-projects-detail-targets',
  templateUrl: './detail-targets.component.html',
  standalone: true,
  imports: [DetailGeneralTargetsComponent, TranslateModule, NgIf],
})
export class DetailTargetsComponent {
  public project: Project;

  constructor(private projectDataBusService: ProjectDataBusService) {
    this.projectDataBusService.projectObservable.subscribe(
      (project) => (this.project = project)
    );
  }
}
