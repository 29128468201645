<div class="input-file">
  <div class="input-file__add" *ngIf="!uploading && !isLoading">
    <span inlineSVG="/assets/img/icons/add.svg"></span>
    {{ 'projects.detail.update.media.media.add' | translate }}
  </div>
  <div class="input-file__replace">
    <span inlineSVG="/assets/img/icons/edit.svg"></span>
  </div>

  <progressbar [value]="item.progress" [striped]="true" *ngIf="uploader?.queue[0] as item">
    {{ item.progress || 1 }}%
  </progressbar>

  <div class="input-file-loader" [hidden]="!isLoading">
    <i class="spinner"></i>
  </div>
  <input
    [disabled]="isLoading || uploading"
    type="file"
    [id]="'media' + type"
    [accept]="getMimeTypes()"
    (change)="upload($event.target.files)"
    #inputFile
  />
</div>
