import { Component, Input, OnChanges } from '@angular/core';
import { Customer } from 'src/app/interfaces/customer';
import { ProjectUser } from 'src/app/interfaces/project-user';
import { UserDataService } from 'src/app/services/user-data.service';
import { ManageUsersService } from './manage-users.service';
import { PermissionType } from 'src/app/enums/permission-type';
import { Project } from 'src/app/interfaces/project';
import { LogEntry } from 'src/app/interfaces/log-entry';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LoaderComponent } from '../loader.component';
import { NgIf, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'app-list-users',
    templateUrl: 'list-users.component.html',
    standalone: true,
    imports: [NgIf, LoaderComponent, NgFor, InlineSVGModule, DatePipe, TranslateModule]
})
export class ListUsersComponent implements OnChanges {
  @Input() projectUsers: ProjectUser[];
  @Input() customer: Customer;
  @Input() loading: boolean;
  @Input() project: Project;
  @Input() editAdmins: boolean = true;

  public projectUser: ProjectUser;

  constructor(
    private userDataService: UserDataService,
    private manageUsersService: ManageUsersService
  ) {
    this.retrieveUser();
  }

  ngOnChanges(): void {
    if (this.project && this.projectUsers) {
      this.projectUsers.forEach(
        (pu: ProjectUser) =>
          (pu.lastLogEntry = pu.lastLogEntryPerProject.find(
            (logEntry: LogEntry) => logEntry.project?.id === this.project.id
          ))
      );
    }
  }

  async retrieveUser() {
    this.projectUser = await this.userDataService.retrieveProjectUser();
  }

  public triggerDelete(projectUser: ProjectUser) {
    this.manageUsersService.triggerDeleteEvent(projectUser);
  }
  public hasPermission(user: ProjectUser) {
    const userWithPermission = this.projectUsers.find(
      (user) => user.id === this.projectUser.id
    );
    const permission = this.manageUsersService.determinePermission(
      userWithPermission,
      this.customer
    );

    if (permission === PermissionType.ACCOUNT_ADMIN) {
      return (
        this.editAdmins ||
        this.manageUsersService.determinePermission(user, this.customer) !==
          PermissionType.ACCOUNT_ADMIN
      );
    }

    if(permission === PermissionType.PROJECT_ADMIN){
      return ![PermissionType.PROJECT_ADMIN, PermissionType.ACCOUNT_ADMIN].includes(this.manageUsersService.determinePermission(user, this.customer));
    }

    return true;
  }

  public triggerEdit(projectUser: ProjectUser) {
    this.manageUsersService.triggerEditEvent(projectUser);
  }

  public isEmpty(): boolean {
    return !this.projectUsers?.length;
  }

  public getIconForPermission(user: ProjectUser): {
    icon: string;
    type: string;
  } {
    const permission = this.manageUsersService.determinePermission(
      user,
      this.customer
    );
    return { icon: `/assets/img/icons/${permission}.svg`, type: permission };
  }
}
