import { Injectable } from '@angular/core';
import { Project } from '../interfaces/project';
import { ProjectService } from './project.service';
import { Subscription } from 'rxjs';
import { Target } from '../interfaces/target';
import { SatisfactionMonitorService } from './satisfaction-monitor.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class StatisticsService {
  private resultRequest: Subscription;
  private conversationResultRequest: Subscription;

  constructor(private http: HttpClient) {}

  public results(
    project: Project,
    period,
    target: Target | null
  ): Promise<Project> {
    if (this.resultRequest != null) {
      this.resultRequest.unsubscribe();
    }

    return new Promise(async (resolve, reject) => {
      let params = SatisfactionMonitorService.getParamsForPeriod(period); // we're using a string here because URLSearchParams doesn't encode + properly

      if (target != null) {
        params += '&target=' + target.id;
      }

      this.resultRequest = this.http
        .get<Project>(
          environment.apiUrl +
            ProjectService.base +
            '/' +
            project.slug +
            '/statistics' +
            params
        )
        .subscribe(
          (value) => {
            resolve(value);
          },
          (error) => reject(error)
        );
    });
  }

  public conversationResults(
    project: Project,
    period,
    filter = null
  ): Promise<Project> {
    if (this.conversationResultRequest != null) {
      this.conversationResultRequest.unsubscribe();
    }

    return new Promise(async (resolve, reject) => {
      let params = SatisfactionMonitorService.getParamsForPeriod(period); // we're using a string here because URLSearchParams doesn't encode + properly

      if (filter != null) {
        if (filter.target) {
          for (const target of filter.target) {
            params += '&target[]=' + target;
          }
        }

        if (filter.types) {
          for (const type of filter.types) {
            params += '&conversationType[]=' + type;
          }
        }

        if (filter.timeType) {
          params += '&timeType=' + filter.timeType;
        }
      }

      this.conversationResultRequest = this.http
        .get<Project>(
          environment.apiUrl +
            ProjectService.base +
            '/' +
            project.slug +
            '/statistics/conversations' +
            params
        )
        .subscribe(
          (value) => {
            resolve(value);
          },
          (error) => reject(error)
        );
    });
  }

  public demography(project: Project, period): Promise<any> {
    const params = SatisfactionMonitorService.getParamsForPeriod(period); // we're using a string here because URLSearchParams doesn't encode + properly

    return this.http
      .get(
        environment.apiUrl +
          ProjectService.base +
          '/' +
          project.slug +
          '/statistics/conversations/demography' +
          params
      )
      .toPromise();
  }
}
