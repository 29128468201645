<div class="feedback-editor" *ngIf="survey || !edit">
  <div class="feedback-editor__nav" [ngClass]="{ isEdit: edit }">
    <div class="feedback-editor__nav__top">
      <div class="feedback-editor__nav__top__left">
        <div class="header__logo">
          <a routerLink="/">
            <img [src]="logo" />
          </a>
        </div>
      </div>
      <div class="feedback-editor__nav__top__right">
        <button
          *ngIf="survey?.status == SurveyStatus.ACTIVE"
          class="button button--m button--ghost bg--grey--hover margin"
          (click)="sendPush()"
          appAccess
          role="manager"
          [project]="project"
        >
          {{ 'project.detail.feedback.editor.top.push' | translate }}
        </button>
        <button
          *ngIf="survey?.status == SurveyStatus.INACTIVE || !edit"
          class="button button--m button--ghost bg--grey--hover margin"
          [appLoading]="loading"
          (click)="saveSurvey(SurveyStatus.INACTIVE)"
          appAccess
          role="manager"
          [project]="project"
        >
          {{ 'project.detail.feedback.editor.top.concept' | translate }}
        </button>
        <button
          class="button button--m button--outlined bg--grey--hover margin"
          [routerLink]="'/projects/' + project.slug + '/feedback'"
        >
          {{ 'project.detail.feedback.editor.top.cancel' | translate }}
        </button>
        <button
          *ngIf="survey?.status == SurveyStatus.INACTIVE || !edit"
          class="button button--m button--flat bg--orange margin"
          [appLoading]="loading"
          (click)="saveSurvey(SurveyStatus.ACTIVE)"
          appAccess
          role="manager"
          [project]="project"
        >
          {{ 'project.detail.feedback.editor.top.publish' | translate }}
        </button>
        <button
          *ngIf="survey?.status == SurveyStatus.ACTIVE"
          class="button button--m button--flat bg--orange margin"
          [appLoading]="loading"
          (click)="saveSurvey(surveyForm.get('status').value)"
          appAccess
          role="manager"
          [project]="project"
        >
          {{ 'project.detail.feedback.editor.top.save' | translate }}
        </button>
      </div>
    </div>
    <div
      class="feedback-editor__tabs"
      *ngIf="edit"
      appAccess
      role="manager"
      [project]="project"
    >
      <ul>
        <li
          (click)="surveyTab = 'editor'"
          [ngClass]="{ active: surveyTab == 'editor' }"
        >
          {{ 'project.detail.feedback.editor.tab.editor' | translate }}
        </li>
        <li
          (click)="surveyTab = 'submissions'"
          [ngClass]="{ active: surveyTab == 'submissions' }"
        >
          {{ 'project.detail.feedback.editor.tab.submissions' | translate }}
        </li>
      </ul>
    </div>
  </div>

  <div
    [hidden]="!pushSent"
    class="alert"
    [ngClass]="{
      'alert-success': pushSent == 'success',
      'alert-danger': pushSent == 'fail'
    }"
  >
    <span *ngIf="pushSent == 'success'">{{
      'project.detail.feedback.editor.push.sent' | translate
    }}</span>
    <span *ngIf="pushSent == 'fail'">{{
      'project.detail.feedback.editor.push.sent.fail' | translate
    }}</span>
  </div>

  <ng-container *ngIf="surveyTab == 'editor'">
    <div class="feedback-editor__head">
      <div *ngIf="!edit">
        <h1>{{ 'project.detail.feedback.editor.title.new' | translate }}</h1>
        <p
          [innerHTML]="
            'project.detail.feedback.editor.description.new' | translate
          "
        ></p>
      </div>

      <div *ngIf="edit">
        <h1
          [innerHTML]="
            'project.detail.feedback.editor.title.edit'
              | translate : { name: survey?.title }
          "
        ></h1>
        <p
          [innerHTML]="
            'project.detail.feedback.editor.description.edit'
              | translate : { name: survey?.title }
          "
        ></p>
      </div>
      <div>
        <button
          class="button button--m button--flat bg--orange"
          *ngIf="edit && survey.status == SurveyStatus.ACTIVE"
          ngxClipboard
          [cbContent]="getSurveyURL()"
          (cbOnSuccess)="textCopied()"
        >
          {{ 'project.detail.feedback.editor.link.copy' | translate }}
        </button>
        <span [style.display]="copied ? 'block' : 'none'">{{
          'project.detail.feedback.editor.link.copied' | translate
        }}</span>
      </div>
    </div>

    <form #formRef="ngForm" ngForm [formGroup]="surveyForm" appFormChangeDetector>
      <div class="feedback-editor__default">
        <div class="bouwapp-box full">
          <div class="bouwapp-box__self">
            <h2>
              {{ 'project.detail.feedback.editor.general.title' | translate }}
            </h2>
            <p>
              {{
                'project.detail.feedback.editor.general.description' | translate
              }}
            </p>

            <div class="form-split">
              <div class="form-group">
                <app-form-group errorPath="status" [form]="surveyForm">
                  <label for="status">
                    {{ 'project.detail.feedback.editor.status' | translate }}
                    <span
                      class="status"
                      [ngClass]="[getFeedbackStatusClass()]"
                    ></span>
                  </label>
                  <select
                    formControlName="status"
                    id="status"
                    [attr.title]="
                      'project.detail.feedback.editor.status' | translate
                    "
                    class="select select--m status"
                  >
                    <option [value]="SurveyStatus.INACTIVE">
                      {{
                        'project.detail.feedback.editor.status.concept'
                          | translate
                      }}
                    </option>
                    <option [value]="SurveyStatus.ACTIVE">
                      {{
                        'project.detail.feedback.editor.status.published'
                          | translate
                      }}
                    </option>
                  </select>
                </app-form-group>
              </div>
              <div class="form-group">
                <app-form-group errorPath="title" [form]="surveyForm">
                  <label for="name">{{
                    'project.detail.feedback.editor.name' | translate
                  }}</label>
                  <input
                    formControlName="title"
                    id="name"
                    type="text"
                    class="input input--m"
                    [placeholder]="
                      'project.detail.feedback.editor.name.placeholder'
                        | translate
                    "
                  />
                </app-form-group>
              </div>
            </div>
            <div class="form-group">
              <app-form-group errorPath="description" [form]="surveyForm">
                <label for="description">{{
                  'project.detail.feedback.editor.description' | translate
                }}</label>
                <app-quill
                  [formGroup]="surveyForm"
                  controlName="description"
                  id="description"
                  [placeholder]="
                    'project.detail.feedback.editor.description.placeholder'
                      | translate
                  "
                ></app-quill>
              </app-form-group>
            </div>
          </div>
        </div>
      </div>

      <div class="feedback-editor__separator">
        {{ 'project.detail.feedback.editor.separator' | translate }}
      </div>

      <ng-container formArrayName="surveyQuestions" *ngIf="surveyQuestions">
        <ng-container
          *ngFor="let questionForm of surveyQuestions.controls; let i = index"
        >
          <div
            #question
            class="feedback-editor__question"
            [formGroup]="questionForm"
          >
            <h2
              [innerHTML]="
                'project.detail.feedback.editor.question.indicator'
                  | translate : { number: i + 1 }
              "
            ></h2>

            <div class="feedback-editor__question__start">
              <div class="form-split">
                <div class="form-group">
                  <app-form-group errorPath="title" [form]="questionForm">
                    <label for="question-{{ i }}">{{
                      'project.detail.feedback.editor.question.title'
                        | translate
                    }}</label>
                    <input
                      formControlName="title"
                      id="question-{{ i }}"
                      type="text"
                      class="input input--m"
                      [placeholder]="
                        'project.detail.feedback.editor.question.placeholder'
                          | translate
                      "
                    />
                  </app-form-group>
                </div>
                <div class="form-group">
                  <app-form-group errorPath="type" [form]="questionForm">
                    <label for="type-{{ i }}">{{
                      'project.detail.feedback.editor.question.type' | translate
                    }}</label>
                    <select
                      id="type-{{ i }}"
                      formControlName="type"
                      [attr.title]="
                        'project.detail.feedback.editor.question.type.placeholder'
                          | translate
                      "
                      class="select select--m status"
                    >
                      <option value="" disabled selected>
                        {{
                          'project.detail.feedback.editor.question.type.placeholder'
                            | translate
                        }}
                      </option>
                      <option [value]="SurveyQuestionType.OPEN">
                        {{
                          'project.detail.feedback.editor.question.type.open'
                            | translate
                        }}
                      </option>
                      <option [value]="SurveyQuestionType.MULTIPLE_CHOICE">
                        {{
                          'project.detail.feedback.editor.question.type.multiple'
                            | translate
                        }}
                      </option>
                      <option [value]="SurveyQuestionType.CHECKBOX">
                        {{
                          'project.detail.feedback.editor.question.type.checkboxes'
                            | translate
                        }}
                      </option>
                      <option [value]="SurveyQuestionType.RANGE">
                        {{
                          'project.detail.feedback.editor.question.type.scale'
                            | translate
                        }}
                      </option>
                      <option [value]="SurveyQuestionType.DESCRIPTION">
                        {{
                          'project.detail.feedback.editor.question.type.description'
                            | translate
                        }}
                      </option>
                    </select>
                  </app-form-group>
                </div>
              </div>

              <div
                class="checkbox"
                [hidden]="
                  questionForm.get('type').value ==
                  SurveyQuestionType.DESCRIPTION
                "
              >
                <input
                  formControlName="withDescription"
                  type="checkbox"
                  id="description-toggle-{{ i }}"
                />
                <label for="description-toggle-{{ i }}">
                  <span *ngIf="!questionForm.get('withDescription').value">{{
                    'project.detail.feedback.editor.question.description.toggle.disabled'
                      | translate
                  }}</span>
                  <span *ngIf="questionForm.get('withDescription').value">{{
                    'project.detail.feedback.editor.question.description.toggle.enabled'
                      | translate
                  }}</span>
                </label>
              </div>

              <div
                *ngIf="questionForm.get('withDescription').value"
                class="form-group description"
              >
                <label for="description-{{ i }}">{{
                  'project.detail.feedback.editor.question.description'
                    | translate
                }}</label>
                <textarea
                  formControlName="description"
                  id="description-{{ i }}"
                  class="textarea"
                ></textarea>
              </div>
            </div>

            <ng-container
              *ngIf="
                [
                  SurveyQuestionType.MULTIPLE_CHOICE,
                  SurveyQuestionType.CHECKBOX
                ].indexOf(questionForm.get('type').value) !== -1
              "
            >
              <ul class="feedback-editor__question__options">
                <li>
                  <div>
                    {{
                      'project.detail.feedback.editor.question.option'
                        | translate
                    }}
                  </div>
                  <div>
                    {{
                      'project.detail.feedback.editor.question.action'
                        | translate
                    }}
                  </div>
                </li>
                <ng-container
                  *ngFor="
                    let optionForm of getOptions(questionForm);
                    let j = index
                  "
                >
                  <li
                    [formGroup]="optionForm"
                    [attr.data-label]="
                      'project.detail.feedback.editor.question.option'
                        | translate
                    "
                  >
                    <div>
                      <app-form-group errorPath="title" [form]="optionForm">
                        <input
                          (keydown)="onKeyDown($event, questionForm, i, j)"
                          formControlName="title"
                          id="option-{{ i }}-{{ j }}"
                          type="text"
                          class="input input--m"
                          [placeholder]="
                            'project.detail.feedback.editor.question.option.placeholder'
                              | translate
                          "
                        />
                      </app-form-group>
                      <span
                        (click)="
                          confirmDeleteOption.confirmDelete({
                            questionForm: questionForm,
                            index: j
                          })
                        "
                        [inlineSVG]="'/assets/img/icons/close--grey.svg'"
                      ></span>
                    </div>
                    <div>
                      <select
                        formControlName="action"
                        id="action-{{ i }}-{{ j }}"
                        [attr.title]="
                          'project.detail.feedback.editor.question.action.placeholder'
                            | translate
                        "
                        class="select select--m status"
                      >
                        <option [value]="null">
                          {{
                            'project.detail.feedback.editor.question.action.placeholder'
                              | translate
                          }}
                        </option>
                        <option [value]="'goTo:additional'">
                          {{
                            'project.detail.feedback.editor.question.action.additional'
                              | translate
                          }}
                        </option>
                        <ng-container
                          *ngIf="
                            questionForm.get('type').value ==
                            SurveyQuestionType.MULTIPLE_CHOICE
                          "
                        >
                          <ng-container
                            *ngFor="
                              let question of surveyQuestions.controls;
                              let actionIndex = index
                            "
                          >
                            <option
                              *ngIf="actionIndex > i"
                              [value]="'goTo:' + actionIndex"
                            >
                              {{
                                'project.detail.feedback.editor.question.action.skipto'
                                  | translate : { index: actionIndex + 1 }
                              }}
                            </option>
                          </ng-container>
                        </ng-container>
                      </select>
                    </div>
                  </li>
                </ng-container>
                <li>
                  <span
                    (click)="addOption(questionForm, null, true, i)"
                    class="button button--m button--dashed bg--grey"
                  >
                    {{
                      'project.detail.feedback.editor.question.option.new'
                        | translate
                    }}
                  </span>
                </li>
              </ul>
            </ng-container>

            <ng-container
              *ngIf="questionForm.get('type').value == SurveyQuestionType.RANGE"
            >
              <div class="feedback-editor__question__scale">
                <div class="feedback-editor__question__scale__options">
                  <div class="form-group">
                    <label for="scale-{{ i }}-1">{{
                      'project.detail.feedback.editor.question.scale.start'
                        | translate
                    }}</label>
                    <select
                      formControlName="ratingStart"
                      id="scale-{{ i }}-1"
                      class="select select--m"
                    >
                      <option
                        [value]="number"
                        *ngFor="let number of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      >
                        {{ number }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="scale-{{ i }}-2">{{
                      'project.detail.feedback.editor.question.scale.end'
                        | translate
                    }}</label>
                    <select
                      formControlName="ratingEnd"
                      id="scale-{{ i }}-2"
                      class="select select--m"
                    >
                      <option
                        [value]="number"
                        *ngFor="let number of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                      >
                        {{ number }}
                      </option>
                    </select>
                  </div>
                  <div
                    *ngIf="
                      questionForm.errors?.range &&
                      (questionForm.touched || questionForm.dirty)
                    "
                    class="errors alert alert-danger"
                  >
                    {{
                      'project.detail.feedback.editor.question.scale.error'
                        | translate
                    }}
                  </div>
                </div>
                <div class="feedback-editor__question__scale__toggle">
                  <div class="checkbox">
                    <input
                      formControlName="withEmoji"
                      type="checkbox"
                      id="emoticons-toggle-{{ i }}"
                    />
                    <label for="emoticons-toggle-{{ i }}">
                      <span>{{
                        'project.detail.feedback.editor.question.scale.emoticons'
                          | translate
                      }}</span>
                    </label>
                  </div>
                  <p>
                    {{
                      'project.detail.feedback.editor.question.scale.emoticons.description'
                        | translate
                    }}
                  </p>
                </div>
              </div>
            </ng-container>

            <div class="feedback-editor__question__bottom">
              <div
                class="button button--m button--outlined bg--grey--hover"
                (click)="addQuestion(null, true, i + 1)"
              >
                {{ 'project.detail.feedback.editor.question.new' | translate }}
              </div>
              <div>
                <div
                  class="feedback-editor__question__delete"
                  [inlineSVG]="'/assets/img/icons/delete.svg'"
                  (click)="confirmDeleteQuestion.confirmDelete(i)"
                ></div>
                <div
                  class="feedback-editor__question__required"
                  [hidden]="
                    questionForm.get('type').value ==
                    SurveyQuestionType.DESCRIPTION
                  "
                >
                  <input
                    formControlName="required"
                    type="checkbox"
                    id="question-toggle-{{ i }}"
                  />
                  <label for="question-toggle-{{ i }}">
                    {{
                      'project.detail.feedback.editor.question.required'
                        | translate
                    }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="feedback-editor__bottom">
        <div class="feedback-editor__bottom__left">
          <span>{{
            'project.detail.feedback.editor.bottom.navigate' | translate
          }}</span>
          <select (change)="goToQuestion($event)">
            <option value="" disabled selected>
              {{
                'project.detail.feedback.editor.bottom.placeholder' | translate
              }}
            </option>
            <option
              *ngFor="let question of surveyQuestions.controls; let i = index"
              [value]="i"
            >
              {{
                'project.detail.feedback.editor.question.indicator'
                  | translate : { number: i + 1 }
              }}
            </option>
          </select>
        </div>
        <div class="feedback-editor__bottom__right">
          <button
            *ngIf="edit"
            type="button"
            class="button button--m button--outlined bg--grey--hover"
            (click)="openSortModal()"
          >
            {{ 'project.detail.feedback.editor.bottom.reorder' | translate }}
          </button>
          <button
            *ngIf="survey?.status == SurveyStatus.ACTIVE"
            type="button"
            class="button button--m button--outlined bg--red--hover"
            (click)="deactivateModal.open()"
            [appLoading]="loading"
          >
            {{ 'project.detail.feedback.editor.bottom.deactivate' | translate }}
          </button>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="surveyTab == 'submissions'">
    <div class="feedback-editor__head">
      <div>
        <h1>{{ 'project.detail.feedback.editor.stats.title' | translate }}</h1>
        <p>
          {{ 'project.detail.feedback.editor.stats.description' | translate }}
        </p>
      </div>
      <div>
        <button
          (click)="exportCSV()"
          class="button button--m button--flat bg--orange"
        >
          {{ 'project.detail.feedback.editor.stats.export' | translate }}
        </button>
      </div>
    </div>

    <p class="feedback-editor__notice">
      {{ 'project.detail.feedback.editor.stats.notice' | translate }}
    </p>

    <div class="feedback-editor__summary">
      <div>
        <p>
          {{ 'project.detail.feedback.editor.stats.started_on' | translate }}
        </p>
        <span>{{ (survey.startedAt | date) || '-' }}</span>
      </div>
      <div>
        <p>
          {{ 'project.detail.feedback.editor.stats.stopped_on' | translate }}
        </p>
        <span>{{ (survey.endedAt | date) || '-' }}</span>
      </div>
      <div>
        <p>{{ 'project.detail.feedback.editor.stats.views' | translate }}</p>
        <span>{{ survey.viewCount }}</span>
      </div>
      <div>
        <p>
          {{
            'project.detail.feedback.editor.stats.responses.total' | translate
          }}
        </p>
        <span>{{ survey.submittedCount }}</span>
      </div>
      <div>
        <p>
          {{ 'project.detail.feedback.editor.stats.responses' | translate }}
        </p>
        <span>{{ survey.uniqueSubmittedCount }}</span>
      </div>
    </div>

    <div class="feedback-editor__graphs">
      <ng-container *ngFor="let question of statisticQuestions; let i = index">
        <div class="feedback-editor__graph">
          <div class="feedback-editor__graph__head">
            <div>
              <span>{{
                'project.detail.feedback.editor.stats.question.index'
                  | translate : { index: i + 1 }
              }}</span>
              <p>{{ question.title }}</p>
            </div>
            <div>
              <ng-container *ngIf="question.statistics">
                <span *ngIf="question.statistics.totalAnswers === 1">{{
                  'project.detail.feedback.editor.stats.question.responses.singular'
                    | translate : { answers: question.statistics.totalAnswers }
                }}</span>
                <span *ngIf="question.statistics.totalAnswers !== 1">{{
                  'project.detail.feedback.editor.stats.question.responses.plural'
                    | translate : { answers: question.statistics.totalAnswers }
                }}</span>
              </ng-container>
            </div>
          </div>
          <div
            *ngIf="question['barChartData']"
            class="feedback-editor__graph__content"
          >
            <canvas
              baseChart
              [ngStyle]="{
                height:
                  question['barChartType'] == 'bar'
                    ? '500px'
                    : question['barChartLabels'].length * 100 + 'px'
              }"
              [datasets]="question['barChartData']"
              [labels]="question['barChartLabels']"
              [type]="question['barChartType']"
              [options]="question['barChartOptions']"
              [legend]="false"
            ></canvas>
          </div>
          <div
            *ngIf="!question['barChartData']"
            class="feedback-editor__graph__content"
          >
            <div class="feedback-editor__graph__no-stats">
              <span [inlineSVG]="'/assets/img/icons/info-dark.svg'"></span>
              <p *ngIf="question.type == SurveyQuestionType.OPEN">
                <span
                  [innerHTML]="
                    'project.detail.feedback.editor.stats.question.open.message.start'
                      | translate
                        : { totalAnswers: question.statistics.totalAnswers }
                  "
                ></span>
                <a (click)="exportCSV()">{{
                  'project.detail.feedback.editor.stats.question.open.message.end'
                    | translate
                }}</a
                >.
              </p>
              <p *ngIf="question.type == SurveyQuestionType.DESCRIPTION">
                {{
                  'project.detail.feedback.editor.stats.question.description.message'
                    | translate
                }}
              </p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<app-modal
  #sortModal
  [title]="'project.detail.feedback.editor.modal.sort.title' | translate"
  class="feedback-modal reorder"
>
  <div class="reorder-container">
    <ul *ngIf="survey" [dragula]="Sortable.SURVEYS" [(dragulaModel)]="sortList">
      <li *ngFor="let question of sortList; let i = index">
        <span
          [inlineSVG]="'/assets/img/icons/handle.svg'"
          class="draggable"
        ></span>
        <div class="question">
          <p *ngIf="question.title">{{ question.title }}</p>
          <p *ngIf="!question.title">
            {{
              'project.detail.feedback.editor.modal.sort.question'
                | translate : { index: i + 1 }
            }}
          </p>
          <span>{{
            'project.detail.feedback.editor.modal.sort.index'
              | translate
                : { index: i + 1, total: survey.surveyQuestions.length }
          }}</span>
        </div>
        <div class="order">
          <span
            [inlineSVG]="'/assets/img/icons/caret-up-grey.svg'"
            (click)="moveUp(question, i)"
          ></span>
          <span
            [inlineSVG]="'/assets/img/icons/caret-down-grey.svg'"
            (click)="moveDown(question, i)"
          ></span>
        </div>
      </li>
    </ul>
  </div>
  <div class="buttons">
    <button
      (click)="sortModal.close()"
      class="button button--m button--ghost bg--grey--hover"
    >
      {{ 'project.detail.feedback.editor.modal.cancel' | translate }}
    </button>
    <button
      (click)="saveSort()"
      class="button button--m button--flat bg--orange"
    >
      {{ 'project.detail.feedback.editor.modal.save' | translate }}
    </button>
  </div>
</app-modal>

<app-modal
  #deactivateModal
  [title]="'project.detail.feedback.editor.modal.deactivate.title' | translate"
  class="feedback-modal deactivate"
>
  <p>
    {{
      'project.detail.feedback.editor.modal.deactivate.description' | translate
    }}
  </p>
  <div class="buttons">
    <button
      (click)="deactivateModal.close()"
      class="button button--m button--ghost bg--grey--hover"
    >
      {{ 'project.detail.feedback.editor.modal.cancel' | translate }}
    </button>
    <button
      (click)="deactivateSurvey()"
      class="button button--m button--flat bg--red"
    >
      {{ 'project.detail.feedback.editor.modal.deactivate' | translate }}
    </button>
  </div>
</app-modal>

<app-confirm-delete
  #confirmDeleteQuestion
  (confirmed)="removeQuestion($event)"
></app-confirm-delete>
<app-confirm-delete
  #confirmDeleteOption
  (confirmed)="removeOption($event)"
></app-confirm-delete>
