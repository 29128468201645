import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { EqualPasswordValidator } from '../../../shared/equal-password.validator';
import { ErrorService } from '../../../services/error.service';
import { UserService } from '../../../services/user.service';
import { UserDataService } from '../../../services/user-data.service';
import { User } from '../../../interfaces/user';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingDirective } from '../../../directives/loading.directive';
import { FormGroupComponent } from '../../../components/form-group.component';
import { NgIf } from '@angular/common';
import { SuccessMessageComponent } from '../../../components/success-message.component';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';

@Component({
  selector: 'app-default-profile-password',
  templateUrl: 'password.component.html',
  standalone: true,
  imports: [
    SuccessMessageComponent,
    ReactiveFormsModule,
    NgIf,
    FormGroupComponent,
    LoadingDirective,
    FormChangeDetectorDirective,
    TranslateModule,
  ],
})
export class PasswordComponent {
  public form: FormGroup;
  public formSaved: boolean = false;
  public loading: boolean = false;
  public new: boolean = false;

  private user: User;

  constructor(
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private userService: UserService,
    private userDataService: UserDataService
  ) {
    this.init();
  }

  async init() {
    this.user = await this.userDataService.retrieveUser();
    this.createForm();
  }

  async createForm(): Promise<void> {
    if (this.user.ssoLogin) {
      this.form = this.formBuilder.group({
        plainPassword: this.formBuilder.group(
          {
            first: ['', [Validators.required]],
            second: ['', Validators.required],
          },
          {
            validator: EqualPasswordValidator(),
          }
        ),
      });
      this.new = true;
    } else {
      this.form = this.formBuilder.group({
        currentPassword: ['', [Validators.required]],
        plainPassword: this.formBuilder.group(
          {
            first: ['', [Validators.required]],
            second: ['', Validators.required],
          },
          {
            validator: EqualPasswordValidator(),
          }
        ),
      });
    }
  }

  async changePassword(): Promise<void> {
    this.formSaved = false;
    this.errorService.markFormGroupTouchedAndDirty(this.form);

    if (this.form.valid) {
      this.loading = true;
      const current = this.user.ssoLogin
        ? null
        : this.form.get('currentPassword').value;
      try {
        await this.userService.changePassword(
          this.user,
          this.form.get('plainPassword').get('first').value,
          current
        );

        this.formSaved = true;
        this.form.reset();
      } catch (error) {
        this.errorService.parseErrorsToForm(this.form, error.error);
      } finally {
        this.loading = false;
      }
    }
  }
}
