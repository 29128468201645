import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { QuillService } from '../services/quill.service';
import { AiTextComponent } from './ai-text.component';
import { LoaderComponent } from './loader.component';
import { QuillModule } from 'ngx-quill';
import { CharacterCountComponent } from './character-counter/character-count.component';
import { NgIf } from '@angular/common';
import 'quill-emoji/dist/quill-emoji.js';
import { Project } from '../interfaces/project';

@Component({
    selector: 'app-quill',
    templateUrl: './quill.component.html',
    standalone: true,
    imports: [NgIf, CharacterCountComponent, QuillModule, ReactiveFormsModule, LoaderComponent, AiTextComponent]
})
export class QuillComponent implements OnInit {
  public form: FormGroup;
  public control: FormControl;

  modules = {};
  randomNumber: number;

  @Input() controlName: string;

  @Input() readOnly: boolean;

  @Input() placeholder: string = null;
  @Input() project?: Project;
  @Input() language: string;

  @Input() characterCountText: string = null;
  @Input() characterLimit: number = null;

  public readonly formats = ['bold', 'italic', 'underline', 'link', 'list', 'emoji'];

  constructor(
    private controlContainer: ControlContainer,
    private quillService: QuillService
  ) {}

  ngOnInit(): void {
    this.form = this.controlContainer.control as FormGroup;
    this.control = this.form.get(this.controlName) as FormControl;

    this.randomNumber = this.quillService.getIdentifier();

    this.modules = {
      clipboard: {
        matchVisual: false,
      },
      toolbar: {
        container: '#quill-toolbar-' + this.randomNumber,
        handlers: {
          link(value: string) {
            const that: any = this;

            const tooltip = that.quill.theme.tooltip;
            const input = tooltip.root.querySelector('input[data-link]');
            input.dataset.link = 'https://debouw.app';
            input.placeholder = 'https://debouw.app';
            input.dataset.lpignore = true;

            // https://github.com/quilljs/quill/blob/develop/themes/snow.js#L113
            if (value) {
              const range = that.quill.getSelection();
              if (range == null || range.length === 0) {
                return;
              }
              let preview = that.quill.getText(range);
              if (
                /^\S+@\S+\.\S+$/.test(preview) &&
                preview.indexOf('mailto:') !== 0
              ) {
                preview = `mailto:${preview}`;
              }
              const { tooltip } = that.quill.theme;
              tooltip.edit('link', '');
            } else {
              that.quill.format('link', false);
            }
          },
        },
      },
      "emoji-toolbar": true
    };
  }

  public aiTextLoading(event: boolean) {
    this.readOnly = event;
  }
}
