import { Injectable } from '@angular/core';
import { User } from '../interfaces/user';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ProjectUserPermissions } from '../interfaces/project-user-permissions';
import { Project } from '../interfaces/project';
import { ProjectService } from './project.service';
import { Customer } from '../interfaces/customer';
import { ProjectUser } from '../interfaces/project-user';

@Injectable()
export class UserService {
  /**
   * @type {string}
   */
  static base = '/users';

  constructor(private http: HttpClient) { }

  public fetch(id: number): Promise<User> {
    return this.http
      .get<User>(environment.apiUrl + UserService.base + '/' + id)
      .toPromise();
  }

  public fetchMe(): Promise<User> {
    return this.http
      .get<User>(environment.apiUrl + UserService.base + '/me')
      .toPromise();
  }

  public changePassword(
    user: User,
    plainPassword: string,
    currentPassword?: string
  ): Promise<User> {
    return this.http
      .put<User>(
        environment.apiUrl +
        UserService.base +
        '/' +
        user.id +
        '/change-password',
        {
          currentPassword,
          plainPassword,
        }
      )
      .toPromise();
  }

  public undoInvite(projectUser: ProjectUser): Promise<any> {
    return this.http
      .delete(environment.apiUrl + `/project-user-mappings/${projectUser.permission.id}`)
      .toPromise();
  }

  public deletePermissions(projectUser: ProjectUser, customer: Customer): Promise<any> {
    return this.http
      .delete(environment.apiUrl + `/project-user-mappings/${projectUser.id}/customer/${customer.id}`)
      .toPromise();
  }

  public updateInvite(
    permissionId: number,
    type: string,
  ): Promise<any> {
    return this.http
      .put(environment.apiUrl + `/project-user-mappings/${permissionId}`, type)
      .toPromise();
  }

  public invite(user: ProjectUser, type: string) {
    return this.http
      .post<Project>(
        environment.apiUrl + `/project-user-mappings/${user.id}`,
        type
      )
      .toPromise();
  }

  public updateInviteMultiple(
    projectUser: ProjectUser,
    type: string
  ): Promise<any> {
    return this.http
      .put(environment.apiUrl + `/project-user-mappings/${projectUser.id}/multiple`, type)
      .toPromise();
  }

  public inviteMultiple(user: ProjectUser, type: string) {
    return this.http
      .post<Project>(
        environment.apiUrl + `/project-user-mappings/${user.id}/multiple`,
        type
      )
      .toPromise();
  }

}
