import { registerLocaleData } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { LocaleService } from './services/locale.service';
import localeNl from '@angular/common/locales/nl';
import localeEn from '@angular/common/locales/en';
import { OAuthStorage } from 'angular-oauth2-oidc';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function loadLocale(service: LocaleService) {
  return service.currentLocale;
}

registerLocaleData(localeNl, 'nl');
registerLocaleData(localeEn, 'en');

export function storageFactory(): OAuthStorage {
  return localStorage;
}

