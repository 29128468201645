<div class="container">
    <div class="bouwapp-header">
        <div class="bouwapp-header__text">
            <h1>{{ 'smart_reporting.themes.title' | translate }}</h1>
            <p>{{ 'smart_reporting.themes.introduction' | translate }}</p>
        </div>
        <div class="bouwapp-header__buttons">
            <button class="primary" (click)="formModal.open()">
                <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"></span>
                <span>{{ 'smart_reporting.themes.form.create.title' | translate }}</span>
            </button>
        </div>
    </div>
</div>
<div class='container'>
    <table class="smart-reporting__themes__table">
        <thead>
        <tr>
            <th width="60%">
                {{ 'smart_reporting.themes.table.title' | translate }}
            </th>
            <th width="20%">
                {{ 'smart_reporting.themes.table.response_time_days' | translate }}
            </th>
            <th width="20%">
                {{ 'smart_reporting.themes.table.actions' | translate }}
            </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="!themes">
            <td colspan="3">
                <app-loader [condensed]="true"></app-loader>
            </td>
        </tr>
        <tr *ngIf="themes && themes.length === 0">
            <td colspan="3">
                {{ 'smart_reporting.themes.table.empty' | translate }}
            </td>
        </tr>
        <ng-container *ngIf="themes">
            <tr *ngFor="let theme of themes">
                <td>{{ theme.title }}</td>
                <td>{{ theme.responseTimeDays }}</td>
                <td>
                    <div
                        [inlineSVG]="'/assets/img/icons/edit-small.svg'"
                        (click)="openEdit(theme)"
                        class="smart-reporting__themes__action inline-svg__item"
                    ></div>
                    <div
                        [inlineSVG]="'/assets/img/icons/delete.svg'"
                        (click)="confirmDelete.confirmDelete(theme)"
                        class="smart-reporting__themes__action inline-svg__item"
                    ></div>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</div>

<app-modal
    [title]="'smart_reporting.themes.form.' + this.mode + '.title' | translate"
    #formModal
    (onClose)="reset()"
>
    <p>
        {{
            'smart_reporting.themes.form.' + this.mode + '.description' | translate
        }}
    </p>

    <div class="alert alert-danger" *ngIf="formError">
        {{ 'smart_reporting.themes.form.error' | translate }}
    </div>

    <form [formGroup]="form" appFormChangeDetector (submit)="submit($event)">
        <app-form-group [form]="form" errorPath="title">
            <label for="title">{{
                    'smart_reporting.themes.form.title' | translate
                }}</label>
            <input type="text" class="input-lg" id="title" formControlName="title" />
        </app-form-group>
        <app-form-group [form]="form" errorPath="responseTimeDays">
            <label for="responseTimeDays">{{
                    'smart_reporting.themes.form.response_time_days' | translate
                }}</label>
            <input
                type="number"
                min="0"
                step="1"
                class="input-lg"
                id="responseTimeDays"
                formControlName="responseTimeDays"
            />
        </app-form-group>
        <br />

        <button type="submit" class="btn btn-primary" [appLoading]="formLoading">
            {{ 'smart_reporting.themes.form.submit' | translate }}
        </button>
    </form>
</app-modal>

<app-confirm-delete
    #confirmDelete
    (confirmed)="deleteTheme($event)"
></app-confirm-delete>
