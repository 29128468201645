<h2 class="title">
  {{ 'projects.detail.concept.step.planning.title' | translate }}
</h2>
<div [formGroup]="generalForm" class="form-group">
  <p>{{ 'projects.detail.general.planning.description' | translate }}</p>
  <div class="form-split">
    <div>
      <div class="form-date">
        <p>{{ 'projects.detail.general.planning.start' | translate }}</p>
        <app-form-group [form]="generalForm" errorPath="startDate">
          <app-date-picker
            formControlName="startDate"
            [typeControl]="generalForm.get('startType')"
          >
          </app-date-picker>
        </app-form-group>
      </div>
    </div>
    <div>
      <div class="form-date">
        <p>{{ 'projects.detail.general.planning.end' | translate }}</p>
        <app-form-group [form]="generalForm" errorPath="endDate">
          <app-date-picker
            formControlName="endDate"
            [typeControl]="generalForm.get('endType')"
          >
          </app-date-picker>
        </app-form-group>
      </div>
    </div>
  </div>

  <div *ngIf="!dateRangeValid" class="form-date__notice">
    {{ 'projects.detail.general.planning.date.invalid' | translate }}
  </div>
</div>
