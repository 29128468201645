<app-loader *ngIf="edit && !update"></app-loader>
<div [ngClass]="{ container: isCustomer }">
  <div [ngClass]="{ 'tabs-content': isCustomer }">
    <section class="updates-edit" [hidden]="edit && !update">
      <form
        [formGroup]="updateForm"
        appFormChangeDetector
        (ngSubmit)="saveUpdate()"
      >
        <h1 class="hasDescription" *ngIf="!edit">
          {{ 'projects.detail.update.edit.new' | translate }}
        </h1>
        <h1 class="hasDescription" *ngIf="edit">
          {{ 'projects.detail.update.edit.edit' | translate }}
        </h1>

        <p class="pageDescription">
          {{ 'projects.detail.update.edit.description' | translate }}
        </p>

        <div class="updates-edit__wrapper">
          <div class="left">
            <!-- 01 GENERAL -->
            <div class="bouwapp-box">
              <div class="bouwapp-box__title">
                <h2>
                  {{ 'projects.detail.update.general.title' | translate }}
                </h2>
              </div>
              <div class="updates-edit__general">
                <div class="bouwapp-box__self">
                  <!-- 01A MEDIA -->
                  <div class="bouwapp-box__subtitle">
                    <h3>
                      {{ 'projects.detail.update.edit.media' | translate }}
                    </h3>
                    <p>
                      {{
                        'projects.detail.update.edit.media.description'
                          | translate
                      }}
                    </p>
                  </div>
                  <div class="form-group multi-attachment">
                    <app-multi-attachment-control-v2
                      type="update-attachment-file-path"
                      formControlName="attachments"
                      [id]="update?.id"
                      previewBasePath="/update/attachment"
                      [with360]="true"
                      (startUploading)="startUploading()"
                      (stopUploading)="stopUploading()"
                    ></app-multi-attachment-control-v2>
                  </div>

                  <!-- 01B DESCRIPTION -->
                  <div class="bouwapp-box__subtitle">
                    <h3>
                      {{
                        'projects.detail.update.description.title' | translate
                      }}*
                    </h3>
                  </div>
                  <app-form-group [form]="updateForm" errorPath="content">
                    <app-quill
                      [formGroup]="updateForm"
                      controlName="content"
                      placeholder="{{
                        'projects.detail.update.placeholder' | translate
                      }}"
                      [project]="project"
                      [language]="language"
                      [characterCountText]="updateForm.get('content').value"
                      [characterLimit]="limitMessage"
                    ></app-quill>
                  </app-form-group>

                  <!-- 01C: CATEGORY-->
                  <div class="bouwapp-box__subtitle">
                    <h3>
                      {{ 'projects.detail.update.category.title' | translate }}*
                    </h3>
                    <p>
                      {{
                        'projects.detail.update.category.description-v2'
                          | translate
                      }}
                    </p>
                  </div>
                  <app-form-group [form]="updateForm" errorPath="category">
                    <ng-select
                      [items]="updateCategories"
                      id="category"
                      bindLabel="item"
                      bindValue="type"
                      [placeholder]="
                        'projects.detail.update.category.description'
                          | translate
                      "
                      class="category-dropdown"
                      formControlName="category"
                    >
                      <ng-template ng-label-tmp let-item="item">
                        <span class="vehicle-option">{{ item.name }}</span>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <div>
                          <span class="category-option">{{ item.name }}</span
                          ><br />
                          <span class="category-description">{{
                            item.description
                          }}</span>
                        </div>
                      </ng-template>
                    </ng-select>
                  </app-form-group>
                  <!-- 03 LOCATION -->
                  <div class="bouwapp-box__subtitle">
                    <h3>
                      {{ 'projects.detail.update.edit.location' | translate }}
                    </h3>
                    <p>
                      {{
                        'projects.detail.update.edit.location.description'
                          | translate
                      }}
                    </p>
                  </div>
                  <app-form-group [form]="updateForm" errorPath="locationLat">
                    <app-update-location
                      *ngIf="!edit || update"
                      [noDefault]="isCustomer"
                      (locationPicked)="handleLocationPicked($event)"
                      [project]="project"
                      [update]="update"
                    ></app-update-location>
                  </app-form-group>
                </div>
              </div>
            </div>

            <!-- EVENTS -->
            <div
              class="events__container bouwapp-box full"
              *ngIf="showEvents"
              [style.order]="clickedFirst == 'poll' ? 1 : 0"
            >
              <div class="events__container__overlay" *ngIf="eventLoading">
                <i class="spinner"></i>
              </div>
              <div class="bouwapp-box__title">
                <h2>{{ 'projects.detail.update.edit.events' | translate }}</h2>
                <button
                  (click)="removeAllEvents()"
                  class="button button--icon bg--red--hover button--ghost"
                >
                  <span>{{ 'remove' | translate }}</span>
                  <span inlineSVG="/assets/v2/img/icons/trash.svg"></span>
                </button>
              </div>
              <div class="bouwapp-box__self updates-edit__events">
                <div class="bouwapp-box__subtitle">
                  <h3>
                    {{ 'projects.detail.update.edit.location' | translate }}
                  </h3>
                  <p>
                    {{
                      'projects.detail.update.edit.location.description'
                        | translate
                    }}
                  </p>
                </div>
                <section class="events-button__wrapper">
                  <button (click)="addEvent(true)">
                    <span inlineSVG="/assets/v2/img/icons/plus.svg"></span>
                    <p>
                      {{ 'projects.detail.update.edit.events.new' | translate }}
                    </p>
                  </button>
                  <button
                    [tooltip]="
                      aiEventEnhancementButtonActive
                        ? null
                        : ('projects.detail.update.tooltip' | translate)
                    "
                    (click)="addEventFromAI()"
                    [class.inactive]="!aiEventEnhancementButtonActive"
                    [disabled]="!aiEventEnhancementButtonActive"
                  >
                    <span
                      class="magic-wand"
                      inlineSVG="/assets/img/icons/magic-wand.svg"
                    ></span>
                    <p>
                      {{ 'projects.detail.update.edit.events.ai' | translate }}
                    </p>
                  </button>
                </section>
                <hr />
                <app-form-group
                  [form]="updateForm"
                  errorPath="events"
                  cssClass="form-group--normal"
                >
                  <div class="update-event" formArrayName="events">
                    <div
                      class="update-event__item"
                      [id]="'event' + index"
                      *ngFor="let event of events.controls; let index = index"
                      [formGroupName]="index"
                    >
                      <div class="update-event__item__left">
                        <div class="count">{{ index + 1 }}</div>
                        <div class="form-group">
                          <app-form-group
                            [form]="events.controls[index]"
                            errorPath="title"
                          >
                            <label>{{
                              'projects.detail.update.edit.events.title'
                                | translate
                            }}</label>
                            <input
                              formControlName="title"
                              class="input input--m"
                              type="text"
                              [placeholder]="
                                'projects.detail.update.edit.events.title.placeholder'
                                  | translate
                              "
                            />
                          </app-form-group>
                        </div>
                        <div class="form-group form-split">
                          <div>
                            <app-form-group
                              [form]="events.controls[index]"
                              errorPath="startDate"
                            >
                              <label>{{
                                'projects.detail.update.edit.events.startdate'
                                  | translate
                              }}</label>
                              <input
                                formControlName="startDate"
                                [value]="
                                  events.controls[index].get('startDate').value
                                    | date : 'yyyy-MM-ddTHH:mm'
                                "
                                class="input input--m"
                                type="datetime-local"
                              />
                            </app-form-group>
                          </div>
                          <div>
                            <app-form-group
                              [form]="events.controls[index]"
                              errorPath="endDate"
                            >
                              <label>{{
                                'projects.detail.update.edit.events.enddate'
                                  | translate
                              }}</label>
                              <input
                                formControlName="endDate"
                                [value]="
                                  events.controls[index].get('endDate').value
                                    | date : 'yyyy-MM-ddTHH:mm'
                                "
                                class="input input--m"
                                type="datetime-local"
                              />
                            </app-form-group>
                          </div>
                        </div>
                      </div>
                      <div class="update-event__item__right">
                        <span
                          inlineSVG="/assets/img/icons/trash.svg"
                          (click)="removeEvent(index)"
                        ></span>
                      </div>
                    </div>
                  </div>
                </app-form-group>
              </div>
            </div>

            <!-- POLL -->
            <div
              class="bouwapp-box full"
              *ngIf="showPoll"
              [style.order]="clickedFirst == 'events' ? 1 : 0"
            >
              <div class="bouwapp-box__title">
                <h2>{{ 'projects.detail.update.edit.poll' | translate }}</h2>
                <button
                  (click)="removePoll()"
                  class="button button--icon bg--red--hover button--ghost"
                >
                  <span>{{ 'remove' | translate }}</span>
                  <span inlineSVG="/assets/img/icons/trash.svg"></span>
                </button>
              </div>
              <div class="bouwapp-box__self updates-edit__poll">
                <div class="bouwapp-box__subtitle">
                  <p>
                    {{
                      'projects.detail.update.edit.poll.introduction'
                        | translate
                    }}
                  </p>
                </div>
                <div
                  formGroupName="poll"
                  *ngIf="updateForm && updateForm.get('poll')"
                >
                  <app-form-group
                    [form]="updateForm"
                    errorPath="poll.title"
                    cssClass="form-group--normal"
                  >
                    <label for="pollTitle">
                      {{
                        'projects.detail.update.edit.poll.question' | translate
                      }}
                    </label>
                    <input
                      type="text"
                      [placeholder]="
                        'projects.detail.update.edit.poll.question.placeholder'
                          | translate
                      "
                      class="input input--m"
                      formControlName="title"
                      id="pollTitle"
                    />
                  </app-form-group>
                  <app-form-group
                    [form]="updateForm"
                    errorPath="poll.choices"
                    cssClass="form-group--normal"
                  >
                    <label>
                      {{
                        'projects.detail.update.edit.poll.choices' | translate
                      }}
                    </label>

                    <a
                      href="javascript:void(0);"
                      (click)="addPollChoice()"
                      [hidden]="pollChoiceCount >= MAX_POLL_CHOICE"
                    >
                      {{
                        'projects.detail.update.edit.poll.choices.add'
                          | translate
                      }}
                    </a>

                    <ul class="update-poll" formArrayName="choices">
                      <li
                        *ngFor="
                          let choice of pollChoices.controls;
                          let index = index
                        "
                        [formGroupName]="index"
                        [hidden]="isPollChoiceRemoved(index)"
                      >
                        <div class="update-poll__choice">
                          <div
                            class="update-poll__choice__image"
                            [style.background-image]="getPollPreview(choice)"
                            [class.withImage]="pollChoiceHasPreview(choice)"
                          >
                            <app-input-file
                              class="update-poll__choice__image__input"
                              [unique]="index"
                              (previewUpdated)="
                                updatePollChoicePreview(choice, $event)
                              "
                              [withDragDrop]="false"
                              type="poll-choice-file-path"
                              [uploadImages]="true"
                              formControlName="filePath"
                              [large]="false"
                              (startUploading)="startUploading()"
                              (stopUploading)="stopUploading()"
                            >
                              <div
                                [hidden]="
                                  uploading || pollChoiceHasPreview(choice)
                                "
                                [inlineSVG]="'/assets/img/icons/camera.svg'"
                                [attr.alt]="
                                  'projects.detail.update.edit.poll.image'
                                    | translate
                                "
                                class="update-poll__choice__image__tag inline-svg__item"
                              ></div>
                            </app-input-file>
                          </div>
                          <app-form-group
                            [form]="updateForm"
                            cssClass="update-poll__choice__text-holder"
                            errorPath="poll.choices.{{ index }}.text"
                          >
                            <div
                              class="update-poll__choice__count-wrapper"
                              [class.update-poll__choice__has-remove]="
                                pollChoiceCount > MIN_POLL_CHOICE
                              "
                            >
                              <input
                                type="text"
                                class="update-poll__choice__text"
                                formControlName="text"
                                [attr.placeholder]="
                                  'projects.detail.update.edit.poll.label'
                                    | translate
                                "
                                maxlength="80"
                              />
                              <div class="update-poll__choice__count">
                                <app-character-count
                                  [stripHtml]="false"
                                  [text]="pollChoiceValue(index)"
                                  [limit]="80"
                                ></app-character-count>
                              </div>
                            </div>
                          </app-form-group>
                          <div
                            class="update-poll__choice__remove"
                            (click)="removePollChoice(choice)"
                            [hidden]="pollChoiceCount <= MIN_POLL_CHOICE"
                          >
                            <span>
                              {{
                                'projects.detail.update.edit.poll.answer.remove'
                                  | translate
                              }}
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </app-form-group>
                </div>
              </div>
            </div>

            <div class="buttons" *ngIf="!showPoll || !showEvents">
              <button
                *ngIf="!showPoll"
                class="button button--m button--outlined bg--dark--hover"
                (click)="addPoll(false, true)"
              >
                <span inlineSVG="/assets/img/icons/poll.svg"></span>
                {{ 'projects.detail.update.edit.poll.add-v2' | translate }}
              </button>
              <button
                *ngIf="!showEvents"
                class="button button--m button--outlined bg--dark--hover"
                (click)="addEvent(true)"
              >
                <span inlineSVG="/assets/img/icons/alarm.svg"></span>
                <span>{{
                  'projects.detail.update.edit.events.add' | translate
                }}</span>
              </button>
            </div>
          </div>
          <!-- PUBLISH MENU -->
          <div
            class="right"
            [class.sticky]="sticky"
            [style.left]="stickyLeft + 'px'"
          >
            <div class="bouwapp-box">
              <div class="bouwapp-box__title">
                <h2>{{ 'projects.detail.update.edit.publish' | translate }}</h2>
              </div>
              <div class="bouwapp-box__self">
                <div class="updates-edit__buttons">
                  <div class="publication status">
                    <span inlineSVG="/assets/img/icons/pin.svg"></span>
                    {{ 'projects.detail.update.edit.status' | translate }}
                    <span
                      [class.concept]="update?.status === 'CONCEPT' || !update"
                    >
                      <strong *ngIf="!isScheduled">{{
                        (update?.status ? update.status : 'CONCEPT') | translate
                      }}</strong>
                      <strong *ngIf="isScheduled">{{
                        'projects.detail.update.edit.scheduled' | translate
                      }}</strong>
                    </span>
                  </div>

                  <div class="publication date">
                    <span inlineSVG="/assets/img/icons/clock.svg"></span>
                    <span>
                      <ng-container *ngIf="!edit">
                        {{
                          (customDate
                            ? 'projects.detail.update.edit.publication_date'
                            : 'projects.detail.update.edit.publish-now'
                          ) | translate
                        }}
                      </ng-container>
                      <ng-container *ngIf="edit">
                        {{
                          (update?.status === 'PUBLISHED' && !isScheduled
                            ? 'projects.detail.update.edit.published-on'
                            : 'projects.detail.update.edit.publication_date'
                          ) | translate
                        }}
                      </ng-container>
                      <strong *ngIf="update?.publicationDate">{{
                        update.publicationDate | date : 'dd-MM-yyyy HH:mm'
                      }}</strong>
                      <span
                        [class.cancel]="customDate"
                        *ngIf="!pushSent"
                        (click)="togglePublicationDate()"
                      >
                        {{
                          (customDate
                            ? 'projects.detail.update.edit.cancel'
                            : 'projects.detail.update.edit.date.change'
                          ) | translate
                        }}
                      </span>
                    </span>
                  </div>

                  <app-form-group
                    class="publication datepicker"
                    [hidden]="!customDate"
                    [form]="updateForm"
                    errorPath="publicationDate"
                  >
                    <input
                      formControlName="publicationDate"
                      [value]="
                        updateForm.get('publicationDate').value
                          | date : 'yyyy-MM-ddTHH:mm'
                      "
                      type="datetime-local"
                    />
                  </app-form-group>

                  <div class="publication notification">
                    <span inlineSVG="/assets/img/icons/bell.svg"></span>
                    <span
                      >{{ 'projects.detail.update.edit.push' | translate }}
                      <span
                        >({{ pushSent ? update.pushCount : totalPush }}x)</span
                      >
                    </span>
                    <app-toggle
                      *ngIf="!pushSent && !publicationDateInPast"
                      formControlName="sendPush"
                      [small]="true"
                    ></app-toggle>
                    <span
                      class="push-status"
                      *ngIf="
                        update?.pushStatus && update.status === 'PUBLISHED'
                      "
                    >
                      {{
                        'projects.detail.update.edit.push.status.' +
                          update?.pushStatus | translate
                      }}
                    </span>
                    <span>
                      <strong *ngIf="update?.pushStatus == 'sent'">{{
                        update.pushDateTime | date : 'dd-MM-yyyy HH:mm'
                      }}</strong>
                    </span>
                    <span
                      class="past-push"
                      *ngIf="
                        publicationDateInPast &&
                        !pushSent &&
                        update?.pushStatus != 'scheduled'
                      "
                      >{{
                        'projects.detail.update.edit.publication_date_past'
                          | translate
                      }}</span
                    >
                  </div>

                  <div
                    class="publication targets"
                    *ngIf="
                      updateForm.get('sendPush').value &&
                      !publicationDateInPast &&
                      !isCustomer
                    "
                  >
                    <span inlineSVG="/assets/img/icons/targets.svg"></span>
                    <span>
                      {{ 'project.detail.targets.title' | translate }}
                      <span
                        *ngIf="!pushSent"
                        [class.cancel]="showTargets"
                        (click)="toggleShowTargets()"
                      >
                        {{
                          (showTargets
                            ? 'projects.detail.update.edit.cancel'
                            : 'projects.detail.update.edit.targets.change'
                          ) | translate
                        }}
                      </span>
                    </span>

                    <div class="list" [hidden]="showTargets">
                      <span
                        *ngIf="!edit || (edit && update?.targets.length == 0)"
                      >
                        <strong>{{
                          'projects.detail.update.edit.targets.all' | translate
                        }}</strong>
                      </span>
                      <ng-container
                        *ngIf="
                          edit && updateForm?.get('targets').value.length > 0
                        "
                      >
                        <span
                          *ngFor="let target of updateForm.get('targets').value"
                        >
                          {{ target.title }}
                        </span>
                        <span
                          *ngIf="updateForm.get('visibleToTargetsOnly').value"
                        >
                          <hr />
                          {{
                            'projects.detail.update.edit.targets.visible'
                              | translate
                          }}
                        </span>
                      </ng-container>
                    </div>

                    <div [hidden]="!showTargets">
                      <app-form-group [form]="updateForm" errorPath="targets">
                        <app-target-picker
                          [project]="project"
                          [multiple]="true"
                          [update]="true"
                          [label]="false"
                          formControlName="targets"
                          (targetsLoaded)="setTargets($event)"
                        ></app-target-picker>
                      </app-form-group>
                      <hr />
                      <app-form-group
                        [form]="updateForm"
                        errorPath="visibleToTargetsOnly"
                      >
                        <div class="checkbox">
                          <input
                            type="checkbox"
                            id="visibleToTargetsOnly"
                            formControlName="visibleToTargetsOnly"
                          />
                          <label for="visibleToTargetsOnly">{{
                            'projects.detail.update.edit.targets.visible'
                              | translate
                          }}</label>
                        </div>
                      </app-form-group>
                    </div>
                  </div>
                </div>
                <div class="bouwapp-box__footer">
                  <button
                    class="button button--m button--outlined bg--grey--hover"
                    *ngIf="!pushSent"
                    [ngStyle]="{
                      opacity: loading && saveMode == 'publish' ? 0 : 1
                    }"
                    [disabled]="loading || uploading"
                    [appLoading]="loading && saveMode == 'concept'"
                    type="submit"
                    (click)="setPushAndConcept(false, true)"
                  >
                    {{
                      'projects.detail.update.edit.save_concept_v2' | translate
                    }}
                  </button>
                  <button
                    class="button button--m button--flat bg--orange"
                    [disabled]="loading || uploading"
                    [ngStyle]="{
                      opacity: loading && saveMode == 'concept' ? 0 : 1
                    }"
                    [appLoading]="loading && saveMode == 'publish'"
                    type="submit"
                    (click)="setPushAndConcept(false)"
                  >
                    <span
                      *ngIf="
                        !publicationDateInPast &&
                          (edit || (!edit && customDate));
                        else default
                      "
                    >
                      {{ 'projects.detail.update.edit.schedule' | translate }}
                    </span>
                    <ng-template #default>
                      <span>
                        {{
                          (update?.status === 'PUBLISHED'
                            ? 'projects.detail.update.edit.save'
                            : 'projects.detail.update.edit.publish'
                          ) | translate
                        }}
                      </span>
                    </ng-template>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
    <app-error-message [hidden]="!noEventsFound" [onScreen]="true">
      {{ 'projects.detail.update.edit.events.none' | translate }}
    </app-error-message>
  </div>
</div>
