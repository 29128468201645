import { Injectable } from '@angular/core';
import { User } from '../interfaces/user';
import { Customer } from '../interfaces/customer';
import { CollectionResponse } from '../interfaces/collection-response';
import { ProjectUser } from '../interfaces/project-user';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AccessControlList } from '../interfaces/access-control-list';

@Injectable()
export class CustomerService {
  /**
   * @type {string}
   */
  static base = '/customers';

  constructor(private http: HttpClient) { }

  public fetchBySlug(slug: string) {
    return this.http
      .get<Customer>(environment.apiUrlV3 + CustomerService.base + '/' + slug)
      .toPromise();
  }


  /**
   * @param {number} id
   * @returns {Promise<Customer>}
   */
  public fetch(id: number): Promise<Customer> {
    return this.http
      .get<Customer>(environment.apiUrl + CustomerService.base + '/' + id)
      .toPromise();
  }

  /**
   * @param customer
   * @returns {Promise<User>}
   */
  public update(customer: Customer): Promise<Customer> {
    return this.http
      .put<Customer>(
        environment.apiUrl + CustomerService.base + '/' + customer.id,
        customer
      )
      .toPromise();
  }
}
