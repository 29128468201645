import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SmartReportingNotice } from '../../../../../interfaces/smart-reporting-notice';
import { SmartReportingService } from '../../../../../services/smart-reporting.service';
import { SmartReportingNoticeStatus } from '../../../../../enums/smart-reporting-notice-status';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SmartReportingNoticeResponse } from '../../../../../interfaces/smart-reporting-notice-response';
import { ModalComponent } from '../../../../../components/modal.component';
import { Subscription } from 'rxjs';
import { Project } from '../../../../../interfaces/project';
import { ProjectService } from '../../../../../services/project.service';
import { TimeAgoPipe } from '../../../../../pipes/time-ago.pipe';
import { NgDatePipesModule } from 'ngx-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { FormGroupComponent } from '../../../../../components/form-group.component';
import { LoadingDirective } from '../../../../../directives/loading.directive';
import { MapComponent, NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { AccessDirective } from '../../../../../directives/access.directive';
import { LoaderComponent } from '../../../../../components/loader.component';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';
import * as mapboxgl from 'mapbox-gl';
import { MapboxService } from 'src/app/services/mapbox.service';

@Component({
  selector: 'app-smart-reporting-notice-detail',
  templateUrl: './notice-detail.component.html',
  standalone: true,
  imports: [
    NgIf,
    FormChangeDetectorDirective,
    LoaderComponent,
    AccessDirective,
    ReactiveFormsModule,
    InlineSVGModule,
    NgxMapboxGLModule,
    NgFor,
    NgClass,
    LoadingDirective,
    ModalComponent,
    FormGroupComponent,
    DatePipe,
    TranslateModule,
    NgDatePipesModule,
    TimeAgoPipe,
  ],
})
export class SmartReportingNoticeDetailComponent {
  @ViewChild('removalReasonModal', { static: true })
  public removalModal: ModalComponent;
  @ViewChild(MapComponent)
  public mapComponent: MapComponent;

  public notice: SmartReportingNotice;
  public SmartReportingNoticeStatus = SmartReportingNoticeStatus;
  public form = this.fb.group({
    message: [null, Validators.required],
  });
  public removalForm = this.fb.group({
    removalReason: ['', Validators.required],
  });
  public statusControl = this.fb.control(null);
  public loading = false;
  public error = false;
  public statusError = false;
  public options = null;
  public responses: SmartReportingNoticeResponse[];
  public statusSubscription: Subscription;
  public submitted = false;
  public actualProject: Project;
  public version: number;
  public mapReady: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private smartReportingService: SmartReportingService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private mapboxService: MapboxService
  ) {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.load(params.id);
      }
    });

    this.statusControl.valueChanges.subscribe((status) =>
      this.updateStatus(status)
    );
  }

  public async submit() {
    if (!this.form.valid || this.loading) {
      return;
    }

    try {
      this.loading = true;
      this.error = false;

      const value = this.form.value;

      this.form.reset();
      const response = await this.smartReportingService.postResponse({
        ...value,
        notice: this.notice['@id'],
      });

      this.notice.responses.push(response);
      this.updateResponses();
    } catch (error) {
      console.error(error);

      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  public initMap() {
    this.mapboxService.drawMarker(this.mapComponent, [
      this.notice.locationLat,
      this.notice.locationLong,
    ]);
  }

  public getAvatar(response: SmartReportingNoticeResponse): SafeStyle {
    if (response.projectUser == null || !response.projectUser.avatar) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/img/icons/user.svg'
      );
    } else {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        response.projectUser.avatarThumbnails?.small
      );
    }
  }

  public getImage(notice: SmartReportingNotice): SafeStyle {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      notice.attachmentThumbnails?.medium
    );
  }

  updateResponses() {
    this.responses = this.notice.responses
      .filter((it) => !it.isResponse)
      .reduce(
        (previousValue, currentValue) => [
          ...previousValue,
          currentValue,
          ...currentValue.responses,
        ],
        []
      );
  }

  public updateStatus(status: SmartReportingNoticeStatus) {
    if (status === SmartReportingNoticeStatus.DELETED) {
      this.removalForm.patchValue(this.notice);
      this.removalModal.open();
    } else {
      this.changeStatus(status);
    }
  }

  public submitRemoval() {
    if (!this.removalForm.valid) {
      this.submitted = false;
      return;
    }

    this.changeStatus(
      SmartReportingNoticeStatus.DELETED,
      this.removalForm.value.removalReason
    );
  }

  public closeRemoval() {
    if (!this.removalForm.valid && !this.submitted) {
      this.statusControl.patchValue(this.notice.status, { emitEvent: false });
      return;
    }
  }

  public setSubmitted() {
    this.submitted = true;
  }

  private changeStatus(to: SmartReportingNoticeStatus, reason?: string) {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }

    this.notice.status = to;
    this.notice.removalReason = reason;
    this.statusError = false;

    this.statusSubscription = this.smartReportingService
      .updateStatus(this.notice, to, reason)
      .subscribe(
        (_) => this.smartReportingService.noticeStateChanged.next(true),
        (e) => (this.statusError = true)
      );

    this.removalModal.close();
    this.submitted = false;
  }

  private async load(id: number) {
    this.notice = await this.smartReportingService.fetchNotice(id);
    this.actualProject = await this.projectService.fetch(
      (this.notice.project as Project).slug
    );

    this.mapReady = true;
    this.statusControl.patchValue(this.notice.status, { emitEvent: false });
    this.updateResponses();
  }

  get isClosed() {
    return (
      this.notice.status === SmartReportingNoticeStatus.CLOSED ||
      this.notice.status === SmartReportingNoticeStatus.DELETED
    );
  }

  get project() {
    return this.actualProject
      ? this.actualProject
      : (this.notice.project as Project);
  }
}
