import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ErrorService } from 'src/app/services/error.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { LocaleService } from 'src/app/services/locale.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProjectUser } from 'src/app/interfaces/project-user';
import { ProjectUserService } from 'src/app/services/project-user.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { TwoFactorService } from 'src/app/services/two-factor.service';
import { AngularIntlPhoneComponent, AngularIntlPhoneConfig, AngularIntlPhoneModule } from 'angular-intl-phone';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { distinctUntilChanged, throttleTime } from 'rxjs/operators';
import { FooterComponent } from '../../../components/footer.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { FormGroupComponent } from '../../../components/form-group.component';
import { LoaderComponent } from '../../../components/loader.component';
import { NgIf, NgFor } from '@angular/common';
import { platform } from '../../../services/platform.service';

@Component({
    selector: 'app-two-factor',
    templateUrl: './two-factor.component.html',
    styleUrls: ['./two-factor.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0 }), animate('0.5s ease-in')]),
        ]),
    ],
    standalone: true,
    imports: [NgIf, LoaderComponent, ReactiveFormsModule, FormGroupComponent, AngularIntlPhoneModule, LoadingDirective, NgFor, FooterComponent, TranslateModule]
})
export class TwoFactorComponent implements OnInit {
  @ViewChild(AngularIntlPhoneComponent, { static: false }) phoneComponent: AngularIntlPhoneComponent;

  private oldCountryCode: string;

  public error = false;
  public form: FormGroup;
  public twoFaForm: FormGroup;
  public loading: boolean = true;
  public firstStep: boolean = true;
  public logo: SafeResourceUrl;
  public sended: boolean = false;
  public invalidCode: boolean = false;
  public resendSuccess: boolean = false;
  public loginCounter: number = 0;

  phoneConfig: AngularIntlPhoneConfig = {
    id: 'phoneNumber',
    name: 'phoneNumber',
    options: {
      separateDialCode: true,
      localizedCountries: {},
      onlyCountries: ['au', 'cz', 'dk', 'de', 'gb', 'nl', 'sk', 'be'],
      preferredCountries: [],
    },
  };

  /**
   * @type {ProjectUser}
   */
  public projectUser: ProjectUser;

  constructor(
    private sanitizer: DomSanitizer,
    private projectUserService: ProjectUserService,
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private localeService: LocaleService,
    private twoFactorService: TwoFactorService,
    private userDataService: UserDataService,
    private translateService: TranslateService,
    private router: Router
  ) {
    this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(
      platform.logo
    );

    if (localeService.localizedCountryNames) {
      this.phoneConfig.options.localizedCountries =
        localeService.localizedCountryNames;
    }

    this.translateService
      .get('two.factor.phone.placeholder')
      .subscribe((result) => {
        this.phoneConfig.placeholder = result;
      });
    this.phoneConfig.options.initialCountry =
      platform.language == 'nl' ? 'nl' : 'gb';
  }

  async loadProjectUser(): Promise<void> {
    const projectUser: ProjectUser =
      await this.userDataService.retrieveProjectUser();
    const email: string = await this.userDataService.retrieveEmail(); // get user email

    try {
      this.projectUser = await this.projectUserService.fetch(projectUser.id);
    } catch (e) {
      this.firstStep = false;
    }
  }

  async ngOnInit() {
    await this.loadProjectUser();

    if (this.projectUser) {
      this.form = this.formBuilder.group({
        phoneNumber: ['', [Validators.required]],
      });

      if (this.projectUser.phoneNumber) {
        const phoneNumber = '+' + this.projectUser.phoneNumber;
        this.form.get('phoneNumber').setValue(phoneNumber);
      }

      this.form.get('phoneNumber').valueChanges.subscribe((value: any) => {
        if (value.dialCodeWithPlus !== undefined) {
          if (value.dialCodeWithPlus !== this.oldCountryCode) {
            if (value.e164Number.startsWith(this.oldCountryCode)) {
              const cleanNumber = value.e164Number.substring(this.oldCountryCode.length).trim();
              this.phoneComponent.writeValue(cleanNumber);
            }
          }
          this.oldCountryCode = value.dialCodeWithPlus;
        }
      });
    }

    const pattern = /^\d+$/;
    this.twoFaForm = this.formBuilder.group({
      code0: [
        '',
        [
          Validators.required,
          Validators.maxLength(1),
          Validators.pattern(pattern),
        ],
      ],
      code1: [
        '',
        [
          Validators.required,
          Validators.maxLength(1),
          Validators.pattern(pattern),
        ],
      ],
      code2: [
        '',
        [
          Validators.required,
          Validators.maxLength(1),
          Validators.pattern(pattern),
        ],
      ],
      code3: [
        '',
        [
          Validators.required,
          Validators.maxLength(1),
          Validators.pattern(pattern),
        ],
      ],
      code4: [
        '',
        [
          Validators.required,
          Validators.maxLength(1),
          Validators.pattern(pattern),
        ],
      ],
      code5: [
        '',
        [
          Validators.required,
          Validators.maxLength(1),
          Validators.pattern(pattern),
        ],
      ],
    });
    this.loading = false;
  }

  async resend() {
    this.sended = true;
    this.twoFactorService
      .resend()
      .then((res) => {
        this.loginCounter = 0;
        this.sended = false;
        this.error = false;
        this.resendSuccess = true;
        this.invalidCode = false;
      })
      .catch((err) => {
        this.invalidCode = false;
        this.error = true;
        this.sended = false;
        this.resendSuccess = false;
      });
  }

  handlePaste = (event) => {
    const clip = event.clipboardData.getData('text');
    const pin = clip.replace(/\s/g, '');
    const ch = [...pin];
    for (let i = 0; i < 6; i++) {
      const char = ch[i] ?? '';
      this.twoFaForm.get('code' + i).setValue(char);
    }
  };

  focusNext(event: KeyboardEvent, index: number) {
    const number = Number(event.key);
    if (!Number.isInteger(number)) {
      return;
    } else {
      this.twoFaForm.get('code' + index).patchValue(number);
    }
    const nextInput = document.getElementById(`code${index + 1}`);
    if (nextInput) {
      nextInput.focus();
    }
  }

  public async submit() {
    if (this.firstStep && this.form.valid) {
      this.loading = true;
      const data = this.form.getRawValue();
      this.projectUser.phoneNumber = data.phoneNumber.e164Number.replace(
        /\+/g,
        ''
      );
      this.projectUser.twoFactorEnabled = false;

      const projectUser: ProjectUser = await this.projectUserService.update(
        this.projectUser
      );
      await this.userDataService.storeProjectUser(projectUser);
      await this.twoFactorService.send();
      this.firstStep = false;
      this.loading = false;
    }

    if (!this.firstStep) {
      if (this.twoFaForm.valid) {
        const verificationCode = Object.values(this.twoFaForm.value).join('');
        try {
          this.sended = true;
          this.loginCounter = await this.twoFactorService.validate(
            Number(verificationCode)
          );
          this.projectUser.twoFactorEnabled = true;

          const projectUser: ProjectUser = await this.projectUserService.update(
            this.projectUser
          );
          await this.userDataService.storeProjectUser(projectUser);
          await this.router.navigate(['']);
        } catch (e) {
          this.invalidCode = true;
          this.resendSuccess = false;
          this.error = false;
          this.sended = false;
          if (this.loginCounter < 3) {
            this.loginCounter++;
          } else {
            window.location.reload();
          }
        }
      }
      if (this.twoFaForm.invalid && this.twoFaForm.touched) {
        this.errorService.markFormGroupTouchedAndDirty(this.twoFaForm);
      }
    }
  }
}
