import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LocaleService } from './services/locale.service';
import { AuthService } from './services/auth.service';
import { DOCUMENT } from '@angular/common';
import { ThemeService } from './services/theme.service';
import { platform } from './services/platform.service';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  @HostBinding('class') public cssClass = 'default';
  cssLoaded: boolean = false;

  constructor(
    private router: Router,
    title: Title,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private themeService: ThemeService,
    private localeService: LocaleService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // background has to be different on certain pages
        if (/^\/(confirm|login|forget|auth-callback)/.test(event.url)) {
          this.cssClass = 'default authentication-page';
        } else if (
          /^\/projects/.test(event.url) ||
          /^\/smart-reporting/.test(event.url)
        ) {
          this.cssClass = 'default detail-page';
        } else {
          this.cssClass = 'default';
        }
      }
    });

    this.document.documentElement.lang = this.localeService.currentLocale
    title.setTitle(platform.title);
  }

  async ngOnInit() {
    this.authService.setup();
    const version = await this.themeService.getVersion();
    await this.themeService.setVersion(version);
  }
}
