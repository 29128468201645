import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CollectionResponse } from '../interfaces/collection-response';
import { environment } from '../../environments/environment';
import { Sort } from '../interfaces/sort';
import { Article } from '../interfaces/article';
import { NavigationStart, Router } from '@angular/router';

@Injectable()
export class NewsService {
  static base = '/blogs';
  private savedState: any;

  constructor(private http: HttpClient, private router: Router) {
    this.router.events.subscribe((event) => {
      if (this.savedState) {
        if (event instanceof NavigationStart) {
          if (
            event.url === '/' + this.savedState.article.blogCategory.type ||
            /^\/articles\/([a-zA-Z0-9-]+)/.test(event.url)
          ) {
            //keep the state
          } else {
            this.setSavedState(null);
          }
        }
      }
    });
  }

  listCategoriesByType(type: any): Promise<CollectionResponse<any>> {
    return this.http
      .get<CollectionResponse<any>>(
        environment.apiUrl + NewsService.base + '/categories/' + type
      )
      .toPromise();
  }

  listByType(
    type: string,
    page: number,
    perPage: number = 10,
    sort: Sort = null,
    search?: string,
    categories?: string[]
  ): Promise<CollectionResponse<Article>> {
    let params: HttpParams = new HttpParams();

    params = params.set('perPage', String(perPage));
    params = params.set('page', String(page));

    if (sort !== null) {
      params = params.set('order[' + sort.field + ']', sort.direction);
    }

    let urlEnd = '?';

    if (search != null && search !== '') {
      params = params.set('search', search);
    }

    if (categories != null && categories.length > 0) {
      for (const category of categories) {
        urlEnd += `categories[]=${category}&`;
      }
    }

    return this.http
      .get<CollectionResponse<Article>>(
        environment.apiUrl +
          NewsService.base +
          '/type/' +
          type +
          urlEnd.slice(0, -1),
        { params }
      )
      .toPromise();
  }

  listRelated(slug: string): Promise<Article[]> {
    return this.http
      .get<Article[]>(
        environment.apiUrl + NewsService.base + '/related/' + slug
      )
      .toPromise();
  }

  fetch(slug: string): Promise<Article> {
    return this.http
      .get<Article>(environment.apiUrl + NewsService.base + '/post/' + slug)
      .toPromise();
  }

  toggleFavorite(slug: string): Promise<any> {
    return this.http
      .post(environment.apiUrl + NewsService.base + '/favorite/' + slug, {})
      .toPromise();
  }

  setSavedState(state) {
    this.savedState = state;
  }

  getSavedState() {
    return this.savedState;
  }
}
