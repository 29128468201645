import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PermissionType } from 'src/app/enums/permission-type';
import { Customer } from 'src/app/interfaces/customer';
import { ProjectUser } from 'src/app/interfaces/project-user';

@Injectable({ providedIn: 'root' })
export class ManageUsersService {
    private editEventSubject = new Subject<ProjectUser>();
    editEvent$ = this.editEventSubject.asObservable();

    private deleteEventSubject = new Subject<ProjectUser>();
    deleteEvent$ = this.deleteEventSubject.asObservable();

    triggerEditEvent(projectUser: ProjectUser) {
        this.editEventSubject.next(projectUser);
    }

    triggerDeleteEvent(projectUser: ProjectUser) {
        this.deleteEventSubject.next(projectUser);
    }

    extractPrefixAndValue(input: string): { prefix: string, permission: string } {
        const [prefix, ...parts] = input.split('-');
        const permission = parts.join('-');

        return { prefix, permission }
    }

    public hasCustomerPermission(projectUser: ProjectUser, customer: Customer) {
        const customers = projectUser.customersProjectUser ?? projectUser.customers;
        return customers.some(
            (item) =>
                (parseInt(item.customer?.id as any) === parseInt(customer?.id as any)
                    && item.permissionType !== 'view') || item.id === customer.id
        );
    }

    public determinePermission(user: ProjectUser, customer: Customer): PermissionType {
        if (user.admin) {
            return PermissionType.ACCOUNT_ADMIN;
        } else if (user.permission && user.permission.permissionType === 'view') {
            return PermissionType.PROJECT_READER;
        } else if (user.permission && user.permission.permissionType === 'edit') {
            return PermissionType.PROJECT_USER;
        } else if (user.permission && user.permission.permissionType === 'create') {
            return PermissionType.PROJECT_ADMIN;
        } else {
            if (this.hasCustomerPermission(user, customer)) {
                return PermissionType.ACCOUNT_ADMIN;
            } else {
                return PermissionType.PROJECT_ADMIN;
            }
        }
    }
}