<div
  [formGroup]="dateForm"
  class="date-picker date-picker-input-only date-picker-quick-select"
  *ngIf="inline"
>
  <select class="date-picker-day" formControlName="day">
    <option *ngFor="let day of days" value="{{ day }}">{{ day }}</option>
  </select>
  <select class="date-picker-month" formControlName="month">
    <option *ngFor="let month of months; let i = index" value="{{ i + 1 }}">
      {{ 'date_picker.month.' + month | translate }}
    </option>
  </select>
  <select class="date-picker-year" formControlName="year">
    <option *ngFor="let year of years" value="{{ year }}">{{ year }}</option>
  </select>
</div>

<div
  class="date-picker"
  [formGroup]="dateForm"
  [ngClass]="{ 'date-picker-with-time': withTime }"
  *ngIf="!inline"
>
  <app-switch
    *ngIf="typeControl"
    formControlName="mainType"
    class="date-picker-type"
  >
    <app-switch-option value="EXACT_DATE">{{
      'date_picker.main_type.exact' | translate
    }}</app-switch-option>
    <app-switch-option value="PERIOD">{{
      'date_picker.main_type.period' | translate
    }}</app-switch-option>
  </app-switch>

  <div [hidden]="showPeriod" *ngIf="!inline">
    <div class="date-picker-time-container">
      <div
        class="date-picker-quick-select"
        [ngClass]="{ 'input-group': withTime }"
      >
        <select class="date-picker-day input-lg" formControlName="day">
          <option *ngFor="let day of days" value="{{ day }}">{{ day }}</option>
        </select>
        <select class="date-picker-month input-lg" formControlName="month">
          <option
            *ngFor="let month of months; let i = index"
            value="{{ i + 1 }}"
          >
            {{ 'date_picker.month.' + month | translate }}
          </option>
        </select>
        <select class="date-picker-year input-lg" formControlName="year">
          <option *ngFor="let year of years" value="{{ year }}">
            {{ year }}
          </option>
        </select>
      </div>
      <div class="date-picker-time-input" *ngIf="withTime">
        <input
          type="time"
          formControlName="time"
          required
          class="input-lg"
          [attr.title]="'date_picker.time' | translate"
          #timeElement
        />
      </div>
    </div>
    <div class="date-picker-choose" *ngIf="!inline">
      <div class="date-picker-item date-picker-heading">
        {{ 'date_picker.day.short.monday' | translate }}
      </div>
      <div class="date-picker-item date-picker-heading">
        {{ 'date_picker.day.short.tuesday' | translate }}
      </div>
      <div class="date-picker-item date-picker-heading">
        {{ 'date_picker.day.short.wednesday' | translate }}
      </div>
      <div class="date-picker-item date-picker-heading">
        {{ 'date_picker.day.short.thursday' | translate }}
      </div>
      <div class="date-picker-item date-picker-heading">
        {{ 'date_picker.day.short.friday' | translate }}
      </div>
      <div class="date-picker-item date-picker-heading">
        {{ 'date_picker.day.short.saturday' | translate }}
      </div>
      <div class="date-picker-item date-picker-heading">
        {{ 'date_picker.day.short.sunday' | translate }}
      </div>

      <div
        class="date-picker-item"
        *ngFor="let item of items"
        [ngClass]="{
          disabled: item.previous,
          active: currentDate === item.date && !noSelection
        }"
        (click)="select(item)"
      >
        {{ item.date | date : 'd' }}
      </div>
    </div>
  </div>

  <div *ngIf="typeControl" [hidden]="!showPeriod">
    <div class="period">
      <select class="input-lg" formControlName="type">
        <option value="MONTH">
          {{ 'date_picker.type.month' | translate }}
        </option>
        <option value="QUARTER">
          {{ 'date_picker.type.quartile' | translate }}
        </option>
        <option value="YEAR">{{ 'date_picker.type.year' | translate }}</option>
      </select>
      <select
        class="input-lg"
        formControlName="month"
        [hidden]="type !== 'MONTH'"
      >
        <option value="1">{{ 'date_picker.month.january' | translate }}</option>
        <option value="2">
          {{ 'date_picker.month.february' | translate }}
        </option>
        <option value="3">{{ 'date_picker.month.march' | translate }}</option>
        <option value="4">{{ 'date_picker.month.april' | translate }}</option>
        <option value="5">{{ 'date_picker.month.may' | translate }}</option>
        <option value="6">{{ 'date_picker.month.june' | translate }}</option>
        <option value="7">{{ 'date_picker.month.july' | translate }}</option>
        <option value="8">{{ 'date_picker.month.august' | translate }}</option>
        <option value="9">
          {{ 'date_picker.month.september' | translate }}
        </option>
        <option value="10">
          {{ 'date_picker.month.october' | translate }}
        </option>
        <option value="11">
          {{ 'date_picker.month.november' | translate }}
        </option>
        <option value="12">
          {{ 'date_picker.month.december' | translate }}
        </option>
      </select>
      <select
        class="input-lg"
        formControlName="quarter"
        [hidden]="type !== 'QUARTER'"
      >
        <option value="1">{{ 'date_picker.quartile.q1' | translate }}</option>
        <option value="2">{{ 'date_picker.quartile.q2' | translate }}</option>
        <option value="3">{{ 'date_picker.quartile.q3' | translate }}</option>
        <option value="4">{{ 'date_picker.quartile.q4' | translate }}</option>
      </select>
      <select
        class="input-lg"
        formControlName="year"
        [attr.title]="'date_picker.year' | translate"
      >
        <option *ngFor="let year of years" value="{{ year }}">
          {{ year }}
        </option>
      </select>
    </div>
  </div>
</div>
