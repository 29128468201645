import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { AttachmentHelper } from '../shared/attachment-helper';
import { Attachment } from '../interfaces/attachment';
import { debounce, debounceTime } from 'rxjs/operators';
import { Sortable } from '../enums/sortable';
import { UpdateService } from '../services/update.service';
import { PhaseService } from '../services/phase.service';
import { AnnouncementService } from '../services/announcement.service';
import { DragulaService, DragulaModule } from 'ng2-dragula';
import { TranslateModule } from '@ngx-translate/core';
import { ToggleComponent } from './toggle.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf, NgFor } from '@angular/common';
import { InputFileComponent } from './input-file.component';
import { FormGroupComponent } from './form-group.component';

type ChangeFn = (value: any) => void;

@Component({
    selector: 'app-multi-attachment-control',
    templateUrl: './multi-attachment-control.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiAttachmentControlComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [FormGroupComponent, ReactiveFormsModule, InputFileComponent, NgIf, DragulaModule, NgFor, InlineSVGModule, ToggleComponent, TranslateModule]
})
export class MultiAttachmentControlComponent implements ControlValueAccessor {
  public form: FormGroup;
  public attachmentPreviews: Attachment[] = [];
  public removed: number[] = [];
  public activePreview: Attachment = null;
  public activeIndex: number = 0;
  public Sortable = Sortable;

  private onChangeFn: ChangeFn;
  private onTouchedFn: ChangeFn;

  get attachments(): FormArray {
    return this.form.get('attachments') as FormArray;
  }

  @Input() public previewBasePath: string;
  @Input() public withDescription = true;
  @Input() public with360 = false;
  @Input() public withVideo = true;
  @Input() public type;
  @Input() public id;

  @Output() public startUploading = new EventEmitter();
  @Output() public stopUploading = new EventEmitter();
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  @ViewChild('mirrorElement') mirrorElement: ElementRef;

  public convertMessage = false;
  @ViewChildren('file') fileElements: QueryList<ElementRef>;
  hasDragged: boolean = false;

  constructor(
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private updateService: UpdateService,
    private phaseService: PhaseService,
    private announcementService: AnnouncementService,
    private dragulaService: DragulaService,
    private element: ElementRef
  ) {
    this.createForm();

    this.dragulaService.createGroup(Sortable.ATTACHMENTS, {
      moves: (el, container, handle) => {
        return (
          handle.classList.contains('file__drag') ||
          handle.parentElement.classList.contains('file__drag') ||
          handle.parentElement.parentElement.classList.contains('file__drag')
        );
      },
      direction: 'horizontal',
      mirrorContainer: this.element.nativeElement,
    });
  }

  public removeAttachment(event, attachment: Attachment) {
    event.preventDefault();
    event.stopPropagation();

    const index = this.attachmentPreviews.indexOf(attachment, 0);

    if (index !== -1) {
      this.removed.push(index);

      if (attachment === this.activePreview) {
        this.activePreview = null;
        this.activeIndex = -1;
      }

      this.updateActive();
      this.emitChange();
    }

    //check if the conversion message should still be shown
    this.convertMessage = false;
    this.attachmentPreviews.forEach((attachment, _index) => {
      if (
        (this.isVideo(attachment) || this.isPdf(attachment)) &&
        attachment.isConverted != 1 &&
        this.removed.indexOf(_index) == -1
      ) {
        this.convertMessage = true;
      }
    });
  }

  public isRemoved(index: number) {
    return this.removed.includes(index);
  }

  public getUrl(attachment: Attachment, small: boolean = false): string {
    return attachment.preview
      ? attachment.preview
      : small
      ? attachment.thumbnailPathThumbnails?.medium ??
        attachment.filePathThumbnails?.small
      : attachment.thumbnailPathThumbnails?.medium ??
        attachment.filePathThumbnails?.medium;
  }

  public getPreviewImage(
    attachment: Attachment,
    small: boolean = false
  ): SafeStyle | string {
    if (this.isVideo(attachment)) {
      return 'url("/assets/img/icons/type-video.svg")';
    } else {
      const url =
        attachment.videoId != null
          ? AttachmentHelper.getVideoImageForId(attachment.videoId)
          : this.getUrl(attachment, small);

      return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
    }
  }

  public getPreviewVideo(attachment: Attachment): SafeUrl | string {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      attachment.preview
        ? attachment.preview
        : attachment.filePathThumbnails.medium
        ? attachment.filePathThumbnails.medium
        : attachment.filePath
    );
  }

  select(attachment: Attachment, index: number): void {
    this.activePreview = attachment;
    this.activeIndex = index;
    if (this.isVideo(attachment) && this.videoPlayer) {
      this.videoPlayer.nativeElement.load();
    }
  }

  isActivePreview(attachment: Attachment): boolean {
    return this.activePreview === attachment;
  }

  isActiveTitle(index: number): boolean {
    return this.activeIndex === index;
  }

  isPdf(attachment: Attachment) {
    return /\.pdf$/.test(attachment.filePath);
  }

  isVideo(attachment: Attachment) {
    return (
      /\.mp4$/i.test(attachment.filePath) ||
      /\blob:$/i.test(attachment.filePath) ||
      /\.mov$/i.test(attachment.filePath)
    );
  }

  get empty() {
    return !this.attachmentPreviews.some(
      (_, index) => !this.removed.includes(index)
    );
  }

  addVideoAttachment(event) {
    let attachment: Attachment;
    if (event.type == 'youtube') {
      // check if video already exists, cannot be because of front-end rendering
      for (const preview of this.attachmentPreviews) {
        if (preview.filePath === event.videoId) {
          return;
        }
      }

      attachment = {
        videoId: event.url,
        title: '',
        filePath: event.url,
        fileName: event.url,
      };
    } else {
      let path = URL.createObjectURL(event.file.rawFile);
      attachment = {
        title: '',
        filePath: event.url,
        preview: path,
        fileName: event.file.name,
      };
    }
    this.attachmentPreviews.push(attachment);
    this.updateActive();
    this.createAttachmentControl(attachment);
    this.convertMessage = true;
  }

  public writeValue(attachments: Attachment[]): void {
    for (const attachment of attachments) {
      // set video id as name and path for reference
      if (attachment.videoId != null) {
        attachment.filePath = attachment.videoId;
        attachment.fileName = attachment.videoId;
      }

      this.createAttachmentControl(attachment);
    }

    this.attachmentPreviews = attachments;
    this.removed = [];
    this.updateActive();

    this.attachmentPreviews.forEach((attachment) => {
      if (
        (this.isVideo(attachment) || this.isPdf(attachment)) &&
        attachment.isConverted != 1
      )
        this.convertMessage = true;
    });
  }

  public registerOnChange(fn: ChangeFn): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: ChangeFn): void {
    this.onTouchedFn = fn;
  }

  private createAttachmentControl(attachment: Attachment): void {
    const group: FormGroup = this.fb.group({
      '@id': [''], // for api platform: keep reference
      id: [''],
      title: [''],
      videoId: [''],
      fileName: ['', Validators.required],
      filePath: ['', Validators.required],
      is360: [false],
    });
    group.patchValue(attachment);

    group.valueChanges
      .pipe(debounceTime(20))
      .subscribe((_) => this.emitChange());

    this.attachments.push(group);
    this.emitChange();
  }

  private emitChange() {
    const data = { ...this.form.value };

    for (const attachment of data.attachments) {
      delete attachment.preview;

      if (attachment.videoId != null && attachment.videoId !== '') {
        // file path is for reference only in case of a video, so don't fill
        delete attachment.filePath;
        delete attachment.fileName;
      } else {
        delete attachment.videoId;
      }
    }

    const object = { ...data.attachments };

    for (const removedIndex of this.removed) {
      delete object[removedIndex];
    }

    this.onTouchedFn(object);
    this.onChangeFn(object);
  }

  private updateActive(): void {
    const previews = this.attachmentPreviews.filter(
      (_, index) => !this.removed.includes(index)
    );

    if (!this.activePreview && previews && previews.length > 0) {
      this.activePreview = previews[0];
      this.activeIndex = this.attachmentPreviews.indexOf(this.activePreview);
    } else if (previews.length === 0) {
      this.activePreview = null;
      this.activeIndex = -1;
    }
  }

  private createForm() {
    this.form = this.fb.group({
      attachment: [''], // placeholder, won't be used but is for uploading
      attachments: this.fb.array([]),
    });

    this.form.get('attachment').valueChanges.subscribe((value) => {
      if (value != null) {
        this.attachmentPreviews.push(value);
        let index = this.attachmentPreviews.length - 1;

        setTimeout(() => {
          this.fileElements.toArray().forEach((el) => {
            if(el.nativeElement.getAttribute('data-index') == index) {
              if(this.hasDragged) {
                const parentNode = el.nativeElement.parentNode;
                parentNode.appendChild(el.nativeElement);
              }
            }
          });
        });

        if (this.isPdf(value)) {
          this.convertMessage = true;
        }
      }

      this.updateActive();

      if (value != null) {
        this.createAttachmentControl(value);
      }
    });
  }

  updateAttachmentsSortOrder(event, type) {
    this.hasDragged = true;
    this.form.get('attachments').patchValue(event);
    this.attachmentPreviews = event;
  }

  ngOnDestroy() {
    this.dragulaService.destroy(Sortable.ATTACHMENTS);
  }
}
