import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

import * as Sentry from '@sentry/angular';
/**
 * Factory function initializes Sentry configuration
 * BEFORE the app is initialized.
 */
const initSentry = async (http: HttpClient) => {
    const sentryConfig = await http
        .get('assets/config/config-map.json')
        .toPromise()
        .catch(() => null);

    if (!sentryConfig) {
        // eslint-disable-next-line no-console
        console.error('Sentry initialization failed, config-map.json not found');
        return;
    }

    if (!environment.sentry.enabled) {
        // eslint-disable-next-line no-console
        console.error('Sentry config loaded, but Sentry is not enabled for this build.');
        return;
    }

    Sentry.init({
        dsn: environment.sentry.sentryDsn,
        environment: environment.environment,
        release: sentryConfig.release,
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        tracesSampleRate: 1.0,
    });
};

@NgModule({
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (httpClient: HttpClient) => () => initSentry(httpClient),
            deps: [HttpClient],
            multi: true,
        },
    ],
})
export class SentryModule { }
