import { Component } from '@angular/core';
import { SmartReportingNotice } from '../../../../../../interfaces/smart-reporting-notice';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SmartReportingNoticeStatus } from '../../../../../../enums/smart-reporting-notice-status';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { SmartReportingService } from '../../../../../../services/smart-reporting.service';
import { CsvService } from '../../../../../../services/csv.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';
import { Project } from '../../../../../../interfaces/project';
import { ProjectService } from '../../../../../../services/project.service';
import { LoaderComponent } from '../../../../../../components/loader.component';
import { AccessDirective } from '../../../../../../directives/access.directive';
import { NgIf, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'app-smart-reporting-notices-table',
    templateUrl: './table.component.html',
    standalone: true,
    imports: [ReactiveFormsModule, NgIf, AccessDirective, RouterLink, LoaderComponent, NgFor, DatePipe, TranslateModule]
})
export class SmartReportingNoticesTableComponent {
  public notices: SmartReportingNotice[] = null;
  public SmartReportingNoticeStatus = SmartReportingNoticeStatus;
  public form: FormGroup = this.fb.group({
    open: [true],
    inProgress: [true],
    closed: [true],
    deleted: [false],
  });
  private noticesSubscription: Subscription;
  public projectSlug: string;
  public project: Project;

  constructor(
    private fb: FormBuilder,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private smartReportingService: SmartReportingService,
    private csvService: CsvService,
    private translateService: TranslateService
  ) {
    this.activatedRoute.params.subscribe((params) => {
      if (params.projectSlug) {
        this.projectSlug = params.projectSlug;

        this.loadProject();
      }
    });

    this.form.valueChanges.subscribe((_) => this.loadNotices());
  }

  private async loadNotices() {
    if (!this.projectSlug) {
      return;
    }

    this.notices = null;

    if (this.noticesSubscription) {
      this.noticesSubscription.unsubscribe();
    }

    this.noticesSubscription = this.smartReportingService
      .listNotices(this.projectSlug, this.activeStatues)
      .subscribe((value) => {
        value.forEach((v) => {
          v.lastResponseDate =
            v.responses.length > 0
              ? v.responses[v.responses.length - 1].createdAt
              : null;
        });

        this.notices = value;
      });
  }

  get activeStatues(): SmartReportingNoticeStatus[] {
    const result: SmartReportingNoticeStatus[] = [];
    const value = this.form.value;

    if (value.open) {
      result.push(SmartReportingNoticeStatus.OPEN);
    }

    if (value.closed) {
      result.push(SmartReportingNoticeStatus.CLOSED);
    }

    if (value.deleted) {
      result.push(SmartReportingNoticeStatus.DELETED);
    }

    if (value.inProgress) {
      result.push(SmartReportingNoticeStatus.IN_PROGRESS);
    }

    return result;
  }

  public exportCsv() {
    return this.csvService
      .create(this.createCsv(), null, false)
      .download(`notices-${this.projectSlug}.csv`);
  }

  private createCsv(): any[] {
    const headerList = [
      'id',
      'createdAt',
      'status',
      'theme',
      'description',
      'attachment',
      'subscribers',
      'name',
      'phoneNumber',
      'email',
      'feedbackSolution',
      'feedbackProcess',
      'locationLat',
      'locationLong',
      'dialogue',
    ];

    const headers: any = {};

    headerList.forEach((item) => {
      headers[item] = this.translateService.instant(
        `smart_reporting.notices.export.${item}`
      );
    });

    return this.notices.map((item) => {
      const object: any = {};

      object[headers['id']] = item.id;
      object[headers['createdAt']] = moment(item.createdAt).format(
        'DD-MM-YYYY HH:mm'
      );
      object[headers['status']] = item.status;
      object[headers['theme']] = item.theme ? item.theme.title : '';
      object[headers['description']] = item.description;
      object[headers['attachment']] = item.attachment ? item.attachment : '';
      object[headers['subscribers']] = item.amountOfSubscribers;
      object[headers['name']] = item.name;
      object[headers['phoneNumber']] = item.phoneNumber;
      object[headers['email']] = item.email;
      object[headers['feedbackSolution']] = item.feedbackSolution;
      object[headers['feedbackProcess']] = item.feedbackProcess;
      object[headers['locationLat']] = item.locationLat;
      object[headers['locationLong']] = item.locationLong;
      object[headers['dialogue']] = this.translateService.instant(
        item.responses.length >= 2
          ? 'smart_reporting.notices.export.dialogue.yes'
          : 'smart_reporting.notices.export.dialogue.no'
      );

      return object;
    });
  }

  private async loadProject() {
    this.project = await this.projectService.fetch(this.projectSlug);

    await this.loadNotices();
  }
}
