<h2 class="title">
  {{ 'projects.detail.concept.step.contact.title' | translate }}
</h2>
<form
  appFormChangeDetector
  [formGroup]="contactForm"
  (submit)="saveContact()"
  [project]="project"
  appAccess
  role="manager"
  property="readonly"
>
  <div class="project-contact form-group">
    <p>{{ 'project.detail.contact.description' | translate }}</p>
    <div class="project-contact__image">
      <h3>
        {{ 'projects.detail.general.conversations.image.title' | translate }}
      </h3>
      <app-form-group [form]="contactForm" errorPath="chatPlaceholderAvatar">
        <div
          class="project-contact__image__self"
          [style.background-image]="getCurrentPlaceholderAvatar()"
        >
          <app-input-file
            #chatPlaceholderAvatarInput
            (previewUpdated)="updatePlaceholderAvatarThumbnail($event)"
            [withDragDrop]="false"
            type="project-chat-placeholder-avatar"
            [uploadImages]="true"
            formControlName="chatPlaceholderAvatar"
            [large]="false"
            (startEditing)="startEditing($event)"
            (stopEditing)="stopEditing()"
            (startUploading)="inputFileStartUploading()"
            (stopUploading)="inputFileStopUploading($event)"
            [withEditor]="true"
          >
            <span inlineSVG="/assets/img/icons/photo--black.svg"></span>
          </app-input-file>
        </div>
      </app-form-group>
    </div>
    <div class="project-contact__first">
      <app-form-group [form]="contactForm" errorPath="chatPlaceholderName">
        <label for="chatName">{{
          'projects.detail.general.conversations.name.title' | translate
        }}</label>
        <input
          id="chatName"
          class="input input--m"
          [placeholder]="'projects.detail.general.tickets.name' | translate"
          formControlName="chatPlaceholderName"
          appAccess
          role="manager"
          [project]="project"
          property="disabled"
        />
      </app-form-group>
      <app-form-group
        [form]="contactForm"
        errorPath="chatPlaceholderPhoneNumber"
      >
        <label for="chatPhone">{{
          'projects.detail.general.conversations.phone.title' | translate
        }}</label>
        <input
          id="chatPhone"
          class="input input--m"
          [placeholder]="'projects.detail.general.tickets.phone' | translate"
          formControlName="chatPlaceholderPhoneNumber"
          appAccess
          role="manager"
          [project]="project"
          property="disabled"
        />
      </app-form-group>
    </div>
    <div class="project-contact__second">
      <app-form-group [form]="contactForm" errorPath="chatPlaceholderFunction">
        <label for="chatRole">{{
          'projects.detail.general.conversations.role.title' | translate
        }}</label>
        <input
          id="chatRole"
          class="input input--m"
          [placeholder]="'projects.detail.general.tickets.function' | translate"
          formControlName="chatPlaceholderFunction"
          appAccess
          role="manager"
          [project]="project"
          property="disabled"
        />
      </app-form-group>
      <app-form-group [form]="contactForm" errorPath="chatEnabled">
        <label for="chatEnabled">{{
          'projects.detail.general.tickets.enabled' | translate
        }}</label>
        <app-toggle id="chatEnabled" formControlName="chatEnabled"></app-toggle>
      </app-form-group>
    </div>
    <div class="project-contact__description">
      <app-form-group [form]="contactForm" errorPath="chatDescription">
        <label>{{
          'projects.detail.general.tickets.description.label' | translate
        }}</label>
        <app-quill
          [formGroup]="contactForm"
          controlName="chatDescription"
          [readOnly]="!canEdit"
          placeholder="{{
            'projects.detail.general.tickets.description.placeholder'
              | translate
          }}"
        ></app-quill>
      </app-form-group>
    </div>
  </div>
  <button
    *ngIf="withSave"
    class="button button--m button--flat bg--orange"
    [appLoading]="contactLoading"
    appAccess
    role="manager"
    [project]="project"
  >
    {{ 'project.detail.settings.notifications.save' | translate }}
  </button>
</form>

<app-image-cropbox
  [hidden]="!showEditor"
  [imageFormElement]="contactForm.get('chatPlaceholderAvatar')"
  [imageInput]="chatPlaceholderAvatarInput"
  [imageChangedEvent]="imageChangedEvent"
  [aspectRatio]="1"
  [startUploadingEvent]="startUploading"
  [stopUploadingEvent]="stopUploading"
  (editorClosed)="stopEditing()"
></app-image-cropbox>
