import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgZone,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { AttachmentHelper } from '../shared/attachment-helper';
import { Attachment } from '../interfaces/attachment';
import { debounceTime } from 'rxjs/operators';
import { Sortable } from '../enums/sortable';
import { UpdateService } from '../services/update.service';
import { PhaseService } from '../services/phase.service';
import { AnnouncementService } from '../services/announcement.service';
import { DragulaService, DragulaModule } from 'ng2-dragula';
import { ModalComponent } from './modal.component';
import { Subject } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { InputFileV2Component } from './input-file-v2.component';
import { ToggleComponent } from './toggle.component';
import { FormGroupComponent } from './form-group.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgFor, NgIf } from '@angular/common';

type ChangeFn = (value: any) => void;

@Component({
    selector: 'app-multi-attachment-control-v2',
    templateUrl: './multi-attachment-control-v2.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiAttachmentV2ControlComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [DragulaModule, NgFor, NgIf, InlineSVGModule, ModalComponent, ReactiveFormsModule, FormGroupComponent, ToggleComponent, InputFileV2Component, TranslateModule]
})
export class MultiAttachmentV2ControlComponent implements ControlValueAccessor {
  public form: FormGroup;
  public attachmentPreviews: Attachment[] = [];
  public removed: number[] = [];
  public oldPreview: Attachment = null;
  public activePreview: Attachment = null;
  public activeIndex: number = -1;
  public Sortable = Sortable;
  public mode: string = 'create';
  public uploading: boolean = false;

  private onChangeFn: ChangeFn;
  private onTouchedFn: ChangeFn;

  get attachments(): FormArray {
    return this.form.get('attachments') as FormArray;
  }

  @Input() public previewBasePath: string;
  @Input() public withDescription = true;
  @Input() public with360 = false;
  @Input() public withVideo = true;
  @Input() public type;
  @Input() public id;

  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  @ViewChild('mirrorElement') mirrorElement: ElementRef;
  @ViewChild('mediaModal', { static: true }) mediaModal: ModalComponent;
  @ViewChild(InputFileV2Component) inputFile: InputFileV2Component;

  public videoForm: FormGroup;
  private videoUrlRegex =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;

  public convertMessage = false;
  ready: boolean = false;
  confirmed: boolean = false;
  @ViewChildren('file') fileElements: QueryList<ElementRef>;
  hasDragged: boolean = false;

  constructor(
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private dragulaService: DragulaService,
    private element: ElementRef,
    private ngZone: NgZone
  ) {
    this.createForm();

    this.dragulaService.createGroup(Sortable.ATTACHMENTS, {
      moves: (el, container, handle) => {
        return (
          handle.classList.contains('file__drag') ||
          handle.parentElement.classList.contains('file__drag') ||
          handle.parentElement.parentElement.classList.contains('file__drag')
        );
      },
      accepts: (el, target, source, sibling) => {
        if (!sibling) return false;
        return true;
      },
      direction: 'horizontal',
      mirrorContainer: this.element.nativeElement,
    });
  }

  confirm() {
    if (this.activeIndex > -1) {
      if (this.attachments.controls[this.activeIndex].valid) {
        this.attachmentPreviews[this.activeIndex]['confirmed'] = true;
        this.confirmed = true;
        this.mediaModal.close();
      }
    }
  }

  ngOnInit() {
    this.mediaModal.onClose.subscribe(() => {
      if (!this.confirmed) {
        const value =
          this.attachments.controls[this.activeIndex].get('id').value;
        if (
          value == '' &&
          !this.attachmentPreviews[this.activeIndex]?.['confirmed']
        ) {
          this.attachments.removeAt(this.activeIndex);
          this.attachmentPreviews.splice(this.activeIndex, 1);
          this.emitChange();
        } else if (this.oldPreview) {
          this.attachments.controls[this.activeIndex].patchValue(
            this.oldPreview
          );
          this.attachmentPreviews[this.activeIndex] = this.oldPreview;
          this.oldPreview = null;
        }
      }

      this.uploading = false;
      this.videoForm.reset();
      this.confirmed = false;
      this.activeIndex = -1;
      this.checkConvertMessage();
    });
  }

  public removeAttachment() {
    this.confirmed = true;
    this.attachments.removeAt(this.activeIndex);
    this.attachmentPreviews.splice(this.activeIndex, 1);
    this.mediaModal.close();

    this.removed.push(this.activeIndex);
    this.emitChange();

    //check if the conversion message should still be shown
    this.convertMessage = false;
    this.checkConvertMessage();
  }

  public checkConvertMessage() {
    this.attachmentPreviews.forEach((attachment, _index) => {
      if (
        (this.isVideo(attachment) || this.isPdf(attachment)) &&
        attachment.isConverted != 1 &&
        this.removed.indexOf(_index) == -1
      ) {
        this.convertMessage = true;
      }
    });
  }

  public isRemoved(index: number) {
    return this.removed.includes(index);
  }

  public getUrl(attachment: Attachment, small: boolean = false): string {
    return attachment.preview
      ? attachment.preview
      : small
      ? attachment.thumbnailPathThumbnails?.medium ??
        attachment.filePathThumbnails?.small
      : attachment.thumbnailPathThumbnails?.medium ??
        attachment.filePathThumbnails?.medium;
  }

  public getVideoPoster(attachment: Attachment) {
    if (attachment.thumbnailPathThumbnails) {
      return this.sanitizer.bypassSecurityTrustUrl(
        attachment.filePathThumbnails.small
      );
    } else {
      return '';
    }
  }

  public getPreviewImage(
    attachment: Attachment,
    small: boolean = false
  ): SafeStyle | string {
    if (this.isVideo(attachment)) {
      if (attachment.thumbnailPathThumbnails) {
        return 'url("' + attachment.thumbnailPathThumbnails.small + '")';
      } else {
        return 'url("/assets/img/icons/type-video.svg")';
      }
    } else {
      const url =
        attachment.videoId != null
          ? AttachmentHelper.getVideoImageForId(attachment.videoId)
          : this.getUrl(attachment, small);

      return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
    }
  }

  public getPreviewVideo(attachment: Attachment): SafeUrl | string {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      attachment.preview
        ? attachment.preview
        : attachment.filePathThumbnails.medium
        ? attachment.filePathThumbnails.medium
        : attachment.filePath
    );
  }

  openMediaModal(attachment: Attachment, index: number): void {
    this.inputFile.reset();
    this.activePreview = attachment;

    if (attachment) {
      attachment.is360 = this.attachments.controls[index].get('is360').value;
      attachment.title = this.attachments.controls[index].get('title').value;
    }

    this.oldPreview = attachment;
    this.activeIndex = index;

    if (attachment) this.mode = 'edit';
    else this.mode = 'create';

    if (this.activeIndex == -1) {
      this.createAttachmentControl();
      this.activeIndex = this.attachments.length - 1;
    }

    this.form.updateValueAndValidity();
    this.ngZone.run(() => {
      this.mediaModal.open();
    });
  }

  isPdf(attachment: Attachment) {
    return /\.pdf$/.test(attachment.filePath);
  }

  isVideo(attachment: Attachment) {
    return (
      /\.mp4$/i.test(attachment.filePath) ||
      /\blob:$/i.test(attachment.filePath) ||
      /\.mov$/i.test(attachment.filePath)
    );
  }

  isImage(attachment: Attachment) {
    return (
      /\.jpg$/i.test(attachment.filePath) ||
      /\.jpeg:$/i.test(attachment.filePath) ||
      /\.png$/i.test(attachment.filePath)
    );
  }

  addVideoAttachment(event, replace?) {
    if (this.activeIndex > -1) {
      let attachment: Attachment;
      if (event.type == 'youtube') {
        // check if video already exists, cannot be because of front-end rendering
        for (const preview of this.attachmentPreviews) {
          if (preview.filePath === event.videoId) {
            return;
          }
        }

        attachment = {
          videoId: event.url,
          title:
            this.attachments.controls[this.activeIndex].get('title').value ||
            '',
          filePath: event.url,
          fileName: event.url,
        };
      } else {
        let path = URL.createObjectURL(event.file.rawFile);
        attachment = {
          title:
            this.attachments.controls[this.activeIndex].get('title').value ||
            '',
          filePath: event.url,
          preview: path,
          fileName: event.file.name,
        };
      }

      if (replace) this.attachmentPreviews[this.activeIndex] = attachment;
      else this.attachmentPreviews.push(attachment);
      this.attachments.controls[this.activeIndex].patchValue(attachment);
      this.activeIndex = this.attachments.length - 1;
      this.activePreview = this.attachmentPreviews[this.activeIndex];
      if (event.type != 'youtube') this.convertMessage = true;
    }
  }

  public writeValue(attachments: Attachment[]): void {
    for (const attachment of attachments) {
      // set video id as name and path for reference
      if (attachment.videoId != null) {
        attachment.filePath = attachment.videoId;
        attachment.fileName = attachment.videoId;
      }

      this.createAttachmentControl(attachment);
    }

    this.attachmentPreviews = attachments;

    this.attachmentPreviews.forEach((attachment) => {
      if (
        (this.isVideo(attachment) || this.isPdf(attachment)) &&
        attachment.isConverted != 1
      )
        this.convertMessage = true;
    });
  }

  public registerOnChange(fn: ChangeFn): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: ChangeFn): void {
    this.onTouchedFn = fn;
  }

  private createAttachmentControl(attachment?: Attachment): void {
    const group: FormGroup = this.fb.group({
      '@id': [''], // for api platform: keep reference
      id: [''],
      title: [''],
      videoId: [''],
      fileName: ['', Validators.required],
      filePath: ['', Validators.required],
      is360: [false],
    });
    if (attachment) group.patchValue(attachment);

    group.valueChanges
      .pipe(debounceTime(20))
      .subscribe((_) => this.emitChange());

    this.attachments.push(group);
    this.emitChange();
  }

  private emitChange() {
    const data = { ...this.form.value };

    for (const attachment of data.attachments) {
      delete attachment.preview;

      if (attachment.videoId != null && attachment.videoId !== '') {
        // file path is for reference only in case of a video, so don't fill
        delete attachment.filePath;
        delete attachment.fileName;
      } else {
        delete attachment.videoId;
      }
    }

    const object = { ...data.attachments };

    for (const removedIndex of this.removed) {
      delete object[removedIndex];
    }

    this.onTouchedFn(object);
    this.onChangeFn(object);
  }

  public supports360(): boolean {
    if (!this.with360) return false;
    if (this.activePreview) {
      return this.isImage(this.activePreview);
    } else {
      return false;
    }
  }

  private createForm() {
    this.form = this.fb.group({
      attachment: [''], // placeholder, won't be used but is for uploading
      attachments: this.fb.array([]),
    });

    this.form.get('attachment').valueChanges.subscribe((value) => {
      if (value != null && this.activeIndex > -1) {
        if (this.activePreview) {
          this.attachmentPreviews[this.activeIndex] = value;
        } else {
          this.attachmentPreviews.push(value);
          let index = this.attachmentPreviews.length - 1;

          setTimeout(() => {
            this.fileElements.toArray().forEach((el) => {
              if(el.nativeElement.getAttribute('data-index') == index) {
                if(this.hasDragged) {
                  const parentNode = el.nativeElement.parentNode;
                  parentNode.insertBefore(el.nativeElement, parentNode.children[parentNode.children.length - 1]);
                }
              }
            });
          });
        }

        if (this.isPdf(value)) {
          this.convertMessage = true;
        }

        this.activePreview = this.attachmentPreviews[this.activeIndex];
        this.attachments.controls[this.activeIndex].patchValue(value);
      }
    });

    this.videoForm = this.fb.group({
      youtubeUrl: [
        null,
        [Validators.required, Validators.pattern(this.videoUrlRegex)],
      ],
    });

    this.videoForm.get('youtubeUrl').valueChanges.subscribe((value) => {
      if (this.videoForm.get('youtubeUrl').valid && value != null) {
        const videoId = value.match(this.videoUrlRegex)[1];
        this.addVideoAttachment(
          { url: videoId, type: 'youtube' },
          this.activePreview && this.activePreview.videoId ? true : false
        );
      } else {
        this.activePreview = null;
      }
    });
  }

  updateAttachmentsSortOrder(event, type) {
    this.hasDragged = true;
    this.form.get('attachments').patchValue(event);
    this.attachmentPreviews = event;
  }

  ngOnDestroy() {
    this.dragulaService.destroy(Sortable.ATTACHMENTS);
  }
}
