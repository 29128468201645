import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from './modal.component';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { UserDataService } from '../services/user-data.service';
import { Customer } from '../interfaces/customer';
import { Subject } from 'rxjs';
import { ErrorService } from '../services/error.service';
import { Module } from '../enums/module';
import { ModuleService } from '../services/module.service';
import { AccessService } from '../services/access.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor } from '@angular/common';
import { FormGroupComponent } from './form-group.component';

@Component({
    selector: 'app-customer-picker',
    templateUrl: './customer-picker.component.html',
    standalone: true,
    imports: [ModalComponent, ReactiveFormsModule, FormGroupComponent, NgFor, TranslateModule]
})
export class CustomerPickerComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) private modal: ModalComponent;
  @Input() withProjectPermissions: boolean = false;
  public customerList: Customer[] = [];
  public filteredList: Customer[] = [];
  public form: FormGroup;

  private customerSubject: Subject<Customer>;

  constructor(
    private fb: FormBuilder,
    private accessService: AccessService,
    private userDataService: UserDataService,
    private errorService: ErrorService,
    private moduleService: ModuleService
  ) {
    this.createForm();
  }

  async ngOnInit() {
    this.customerList = await this.userDataService.retrieveCustomers();
    this.accessService.accessControlList.subscribe((projectUser) => {
      if (!this.withProjectPermissions) {
        return;
      }
      if (projectUser.mappings !== undefined) {
        projectUser.mappings.forEach((item) => {
          if (item.permissionType === 'create') {
            if (!this.customerList.includes(item.project.customer)) {
              this.customerList.unshift(item.project.customer);
            }
          }
        });
      }
      this.customerList = this.customerList.filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
      );
    });
  }

  public async pick(module?: Module): Promise<Customer> {
    let list = this.customerList;

    if (!!module) {
      list = await this.moduleService.getCustomersWithModule(module);
    }

    this.filteredList = list;

    if (this.filteredList.length === 1) {
      return this.filteredList[0];
    } else {
      this.modal.open();

      this.customerSubject = new Subject<Customer>();

      const customer: Customer = await this.customerSubject.toPromise();
      this.customerSubject = null;

      this.modal.close();

      return customer;
    }
  }

  public selectCompany(): void {
    this.errorService.markFormGroupTouchedAndDirty(this.form);

    if (this.form.valid) {
      const customer = this.customerList.find(
        (item) => item.id === +this.form.value.customer
      );

      if (customer !== null) {
        this.customerSubject.next(customer);
        this.customerSubject.complete();
      }
    }
  }

  public modalClosed(): void {
    if (this.customerSubject !== null) {
      this.customerSubject.complete();
      this.customerSubject = null;
    }
  }

  private createForm(): void {
    this.form = this.fb.group({
      customer: ['', [Validators.required]],
    });
  }
}
