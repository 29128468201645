import { Injectable } from '@angular/core';
import { AccessService } from './access.service';
import { Customer } from '../interfaces/customer';
import { Module } from '../enums/module';
import { AccessControlList } from '../interfaces/access-control-list';

@Injectable()
export class ModuleService {
  constructor(private accessService: AccessService) {}

  public async someCustomerOrProjectHasModule(
    module: Module,
    list: AccessControlList
  ) {
    return (
      list.customers.some((customer) =>
        this.hasModule(customer.customer, module)
      ) ||
      list.mappings.some(
        (mapping) =>
          mapping.project.enabledModules &&
          mapping.project.enabledModules.includes(module)
      )
    );
  }

  public hasModule(customer: Customer, module: Module) {
    return customer.enabledModules && customer.enabledModules.includes(module);
  }

  public async getCustomersWithModule(module: Module): Promise<Customer[]> {
    const customers = await this.accessService.loadCustomers();
    let convertedCustomers = customers.map((item) => item.customer);
    return convertedCustomers.filter((customer) =>
      this.hasModule(customer, module)
    );
  }
}
