import {
  Directive,
  Input,
  ViewContainerRef,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { ThemeService } from '../services/theme.service';

@Directive({
  selector: '[appVersion]',
  standalone: true,
})
export class VersionDirective implements OnInit {
  @Input() appVersion!: 1 | 2;
  component: any;

  constructor(
    private templateRef: TemplateRef<any>,
    private versionService: ThemeService,
    private viewContainer: ViewContainerRef
  ) {}

  async ngOnInit() {
    const appVersion = await this.versionService.getVersion();
    if (this.appVersion === appVersion) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
