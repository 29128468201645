<div class="form-group" [ngClass]="{ 'has-error': hasInvalidState() }">
  <ng-content></ng-content>

  <div class="errors" *ngIf="hasInvalidState() && control.errors && asText">
    <div *ngIf="control.errors.equalPassword === false">
      {{ 'form_group.equal_password' | translate }}
    </div>
    <div *ngIf="control.errors.invalidDateRange">
      {{ 'form_group.invalid_date_range' | translate }}
    </div>
    <div *ngIf="control.errors.invalidDateEnd">
      {{ 'form_group.invalid_date_end' | translate }}
    </div>
    <div *ngIf="control.errors.invalidDate">
      {{ control.errors.message }}
    </div>
    <div *ngIf="control.errors.uploadFileTypeInvalid === false">
      {{ 'form_group.upload_file_type' | translate }}
    </div>
    <div
      *ngIf="
        control.errors.required &&
        !control.errors.uploadServerError &&
        !control.errors.targetError
      "
    >
      {{ 'form_group.required' | translate }}
    </div>
    <div *ngIf="control.errors.invalidLocation">
      {{ 'form_group.invalid_location' | translate }}
    </div>
    <div *ngIf="control.errors.targetError">
      {{ 'form_group.target_error' | translate }}
    </div>
    <div *ngIf="control.errors.uploadServerError">
      {{ control.errors.uploadServerError }}
    </div>
    <div *ngIf="control.errors.pattern?.requiredPattern">
      {{ 'form_group.invalid_pattern' | translate }}
    </div>
    <div *ngIf="control.errors.maxlength">
      {{
        'form_group.max_length'
          | translate : { max: control.errors.maxlength.requiredLength }
      }}
    </div>
    <div *ngIf="control.errors.server">
      <div *ngIf="control.errors.server.length === 1">
        {{ control.errors.server[0] }}
      </div>
      <ul *ngIf="control.errors.server.length > 1">
        <li *ngFor="let message of control.errors.server">{{ message }}</li>
      </ul>
    </div>
    <div *ngIf="control.errors.videoError">{{ control.errors.message }}</div>
    <div *ngIf="control.errors.validatePhoneNumber">
      {{ 'form_group.phone_number_invalid' | translate }}
    </div>
    <div *ngIf="control.errors.pdfMaxSizeExceeded">
      {{ control.errors.pdfMaxSizeExceeded }}
    </div>
    <div *ngIf="control.errors.imageMaxSizeExceeded">
      {{ control.errors.imageMaxSizeExceeded }}
    </div>
  </div>
</div>
