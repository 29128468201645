import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Project } from '../interfaces/project';

@Injectable()
/**
 * This class is used to send data from the DetailComponent to its childs, which are routes. It's also used for a list of small projects
 */
export class ProjectDataBusService {
  private project: Subject<Project> = new BehaviorSubject<Project>(null);
  projectObservable = this.project.asObservable();

  private isConcept: Subject<boolean> = new BehaviorSubject<boolean>(false);
  isConceptObservable = this.isConcept.asObservable();

  private isLoading: Subject<boolean> = new BehaviorSubject<boolean>(false);
  isLoadingObservable = this.isLoading.asObservable();

  private shortList: Subject<Project[]> = new BehaviorSubject<Project[]>(null);
  shortListObservable = this.shortList.asObservable();

  emitProject(data: Project) {
    this.project.next(data);
  }

  emitShortList(data: Project[]) {
    this.shortList.next(data);
  }

  emitIsConcept(value: boolean) {
    this.isConcept.next(value);
  }

  emitIsLoading(value: boolean) {
    this.isLoading.next(value);
  }
}
