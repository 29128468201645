<div class="container">
    <div class="bouwapp-header">
        <div class="bouwapp-header__text">
            <h1>{{ 'smart_reporting.notice_create.title' | translate }}</h1>
        </div>
    </div>
</div>

<div class="container">
    <app-loader *ngIf="themes === null"></app-loader>

    <form [formGroup]="form" appFormChangeDetector *ngIf="themes !== null" (ngSubmit)="submit()">
        <div *ngIf="error" class="alert alert-danger">
            {{ 'smart_reporting.notice_create.error' | translate }}
        </div>

        <div class="row">
            <div class="col-md-6">
                <h3>{{ 'smart_reporting.notice_create.information' | translate }}</h3>

                <app-form-group [form]="form" errorPath="theme">
                    <label for="theme">{{
                            'smart_reporting.notice_create.theme' | translate
                        }}</label>

                    <select formControlName="theme" id="theme">
                        <option [ngValue]="null">
                            {{ 'smart_reporting.notice_create.theme.placeholder' | translate }}
                        </option>
                        <option *ngFor="let theme of themes" [ngValue]="theme">
                            {{ theme.title }}
                        </option>
                    </select>
                </app-form-group>

                <app-form-group [form]="form" errorPath="description">
                    <label for="description">{{
                            'smart_reporting.notice_create.description' | translate
                        }}</label>

                    <textarea formControlName="description" id="description"></textarea>
                </app-form-group>

                <app-form-group [form]="form" errorPath="attachment">
                    <label for="description">{{
                            'smart_reporting.notice_create.attachment' | translate
                        }}</label>

                    <app-input-file
                        [simple]="true"
                        formControlName="attachment"
                        type="smart-reporting-notice-attachment"
                        [label]="'smart_reporting.notice_create.attachment.label' | translate"
                        [labelSelectedValue]="
            'smart_reporting.notice_create.attachment.label.selected'
              | translate
          "
                        [uploadImages]="true"
                        [simpleValue]="form.get('attachment').value"
                        (startUploading)="startUploading()"
                        (stopUploading)="stopUploading()"
                        #attachmentUpload
                    >
                    </app-input-file>
                </app-form-group>

                <br /><br />

                <app-form-group [form]="form" errorPath="name">
                    <label for="name">{{
                            'smart_reporting.notice_create.name' | translate
                        }}</label>

                    <input type="text" formControlName="name" id="name" />
                </app-form-group>

                <app-form-group [form]="form" errorPath="phoneNumber">
                    <label for="phoneNumber">{{
                            'smart_reporting.notice_create.phoneNumber' | translate
                        }}</label>

                    <input type="text" formControlName="phoneNumber" id="phoneNumber" />
                </app-form-group>

                <app-form-group [form]="form" errorPath="email">
                    <label for="email">{{
                            'smart_reporting.notice_create.email' | translate
                        }}</label>

                    <input type="text" formControlName="email" id="email" />
                </app-form-group>
            </div>
            <div class="col-md-6">
                <h3>{{ 'smart_reporting.notice_create.location' | translate }}</h3>

                <span *ngIf="locationError" class="smart-reporting__error-text">{{
                        'smart_reporting.notice_create.location.error' | translate
                    }}</span>

                <div class="smart-reporting__notice-create__map">
                    <mgl-map #map *ngIf="ready" (mapClick)="mapClick($event)" class="map"
                             [style]="'mapbox://styles/mapbox/streets-v11'" [zoom]="12" [maxZoom]="20"
                             [center]="[locationLong, locationLat]">
                        <mgl-control mglNavigation [showCompass]="false"></mgl-control>
                    </mgl-map>
                </div>
                <p>
                    <img src="/assets/img/icons/info-dark.svg" width="16" height="16"
                         class="smart-reporting__notice-create__info" />
                    {{ 'smart_reporting.notice_create.location.info' | translate }}
                </p>
            </div>
        </div>

        <div class="justified-buttons">
            <button class="btn btn-lg" [appLoading]="loading || uploading">
                {{ 'smart_reporting.notice_create.submit' | translate }}
            </button>
        </div>
    </form>
</div>
