<app-success-message [hidden]="!inviteSaved" [onScreen]="true">
  {{ 'project.detail.settings.users.invited' | translate }}
</app-success-message>

<app-success-message [hidden]="!editSaved" [onScreen]="true">
  {{ 'project.detail.settings.users.updated' | translate }}
</app-success-message>

<app-success-message [hidden]="!deleted" [onScreen]="true">
  {{ 'project.detail.settings.users.delete' | translate }}
</app-success-message>

<app-error-message [hidden]="!cannotSave" [onScreen]="true">
  {{ 'project.detail.settings.users.exists' | translate }}
</app-error-message>

<app-manage-users
  [loading]="loading"
  [projectUsers]="projectUsers"
  (deleteUser)="delete($event)"
  (editUser)="update($event)"
  (createUser)="create($event)"
  [customer]="customer"
>
</app-manage-users>

<section class="projects detail projects-settings container">
  <app-list-users
    [loading]="loading"
    [projectUsers]="projectUsers"
    [customer]="customer"
  ></app-list-users>
</section>
