import { Component, Input } from '@angular/core';
import { Ticket } from '../interfaces/ticket';
import { TicketService } from '../services/ticket.service';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf, DatePipe } from '@angular/common';
import { VersionDirective } from '../directives/version.directive';

@Component({
    selector: '[app-ticket]',
    templateUrl: 'ticket.component.html',
    standalone: true,
    imports: [NgIf, InlineSVGModule, DatePipe, VersionDirective, TranslateModule]
})
export class TicketComponent {
  @Input() ticket: Ticket;
  @Input() withProject = false;

  constructor(private ticketService: TicketService) {}
}
