import { Injectable } from '@angular/core';
import { Announcement } from '../interfaces/announcement';
import { CollectionHelper } from '../shared/collection-helper';
import { PhaseService } from './phase.service';
import { Phase } from '../interfaces/phase';
import { Attachment } from '../interfaces/attachment';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class AnnouncementService {
  static base = '/announcements';
  static attachmentBase = '/announcement-attachments';

  constructor(private http: HttpClient) {}

  public create(phaseId: number, data: any): Promise<Announcement> {
    data.phase = '/api' + PhaseService.base + '/' + phaseId;
    data.targets = CollectionHelper.addHydraId(data.targets, 'slug', 'targets');
    data.targets = CollectionHelper.mapHydraId(data.targets);

    return this.http
      .post<Announcement>(environment.apiUrl + AnnouncementService.base, data)
      .toPromise();
  }

  public move(data: any, target: Phase): Promise<Announcement> {
    data.phase = '/api' + PhaseService.base + '/' + target.id;

    return this.http
      .put<Announcement>(
        environment.apiUrl + AnnouncementService.base + '/' + data.id,
        data
      )
      .toPromise();
  }

  /**
   * @param {Announcement} announcement
   * @returns {Promise<void>}
   */
  public async remove(announcement: Announcement): Promise<void> {
    await this.http
      .delete<Announcement>(
        environment.apiUrl + AnnouncementService.base + '/' + announcement.id
      )
      .toPromise();

    return;
  }

  /**
   * @param {Announcement} announcement
   * @returns {Promise<Announcement>}
   */
  public async update(announcement: Announcement): Promise<Announcement> {
    announcement.targets = CollectionHelper.addHydraId(
      announcement.targets,
      'slug',
      'targets'
    );
    announcement.targets = CollectionHelper.mapHydraId(announcement.targets);

    return await this.http
      .put<Announcement>(
        environment.apiUrl + AnnouncementService.base + '/' + announcement.id,
        announcement
      )
      .toPromise();
  }

  /**
   * @param {number} id
   * @returns {Promise<Announcement>}
   */
  public fetch(id: number): Promise<Announcement> {
    return this.http
      .get<Announcement>(
        environment.apiUrl + AnnouncementService.base + '/' + id
      )
      .toPromise();
  }

  /**
   * @param {Attachment} attachment
   * @returns {Promise<Attachment>}
   */
  public updateAttachment(attachment: Attachment): Promise<Attachment> {
    return this.http
      .put<Announcement>(
        environment.apiUrl +
          AnnouncementService.attachmentBase +
          '/' +
          attachment.id,
        attachment
      )
      .toPromise();
  }

  public updateAttachmentSortOrder(id, data): Promise<Attachment[]> {
    return this.http
      .post(
        environment.apiUrl +
          AnnouncementService.attachmentBase +
          '/' +
          id +
          '/sort',
        data
      )
      .toPromise()
      .then((response) => {
        return response as Promise<Attachment[]>;
      });
  }
}
