<app-loader *ngIf="!customer"></app-loader>

<app-success-message [hidden]="!formSaved" [onScreen]="true">
    {{ 'profile.company.success' | translate }}
</app-success-message>

<div class="container">
    <div class="bouwapp-header">
        <div class="bouwapp-header__text">
            <h1>{{ 'profile.company.title' | translate }}</h1>
        </div>
        <div class="bouwapp-header__buttons">
            <button (click)="save()" type="submit" class="primary" [appLoading]="loading || uploading">
                {{ 'profile.company.save' | translate }}
            </button>
        </div>
    </div>

    <form appFormChangeDetector [formGroup]="form" (submit)="save()">
        <div class="bouwapp-group">
            <div class="bouwapp-group__header">
                <h2>{{ 'profile.company.general.title' | translate }}</h2>
            </div>

            <div class="bouwapp-group__row">
                <div class="bouwapp-group__item">
                    <app-form-group [form]="form" errorPath="name">
                        <label for="name">{{ 'profile.company.general.name' | translate }}</label>
                        <input type="text" id="name" formControlName="name" />
                    </app-form-group>
                </div>
            </div>

            <div class="bouwapp-group__row two">
                <div class="bouwapp-group__item">
                    <app-form-group [form]="form" errorPath="logo">
                        <label>{{ 'profile.company.general.logo' | translate }}</label>
                        <app-input-file
                            type="customer-logo"
                            [uploadImages]="true"
                            formControlName="logo"
                            [rotateWarning]="false"
                            (startUploading)="startUploading()"
                            (stopUploading)="stopUploading()"
                            (previewUpdated)="preview.update($event)"
                        ></app-input-file>
                    </app-form-group>
                </div>
                <div class="bouwapp-group__item">
                    <app-form-group>
                        <label>{{ 'profile.company.general.logo.preview' | translate }}</label>
                        <app-input-file-preview #preview [thumbnails]="customer?.logoThumbnails"
                            [deleteText]="'profile.company.general.logo.delete' | translate"
                            formControlName="logo"></app-input-file-preview>
                    </app-form-group>
                </div>
            </div>

            <div class="bouwapp-group__row">
                <div class="bouwapp-group__item">
                    <app-form-group [form]="form" errorPath="description">
                        <label for="description">{{ 'profile.company.general.description' | translate }}</label>
                        <textarea rows="6" id="description" formControlName="description"></textarea>
                    </app-form-group>
                </div>
            </div>
        </div>

        <div class="bouwapp-group">
            <div class="bouwapp-group__header">
                <h2>{{ 'profile.company.contact.title' | translate }}</h2>
            </div>

            <div class="bouwapp-group__row three">
                <div class="bouwapp-group__item">
                    <app-form-group [form]="form" errorPath="email">
                        <label for="email">{{ 'profile.company.contact.email' | translate }}</label>
                        <input type="email" id="email" formControlName="email" />
                    </app-form-group>
                </div>
                <div class="bouwapp-group__item">
                    <app-form-group [form]="form" errorPath="street">
                        <label for="street">{{ 'profile.company.contact.street' | translate }}</label>
                        <input type="text" id="street" formControlName="street" />
                    </app-form-group>
                </div>
                <div class="bouwapp-group__item">
                    <app-form-group [form]="form" errorPath="streetNumber" cssClass="street-number">
                        <label for="streetNumber">{{ 'profile.company.contact.street_number' | translate }}</label>
                        <input type="text" id="streetNumber" formControlName="streetNumber" />
                    </app-form-group>
                </div>
            </div>

            <div class="bouwapp-group__row three">
                <div class="bouwapp-group__item">
                    <app-form-group [form]="form" errorPath="phoneNumber">
                        <label for="phoneNumber">{{ 'profile.company.contact.phone' | translate }}</label>
                        <input type="text" id="phoneNumber" formControlName="phoneNumber" />
                    </app-form-group>
                </div>
                <div class="bouwapp-group__item">
                    <app-form-group [form]="form" errorPath="zipcode" cssClass="zipcode">
                        <label for="zipcode">{{ 'profile.company.contact.zip_code' | translate }}</label>
                        <input type="text" id="zipcode" formControlName="zipcode" />
                    </app-form-group>
                </div>
                <div class="bouwapp-group__item">
                    <app-form-group [form]="form" errorPath="city">
                        <label for="city">{{ 'profile.company.contact.city' | translate }}</label>
                        <input type="text" id="city" formControlName="city" />
                    </app-form-group>
                </div>
            </div>
        </div>
    </form>
</div>
