<div class="multi-attachment__group">
  <div class="multi-attachment__list">
    <div class="list" [dragula]="Sortable.ATTACHMENTS" [(dragulaModel)]="attachmentPreviews"
      (dragulaModelChange)="updateAttachmentsSortOrder($event, type)">
      <div #file class="file" [attr.data-index]="i" (click)="openMediaModal(attachment, i)" [class.pdf]="isPdf(attachment)"
        *ngFor="let attachment of attachmentPreviews; let i = index">
        <div class="file__image" [style.backgroundImage]="getPreviewImage(attachment, true)">
          <div class="file__overlay">
            <span class="file__360" inlineSVG="/assets/img/icons/360.svg" *ngIf="attachments.controls[i].get('is360').value"></span>
            <div class="file__edit" inlineSVG="/assets/img/icons/edit.svg"></div>
          </div>
        </div>
        <div class="file__drag" inlineSVG="/assets/img/icons/handle.svg"></div>
      </div>

      <div class="file disable-drag" (click)="openMediaModal(null, -1)">
        <div class="file__image new">
          <div class="file__overlay">
            <div class="file__add" inlineSVG="/assets/img/icons/add.svg"></div>
          </div>
        </div>
      </div>
    </div>

    <p class="alert alert-info" *ngIf="convertMessage">
      {{ 'multi_attachment_control.convert.warning' | translate }}
    </p>
  </div>
</div>

<app-modal class="media-modal" #mediaModal [title]="(activePreview && (activePreview.id || activePreview['confirmed']) ? 'projects.detail.update.media.edit' : 'projects.detail.update.media.new') | translate">
  <p class="modal-description" *ngIf="withVideo">{{ 'projects.detail.update.media.description' | translate }}</p>
  <div class="multi-attachment" [formGroup]="form">
    <div class="multi-attachment__form" formArrayName="attachments" *ngIf="attachments.controls.length > 0 && (withVideo || withDescription || with360)">
      <div class="multi-attachment__control-holder" [formGroupName]="activeIndex" *ngIf="activeIndex >= 0">
        <app-form-group [form]="attachments.controls[activeIndex]" errorPath="title" cssClass="multi-attachment__form-group__holder">
          <label>{{ 'projects.detail.update.media.title' | translate }}</label>
          <textarea
            rows="4"
            [attr.placeholder]="'projects.detail.update.media.title.placeholder' | translate"
            class="input"
            formControlName="title">
          </textarea>
        </app-form-group>
        <div class="multi-attachment__360-option" *ngIf="supports360()">
          <label>{{ 'projects.detail.update.media.360' | translate }}</label>
          <span>{{ 'projects.detail.update.media.360.description' | translate }}</span>
          <app-toggle formControlName="is360" [small]="true"></app-toggle>
        </div>
        <app-form-group [form]="videoForm" [formGroup]="videoForm" errorPath="youtubeUrl" cssClass="multi-attachment__youtube" *ngIf="!activePreview && withVideo">
          <label>{{ 'projects.detail.update.media.youtube.title' | translate }}</label>
          <input class="input input--m" formControlName="youtubeUrl"/>
        </app-form-group>
      </div>
    </div>
    <div class="multi-attachment__click">
      <app-form-group [form]="form" errorPath="attachment">
        <label>
          {{ 'projects.detail.update.media.label' | translate }}
          <span *ngIf="withVideo">({{ 'projects.detail.update.media.filetypes' | translate }})</span>
          <span *ngIf="!withVideo">({{ 'projects.detail.update.media.filetypes-limited' | translate }})</span>
        </label>
        <div class="cover" [class.with-image]="activePreview" [style.backgroundImage]="activePreview ? getPreviewImage(activePreview) : null">
          <video #videoPlayer [poster]="getVideoPoster(activePreview)" controls *ngIf="activePreview && isVideo(activePreview)">
            <source [src]="getPreviewVideo(activePreview)" />
          </video>
          <app-input-file-v2
            *ngIf="!activePreview || (activePreview && !isVideo(activePreview))"
            class="input-file-holder"
            [type]="type"
            [uploadImages]="true"
            [uploadVideos]="withVideo"
            [uploadPdfs]="true"
            (videoChosen)="addVideoAttachment($event)"
            (startUploading)="uploading = true"
            (stopUploading)="uploading = false"
            formControlName="attachment">
          </app-input-file-v2>
        </div>
      </app-form-group>
    </div>
    <div class="multi-attachment__buttons">
      <button type="button" [disabled]="uploading || !activePreview" *ngIf="mode == 'edit'" (click)="removeAttachment()" class="btn button--m button--outlined bg--red--hover">{{ 'projects.detail.update.media.remove' | translate }}</button>
      <button type="button" [disabled]="uploading || !activePreview" (click)="confirm()" class="btn button--m button--flat button--primary">
        {{ (mode == 'create' ? 'projects.detail.update.media.add' : 'projects.detail.update.media.save') | translate }}
      </button>
    </div>
  </div>
</app-modal>
