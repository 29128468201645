import { UserDataService } from './user-data.service';
import { UserService } from './user.service';
import { User } from '../interfaces/user';
import { Customer } from '../interfaces/customer';
import { ProjectUser } from '../interfaces/project-user';
import { ProjectUserService } from './project-user.service';
import { ProjectUserPermissions } from '../interfaces/project-user-permissions';
import { AccessControlList } from '../interfaces/access-control-list';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Subject, of } from 'rxjs';
import * as moment from 'moment';
import { switchMap, take } from 'rxjs/operators';
import { Project } from '../interfaces/project';

@Injectable()
export class AccessService {
  static readonly STORE_KEY = 'acl';
  static readonly EXPIRY_HOURS = 1;
  public tokenReceived = new BehaviorSubject<boolean>(false);

  private access: Subject<AccessControlList> =
    new BehaviorSubject<AccessControlList>(null);
  accessControlList = this.access.asObservable();

  constructor(
    private userDataService: UserDataService,
    private projectUserService: ProjectUserService,
    private userService: UserService,
    private storageService: LocalStorageService
  ) {
    this.initializeList();
  }

  public async updateAccessControlList(): Promise<void> {
    return this.tokenReceived
      .pipe(
        take(1),
        switchMap(async (value) => {
          if (false === value) {
            return of(null).toPromise();
          }
          const list = await this.fetchAccessControlList();
          await this.storageService.store(
            AccessService.STORE_KEY,
            JSON.stringify(list)
          );
          this.access.next(list);
        })
      )
      .toPromise();
  }
  public async fetchAccessControlList(): Promise<AccessControlList> {
    let customers: ProjectUserPermissions[];
    let mappings: ProjectUserPermissions[];

    await Promise.all([
      this.loadCustomers().then((result) => (customers = result)),
      this.loadProjectMappings().then((result) => (mappings = result)),
    ]);

    return {
      lastUpdated: new Date(),
      customers,
      mappings,
    };
  }

  public async loadLocalAccessControlList(): Promise<AccessControlList | null> {
    const localString = await this.storageService.retrieve(
      AccessService.STORE_KEY
    );

    if (localString == null) {
      return null;
    } else {
      let local;

      try {
        local = JSON.parse(localString);
      } catch (error) {
        local = null;
      }

      if (local != null) {
        local.lastUpdated = moment(local.lastUpdated).toDate();
      }

      if (
        local == null ||
        this.isExpired(
          local.lastUpdated,
          new Date(),
          AccessService.EXPIRY_HOURS
        )
      ) {
        await this.updateAccessControlList();
      }
      return local;
    }
  }

  private isExpired(date1: Date, date2: Date, threshold: number): boolean {
    return (
      Math.floor(Math.abs(date1.getTime() - date2.getTime()) / 36e5) > threshold
    );
  }

  private async initializeList(): Promise<void> {
    const list = await this.loadLocalAccessControlList();

    if (list != null) {
      this.access.next(list);
    }
  }

  public getCustomersFromACL(list: AccessControlList): Customer[] {
    const mappings = list.mappings.map((m) => m.project.customer);

    let customers = list.customers.map((item) => {
      return item.customer;
    });

    if (undefined !== mappings) {
      customers = [...customers, ...mappings];
    }

    return customers.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    );
  }

  public getProjectsFromACL(list: AccessControlList): Project[] {
    return list.mappings.map((m) => m.project);
  }

  public async loadCustomers(): Promise<ProjectUserPermissions[]> {
    let userId = await this.userDataService.retrieveId();

    if (userId == null) {
      await this.userDataService.userLoaded.pipe(take(1)).toPromise();
      userId = await this.userDataService.retrieveId();
    }

    const user: User = await this.userService.fetch(userId);
    return user.projectUser.customersProjectUser;
  }

  private async loadProjectMappings(): Promise<ProjectUserPermissions[]> {
    let projectUser: ProjectUser =
      await this.userDataService.retrieveProjectUser();

    if (projectUser.id == null) {
      await this.userDataService.projectUserLoaded.pipe(take(1)).toPromise();
      projectUser = await this.userDataService.retrieveProjectUser();
    }

    return await this.projectUserService.fetchListForProjectUser(projectUser);
  }
}
