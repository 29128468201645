<section class="projects projects-settings">
  <app-manage-users
    [loading]="loading"
    [projectUsers]="projectUsers"
    (deleteUser)="delete($event)"
    (editUser)="update($event)"
    (createUser)="create($event)"
    [project]="project"
  ></app-manage-users>
  <app-list-users
    [loading]="loading"
    [projectUsers]="projectUsers"
    [customer]="project.customer"
    [project]="project"
    [editAdmins]="false"
  ></app-list-users>
</section>
<app-success-message [hidden]="!inviteSaved" [onScreen]="true">
  {{ 'project.detail.settings.users.invited' | translate }}
</app-success-message>

<app-success-message [hidden]="!editSaved" [onScreen]="true">
  {{ 'project.detail.settings.users.updated' | translate }}
</app-success-message>

<app-success-message [hidden]="!deleted" [onScreen]="true">
  {{ 'project.detail.settings.users.delete' | translate }}
</app-success-message>

<app-error-message [hidden]="!cannotSave" [onScreen]="true">
  {{ 'project.detail.settings.users.exists' | translate }}
</app-error-message>
