<app-loader *ngIf="edit && !announcement"></app-loader>

<!-- New/Edit Announcement -->
<section
  class="announcements new-announcement"
  [hidden]="edit && !announcement"
>
  <form appFormChangeDetector [formGroup]="announcementForm" (submit)="saveAnnouncement()">
    <div class="bouwapp-box full">
      <div class="bouwapp-box__title">
        <h2 *ngIf="!edit">
          {{ 'projects.detail.announcement.edit.new' | translate }}
        </h2>
        <h2 *ngIf="edit">
          {{ 'projects.detail.announcement.edit.edit' | translate }}
        </h2>
      </div>

      <div class="bouwapp-box__self">
        <app-form-group [form]="announcementForm" errorPath="title">
          <label for="title"
            >{{ 'projects.detail.announcement.edit.title' | translate }}*</label
          >
          <input
            type="text"
            class="input input--m"
            id="title"
            formControlName="title"
          />
        </app-form-group>

        <app-form-group [form]="announcementForm" errorPath="content">
          <label
            >{{
              'projects.detail.announcement.edit.message' | translate
            }}*</label
          >
          <app-quill
            [formGroup]="announcementForm"
            controlName="content"
          ></app-quill>
        </app-form-group>

        <app-form-group [form]="announcementForm" errorPath="type">
          <label
            >{{ 'projects.detail.announcement.edit.type' | translate }}*</label
          >
          <ul class="announcement--type">
            <li *ngFor="let announcementType of announcementTypes">
              <input
                type="radio"
                value="{{ announcementType }}"
                formControlName="type"
                id="{{ 'type' + announcementType }}"
              />
              <label for="{{ 'type' + announcementType }}">
                <span>{{
                  'projects.detail.announcement.type.' + announcementType
                    | translate
                }}</span>
                <span
                  [inlineSVG]="
                    '/assets/img/icons/message-' + announcementType + '.svg'
                  "
                ></span>
              </label>
            </li>
          </ul>
        </app-form-group>

        <div class="form-split">
          <div>
            <div class="form-date">
              <p>{{ 'projects.detail.announcement.edit.start' | translate }}</p>
              <app-form-group [form]="announcementForm" errorPath="startDate">
                <app-date-picker
                  formControlName="startDate"
                  [withTime]="true"
                  [typeControl]="announcementForm.get('startType')"
                ></app-date-picker>
              </app-form-group>
            </div>
          </div>
          <div>
            <div class="form-date">
              <p>{{ 'projects.detail.announcement.edit.end' | translate }}</p>
              <app-form-group [form]="announcementForm" errorPath="endDate">
                <app-date-picker
                  formControlName="endDate"
                  [withTime]="true"
                  [typeControl]="announcementForm.get('endType')"
                ></app-date-picker>
              </app-form-group>
            </div>
          </div>
        </div>

        <div *ngIf="!dateRangeValid" class="form-date__notice combi">
          {{ 'projects.detail.announcement.date.invalid' | translate }}
        </div>

        <div
          class="form-attachments form-group multi-attachment announcement__media"
        >
          <label>{{
            'projects.detail.announcement.edit.media' | translate
          }}</label>
          <app-multi-attachment-control
            formControlName="attachments"
            previewBasePath="/announcement/document"
            type="announcement-document"
            [id]="announcement?.id"
            (startUploading)="startUploading()"
            (stopUploading)="stopUploading()"
            [withDescription]="false"
            [withVideo]="false"
          ></app-multi-attachment-control>
        </div>

        <div class="form-group">
          <label class="label-lg">{{
            'projects.detail.announcement.edit.push_notification' | translate
          }}</label>

          <p>
            {{
              'projects.detail.announcement.edit.push_notification.description'
                | translate
            }}
          </p>

          <div class="alert alert-info" *ngIf="pushSent">
            {{
              'projects.detail.announcement.edit.push_notification.sent'
                | translate
            }}
          </div>

          <a
            href="javascript:void(0);"
            (click)="togglePush()"
            *ngIf="!pushSent && !hasPush"
            >{{
              'projects.detail.announcement.edit.push_notification.add'
                | translate
            }}</a
          >
          <a
            href="javascript:void(0);"
            (click)="togglePush()"
            *ngIf="!pushSent && hasPush"
            >{{
              'projects.detail.announcement.edit.push_notification.remove'
                | translate
            }}</a
          >

          <div [hidden]="!hasPush || pushSent">
            <div class="row">
              <div class="col-md-6">
                <app-form-group
                  [form]="announcementForm"
                  errorPath="pushDateTime"
                >
                  <label class="label-lg">{{
                    'projects.detail.announcement.edit.push_notification.date_time'
                      | translate
                  }}</label>
                  <app-date-picker
                    formControlName="pushDateTime"
                    [withTime]="true"
                  ></app-date-picker>
                </app-form-group>

                <div
                  class="alert alert-info"
                  [hidden]="!pushInPast || (announcement && pushSent)"
                >
                  <strong>{{
                    'projects.detail.announcement.edit.pay_attention'
                      | translate
                  }}</strong>
                  {{
                    'projects.detail.announcement.edit.pay_attention.push'
                      | translate
                  }}
                </div>
              </div>

              <div class="col-md-6">
                <app-form-group [form]="announcementForm" errorPath="targets">
                  <label class="label-lg">{{
                    'projects.detail.announcement.edit.target_audiences'
                      | translate
                  }}</label>

                  <p>
                    {{
                      'projects.detail.announcement.edit.target_audiences.info'
                        | translate
                    }}
                  </p>

                  <app-target-picker
                    [project]="project"
                    [multiple]="true"
                    [label]="false"
                    formControlName="targets"
                  ></app-target-picker>
                </app-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bouwapp-bottombar">
      <a
        class="button button--l button--outlined bg--grey--hover"
        routerLink="/projects/{{ project.slug }}/phases"
      >
        {{ 'projects.detail.announcement.edit.cancel' | translate }}
      </a>
      <button
        class="button button--l button--flat bg--orange"
        type="button"
        (click)="saveAnnouncement()"
        [appLoading]="loading || uploading"
      >
        {{ 'projects.detail.announcement.edit.save' | translate }}
      </button>
    </div>
  </form>
</section>
