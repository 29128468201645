import { Component, OnInit } from '@angular/core';
import { Phase } from '../../../../interfaces/phase';
import { PhaseService } from '../../../../services/phase.service';
import { ProjectDataBusService } from '../../../../services/project-data-bus.service';
import { Project } from '../../../../interfaces/project';
import { Announcement } from '../../../../interfaces/announcement';
import { AnnouncementService } from '../../../../services/announcement.service';
import * as moment from 'moment';
import { DateTypePipe } from '../../../../pipes/date-type.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { ConfirmDeleteComponent } from '../../../../components/confirm-delete.component';
import { DetailAnnouncementsTileComponent } from '../detail-announcements/tile.component';
import { RouterLink } from '@angular/router';
import { AccessDirective } from '../../../../directives/access.directive';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LoaderComponent } from '../../../../components/loader.component';
import { NgIf, NgClass, NgFor, SlicePipe } from '@angular/common';

@Component({
  selector: 'app-detail-phases-list',
  templateUrl: './list.component.html',
  standalone: true,
  imports: [
    NgIf,
    LoaderComponent,
    NgClass,
    InlineSVGModule,
    AccessDirective,
    NgFor,
    RouterLink,
    DetailAnnouncementsTileComponent,
    ConfirmDeleteComponent,
    SlicePipe,
    TruncateModule,
    TranslateModule,
    DateTypePipe,
  ],
})
export class DetailPhasesListComponent implements OnInit {
  public project: Project;
  public phases: Phase[];
  public dragging = false;
  public dateMessage = false;
  public now = Date.now();
  public version: number;

  constructor(
    private phaseService: PhaseService,
    private projectBusService: ProjectDataBusService,
    private announcementService: AnnouncementService
  ) {
    this.projectBusService.projectObservable.subscribe(
      (project) => (this.project = project)
    );
  }

  public async ngOnInit(): Promise<void> {
    this.load();
  }

  async getPhaseEnd() {
    let checkDate;
    this.phases.forEach((phase) => {
      phase.isStacked = moment(phase.startDate) < checkDate;
      checkDate = moment(phase.endDate);
    });
  }

  async deleteAnnouncement(announcement: Announcement): Promise<void> {
    await this.announcementService.remove(announcement);
    this.load();
  }

  async deletePhase(phase: Phase): Promise<void> {
    await this.phaseService.remove(phase);
    this.load();
  }

  async togglePhase(phase: Phase): Promise<void> {
    phase.isCollapsed = !phase.isCollapsed;
  }

  async toggleView(project: Project): Promise<void> {
    project.isCompact = !project.isCompact;
  }

  private async load() {
    this.phases = await this.phaseService.listByProject(this.project);
    this.getPhaseEnd();
  }
}
