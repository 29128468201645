<app-form-group [form]="form" errorPath="attachment" [formGroup]="form">
  <div class="multi-attachment__click">
    <app-input-file
      class="input-file-holder"
      [type]="type"
      [uploadImages]="true"
      [uploadPdfs]="true"
      [chooseVideo]="withVideo"
      (videoChosen)="addVideoAttachment($event)"
      [withCustomVideo]="true"
      [multiple]="true"
      [fullEntity]="true"
      (startUploading)="startUploading.emit()"
      (stopUploading)="stopUploading.emit()"
      formControlName="attachment"
    >
    </app-input-file>
  </div>
  <div class="multi-attachment__group" *ngIf="attachmentPreviews.length > 0">
    <div class="multi-attachment__list">
      <span>{{
        'multi_attachment_control.attachments.title' | translate
      }}</span>

      <div class="no-files" *ngIf="empty">
        {{ 'multi_attachment_control.media.empty' | translate }}
      </div>

      <div
        class="list"
        [dragula]="Sortable.ATTACHMENTS"
        [(dragulaModel)]="attachmentPreviews"
        (dragulaModelChange)="updateAttachmentsSortOrder($event, type)"
      >
        <div
          #file
          class="file"
          [attr.data-index]="i"
          (click)="select(attachment, i)"
          [class.pdf]="isPdf(attachment)"
          [class.active]="isActivePreview(attachment)"
          *ngFor="let attachment of attachmentPreviews; let i = index"
          [hidden]="isRemoved(i)"
        >
          <div
            class="file__image"
            [style.backgroundImage]="getPreviewImage(attachment, true)"
          ></div>
          <span *ngIf="isPdf(attachment)">{{
            'multi_attachment_control.media.pdf' | translate
          }}</span>
          <div
            [inlineSVG]="'/assets/img/icons/delete.svg'"
            class="file__remove"
            (click)="removeAttachment($event, attachment)"
          ></div>
          <div
            class="file__drag"
            [inlineSVG]="'/assets/img/icons/handle.svg'"
          ></div>
        </div>
      </div>

      <p class="alert alert-info" *ngIf="convertMessage">
        {{ 'multi_attachment_control.convert.warning' | translate }}
      </p>
    </div>
    <div class="multi-attachment__preview">
      <span>{{ 'multi_attachment_control.example.title' | translate }}</span>

      <div class="multi-attachment__preview__box">
        <div
          class="cover"
          *ngIf="activePreview && !isVideo(activePreview)"
          [style.backgroundImage]="getPreviewImage(activePreview)"
        >
          <span class="file-name" *ngIf="isPdf(activePreview)">{{
            activePreview.fileName
              ? activePreview.fileName
              : ('multi_attachment_control.media.pdf' | translate)
          }}</span>
          <div
            *ngIf="attachments.value[activeIndex]?.is360"
            class="multi-attachment__icon"
            inlineSVG="/assets/img/icons/360.svg"
            [attr.aria-label]="'multi_attachment_control.360' | translate"
          ></div>
        </div>

        <div class="cover" *ngIf="activePreview && isVideo(activePreview)">
          <video #videoPlayer controls width="100%" height="100%">
            <source [src]="getPreviewVideo(activePreview)" />
          </video>
        </div>

        <div formArrayName="attachments" *ngIf="withDescription">
          <div
            *ngFor="let attachment of attachments.controls; index as i"
            [formGroupName]="i"
            [hidden]="!isActiveTitle(i)"
          >
            <div class="multi-attachment__control-holder">
              <app-form-group
                [form]="attachment"
                errorPath="title"
                cssClass="multi-attachment__form-group__holder"
              >
                <input
                  [attr.placeholder]="
                    'multi_attachment_control.media.description' | translate
                  "
                  class="input input--m"
                  formControlName="title"
                />
              </app-form-group>

              <div
                class="multi-attachment__360-option"
                *ngIf="with360 && !isPdf(attachments.value[i])"
              >
                <label>{{ 'multi_attachment_control.360' | translate }}</label>
                <app-toggle formControlName="is360" [small]="true"></app-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-form-group>
