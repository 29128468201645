import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Survey } from '../interfaces/survey';
import { Project } from '../interfaces/project';
import { ProjectService } from './project.service';
import { SurveyQuestion } from '../interfaces/survey-question';
import { SurveyQuestionOption } from '../interfaces/survey-question-option';

@Injectable()
export class SurveyService {
  static base = '/surveys';

  constructor(private http: HttpClient) {}

  /**
   * @param {Project} project
   * @param {Survey} data
   * @returns {Promise<Survey>}
   */
  public create(project: Project, data: any): Promise<Survey> {
    data.project = '/api' + ProjectService.base + '/' + project.slug;
    return this.http
      .post<Survey>(environment.apiUrl + SurveyService.base, data)
      .toPromise();
  }

  /**
   * @param {Survey} survey
   * @returns {Promise<Survey>}
   */
  public async update(survey: Survey): Promise<Survey> {
    return await this.http
      .put<Survey>(
        environment.apiUrl + SurveyService.base + '/' + survey.id,
        survey
      )
      .toPromise();
  }

  /**
   * @param {Project} project
   * @returns {Promise<Survey[]>}
   */
  public listByProject(project: Project): Promise<Survey[]> {
    return this.http
      .get(
        environment.apiUrl +
          ProjectService.base +
          '/' +
          project.slug +
          SurveyService.base
      )
      .toPromise()
      .then((result) => <Survey[]>result);
  }

  /**
   * @param {number} id
   * @returns {Promise<Survey>}
   */
  public fetch(id: number): Promise<Survey> {
    return this.http
      .get<Survey>(environment.apiUrl + SurveyService.base + '/' + id)
      .toPromise();
  }

  /**
   * @param {Survey} survey
   * @returns {Promise<void>}
   */
  public async remove(survey: Survey): Promise<void> {
    await this.http
      .delete<Survey>(environment.apiUrl + SurveyService.base + '/' + survey.id)
      .toPromise();

    return;
  }

  /**
   * @param {SurveyQuestion} question
   * @returns {Promise<void>}
   */
  public async removeQuestion(question: SurveyQuestion): Promise<void> {
    await this.http
      .delete<SurveyQuestion>(
        environment.apiUrl + SurveyService.base + '/question/' + question.id
      )
      .toPromise();

    return;
  }

  /**
   * @param {SurveyQuestionOption} option
   * @returns {Promise<void>}
   */
  public async removeOption(option: SurveyQuestionOption): Promise<void> {
    await this.http
      .delete<SurveyQuestionOption>(
        environment.apiUrl +
          SurveyService.base +
          '/question/option/' +
          option.id
      )
      .toPromise();

    return;
  }

  public getStatistics(surveyId: number) {
    return this.http
      .get(
        environment.apiUrl + SurveyService.base + '/' + surveyId + '/statistics'
      )
      .toPromise();
  }

  public getExportCSV(survey: Survey) {
    return this.http
      .get(
        environment.apiUrl + SurveyService.base + '/' + survey.id + '/export',
        { responseType: 'text' }
      )
      .toPromise();
  }

  public async sendPush(survey: Survey) {
    return this.http
      .post(
        environment.apiUrl + SurveyService.base + '/' + survey.id + '/notify',
        {}
      )
      .toPromise();
  }
}
