import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { InputFileComponent } from 'src/app/components/input-file.component';
import { ProjectStatus } from 'src/app/enums/project-status';
import { Project } from 'src/app/interfaces/project';
import { SecurityVoter } from 'src/app/security/security-voter';
import { AccessService } from 'src/app/services/access.service';
import { ErrorService } from 'src/app/services/error.service';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { ProjectService } from 'src/app/services/project.service';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropboxComponent } from '../../../../components/image-cropbox.component';
import { LoadingDirective } from '../../../../directives/loading.directive';
import { NgIf } from '@angular/common';
import { QuillComponent } from '../../../../components/quill.component';
import { ToggleComponent } from '../../../../components/toggle.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { InputFileComponent as InputFileComponent_1 } from '../../../../components/input-file.component';
import { FormGroupComponent } from '../../../../components/form-group.component';
import { AccessDirective } from '../../../../directives/access.directive';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';

@Component({
  selector: 'app-detail-general-contact',
  templateUrl: './detail-general-contact.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AccessDirective,
    FormGroupComponent,
    InputFileComponent_1,
    InlineSVGModule,
    ToggleComponent,
    QuillComponent,
    NgIf,
    LoadingDirective,
    FormChangeDetectorDirective,
    ImageCropboxComponent,
    TranslateModule,
  ],
})
export class DetailGeneralContactComponent implements OnInit {
  @Output() formSaved = new EventEmitter();
  @Input() withSave;
  @ViewChild('chatPlaceHolderAvatarInput')
  chatPlaceholderAvatarInput: InputFileComponent;
  project: Project;
  contactLoading = false;
  uploading = false;
  ProjectStatus = ProjectStatus;
  contactForm: FormGroup;
  showEditor: boolean = false;
  canEdit: boolean = false;
  imageChangedEvent: any;
  startUploading = new EventEmitter();
  stopUploading = new EventEmitter();
  private placeholderAvatar: string;
  private placeholderAvatarThumbnail: string;

  constructor(
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private projectService: ProjectService,
    private projectDataBusService: ProjectDataBusService,
    private accessService: AccessService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.projectDataBusService.projectObservable.subscribe((project) => {
      this.project = project;
      this.contactForm.patchValue(project);
      if (this.project.create !== true) {
        this.accessService.accessControlList.subscribe(
          (acl) =>
            (this.canEdit = SecurityVoter.canEditProject(acl, this.project))
        );
      } else {
        this.accessService.accessControlList.subscribe(
          (acl) =>
            (this.canEdit =
              SecurityVoter.hasCreateRole(acl) ||
              SecurityVoter.hasCustomers(acl))
        );
      }
    });
  }

  createForm() {
    this.contactForm = this.formBuilder.group({
      chatPlaceholderName: ['', this.withSave && Validators.required],
      chatPlaceholderFunction: [''],
      chatPlaceholderPhoneNumber: [''],
      chatPlaceholderAvatar: [null],
      chatDescription: [''],
      chatEnabled: [true],
    });

    this.contactForm
      .get('chatPlaceholderAvatar')
      .valueChanges.subscribe((value) => {
        this.placeholderAvatar = value;
      });
  }

  public async saveContact() {
    this.errorService.markFormGroupTouchedAndDirty(this.contactForm);

    if (!this.contactForm.valid) {
      return;
    }

    this.contactLoading = true;
    this.projectDataBusService.emitIsLoading(true);
    this.project.chatEnabled = this.contactForm.get('chatEnabled').value;

    try {
      await this.projectService.updateTicketSettings(
        this.project,
        this.contactForm.value
      );
      this.formSaved.emit();
    } catch (error) {
      this.errorService.parseErrorsToForm(this.contactForm, error.error);
    } finally {
      this.contactLoading = false;
      this.projectDataBusService.emitIsLoading(false);
    }
  }

  inputFileStartUploading() {
    this.startUploading.emit();
  }

  inputFileStopUploading(event) {
    this.stopUploading.emit();
  }

  updatePlaceholderAvatarThumbnail(thumbnail: string): void {
    this.placeholderAvatarThumbnail = thumbnail;
  }

  getCurrentPlaceholderAvatar(): SafeStyle | string {
    if (this.placeholderAvatar == null) {
      return '';
    } else {
      let url;

      if (this.placeholderAvatarThumbnail != null) {
        url = this.placeholderAvatarThumbnail;
      } else {
        url = this.project.chatPlaceholderAvatarThumbnails?.small;
      }

      return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
    }
  }

  startEditing(event) {
    this.showEditor = true;
    this.imageChangedEvent = event;
  }

  stopEditing() {
    this.showEditor = false;
  }
}
