<div [formGroup]="generalForm">
    <app-form-group [form]="generalForm" errorPath="coverImage" class="bouwapp-group__row">
        <div class="bouwapp-group__item">
            <div class="bouwapp-group__item__header">
                <h3>{{ 'projects.detail.general.cover.title' | translate }}</h3>
                <p>{{ 'projects.detail.general.cover.format.info' | translate }}</p>
            </div>

            <div class="project-coverImage" [style.backgroundImage]="getCurrentCoverImage()">
                <div class="project-coverImage__button">
                    <app-input-file
                        #coverInput
                        [withEditor]="true"
                        formControlName="coverImage"
                        (previewUpdated)="updateCoverImageThumbnail($event)"
                        [withDragDrop]="false"
                        type="project-cover-image"
                        [uploadImages]="true"
                        (startEditing)="startEditingCoverImage($event)"
                        (stopEditing)="stopEditingCoverImage()"
                        (startUploading)="inputFileStartUploading()"
                        (stopUploading)="inputFileStopUploading($event)"
                    >
                        <img
                            src="/assets/img/icons/image-white.svg"
                            height="13"
                            [attr.alt]="'projects.detail.general.cover.upload.image' | translate"
                        />
                        {{ 'projects.detail.general.cover.upload' | translate }}
                    </app-input-file>
                </div>
            </div>
        </div>
    </app-form-group>

    <div class="bouwapp-group__row three">
        <div class="bouwapp-group__item">
            <app-form-group [form]="generalForm" errorPath="logo">
                <label for="logo">{{ 'projects.detail.general.logo.title' | translate }}</label>
                <div class="project-contact__image__self" [style.backgroundImage]="getCurrentLogo()">
                    <app-input-file
                        #logoInput
                        [withEditor]="true"
                        formControlName="logo"
                        (previewUpdated)="updateLogoThumbnail($event)"
                        [withDragDrop]="false"
                        type="project-logo"
                        [uploadImages]="true"
                        (startEditing)="startEditingLogo($event)"
                        (stopEditing)="stopEditingLogo()"
                        (startUploading)="inputFileStartUploading()"
                        (stopUploading)="inputFileStopUploading($event)"
                    >
                        <span inlineSVG="/assets/img/icons/photo--black.svg"></span>
                    </app-input-file>
                </div>
            </app-form-group>
        </div>

        <div *ngIf="!isConcept" class="bouwapp-group__item">
            <label for="status">
                {{ 'projects.detail.general.project.status.title' | translate }}
                <span class="status" [ngClass]="[getCurrentStatusClass()]"></span>
            </label>

            <select
                id="status"
                [attr.title]="'projects.detail.general.status.title' | translate"
                class="select select--m status"
                formControlName="status"
                appAccess
                role="manager"
                [project]="project"
                property="disabled"
            >
                <option value="PUBLISHED">
                    {{ 'projects.detail.general.status.published' | translate }}
                </option>
                <option value="UNPUBLISHED">
                    {{ 'projects.detail.general.status.unpublished' | translate }}
                </option>
                <option value="ARCHIVED">
                    {{ 'projects.detail.general.status.archived' | translate }}
                </option>
                <option value="PRIVATE">
                    {{ 'projects.detail.general.status.private' | translate }}
                </option>
            </select>

            <span *ngIf="generalForm.get('status').value === ProjectStatus.PRIVATE"
                  style="display: block; font-size: 1.4rem; margin-top: 1.2rem;">{{
                    'projects.detail.general.status.private.info' | translate
                }}</span>
        </div>

        <div class="bouwapp-group__item">
            <label for="language">
                {{ 'projects.detail.general.project.language.title' | translate }}
            </label>

            <select
                id="language"
                [attr.title]="
          'projects.detail.general.project.language.title' | translate
        "
                class="select select--m"
                formControlName="language"
                appAccess
                role="manager"
                [project]="project"
                property="disabled"
            >
                <option [value]="null" disabled selected hidden>
                    {{
                        'projects.detail.general.project.language.placeholder' | translate
                    }}
                </option>
                <option value="nl">
                    {{ 'projects.detail.general.project.language.dutch' | translate }}
                </option>
                <option value="en">
                    {{ 'projects.detail.general.project.language.english' | translate }}
                </option>
                <option value="da">
                    {{ 'projects.detail.general.project.language.danish' | translate }}
                </option>
                <option value="de">
                    {{ 'projects.detail.general.project.language.german' | translate }}
                </option>
            </select>
        </div>
    </div>

    <div class="bouwapp-group__row three">
        <div class="bouwapp-group__item">
            <app-form-group [form]="generalForm" errorPath="name">
                <label for="name">{{
                        'projects.detail.general.project.name.title' | translate
                    }}</label>
                <input
                    id="name"
                    type="text"
                    [placeholder]="
            'projects.detail.general.title.placeholder' | translate
          "
                    formControlName="name"
                    maxLength="50"
                    appAccess
                    role="manager"
                    [project]="project"
                    property="disabled"
                />
            </app-form-group>
        </div>
        <div class="bouwapp-group__item">
            <app-form-group [form]="generalForm" errorPath="participants">
                <label for="participants">{{
                        'projects.detail.general.project.owner.title' | translate
                    }}</label>
                <input
                    id="participants"
                    type="text"
                    formControlName="participants"
                    maxLength="75"
                    appAccess
                    role="manager"
                    [project]="project"
                    property="disabled"
                />
            </app-form-group>
        </div>
        <div class="bouwapp-group__item">
            <app-form-group [form]="generalForm" errorPath="sector">
                <label for="sector">{{
                        'projects.detail.general.project.sector' | translate
                    }}</label>
                <select
                    formControlName="sector"
                    id="sector"
                    [attr.title]="
            'projects.detail.general.project.sector.placeholder' | translate
          "
                    class="select select--m status"
                    appAccess
                    role="manager"
                    [project]="project"
                    property="disabled"
                >
                    <option [value]="null" disabled selected>
                        {{
                            'projects.detail.general.project.sector.placeholder' | translate
                        }}
                    </option>
                    <option [value]="ProjectSector.MOBILITY">
                        {{ ProjectSector.MOBILITY | translate }}
                    </option>
                    <option [value]="ProjectSector.HOUSING">
                        {{ ProjectSector.HOUSING | translate }}
                    </option>
                    <option [value]="ProjectSector.UTILITY_BUILDING">
                        {{ ProjectSector.UTILITY_BUILDING | translate }}
                    </option>
                    <option [value]="ProjectSector.ENERGY">
                        {{ ProjectSector.ENERGY | translate }}
                    </option>
                    <option [value]="ProjectSector.WATER">
                        {{ ProjectSector.WATER | translate }}
                    </option>
                    <option [value]="ProjectSector.RENOVATION_INFRA">
                        {{ ProjectSector.RENOVATION_INFRA | translate }}
                    </option>
                    <option [value]="ProjectSector.RENOVATION_BUILDING">
                        {{ ProjectSector.RENOVATION_BUILDING | translate }}
                    </option>
                </select>
            </app-form-group>
        </div>
    </div>

    <div class="bouwapp-group__row">
        <div class="bouwapp-group__item">
            <app-form-group [form]="generalForm" errorPath="description">
                <label>{{
                        'projects.detail.general.project.description.title' | translate
                    }}</label>
                <app-quill
                    [formGroup]="generalForm"
                    controlName="description"
                    [readOnly]="!canEdit"
                    placeholder="{{ 'projects.detail.general.description.placeholder' | translate }}"
                ></app-quill>
            </app-form-group>
        </div>
    </div>
</div>

<app-image-cropbox
    *ngIf="showCoverImageEditor"
    [imageFormElement]="generalForm.get('coverInput')"
    [imageInput]="coverInput"
    [imageChangedEvent]="imageChangedEvent"
    [aspectRatio]="8 / 3"
    [startUploadingEvent]="startUploading"
    [stopUploadingEvent]="stopUploading"
    (editorClosed)="stopEditingCoverImage()"
></app-image-cropbox>

<app-image-cropbox
    *ngIf="showLogoEditor"
    [imageFormElement]="generalForm.get('logo')"
    [imageInput]="logoInput"
    [imageChangedEvent]="imageChangedEvent"
    [aspectRatio]="1"
    [startUploadingEvent]="startUploading"
    [stopUploadingEvent]="stopUploading"
    (editorClosed)="stopEditingLogo()"
></app-image-cropbox>
