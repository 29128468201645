import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { DragulaService, DragulaModule } from 'ng2-dragula';
import { ModalComponent } from 'src/app/components/modal.component';
import { ProjectStatus } from 'src/app/enums/project-status';
import { Sortable } from 'src/app/enums/sortable';
import { Project } from 'src/app/interfaces/project';
import { Target } from 'src/app/interfaces/target';
import { SecurityVoter } from 'src/app/security/security-voter';
import { AccessService } from 'src/app/services/access.service';
import { ErrorService } from 'src/app/services/error.service';
import { ProjectService } from 'src/app/services/project.service';
import { TargetService } from 'src/app/services/target.service';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDeleteComponent } from '../../../../components/confirm-delete.component';
import { SuccessMessageComponent } from '../../../../components/success-message.component';
import { LoadingDirective } from '../../../../directives/loading.directive';
import { FormGroupComponent } from '../../../../components/form-group.component';
import { ModalComponent as ModalComponent_1 } from '../../../../components/modal.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LoaderComponent } from '../../../../components/loader.component';
import { AccessDirective } from '../../../../directives/access.directive';
import { NgIf, NgFor } from '@angular/common';
import { FormChangeDetectorDirective } from 'src/app/directives/form-change-detector.directive';

@Component({
  selector: 'app-detail-general-targets',
  templateUrl: './detail-general-targets.component.html',
  styles: [],
  standalone: true,
  imports: [
    NgIf,
    AccessDirective,
    LoaderComponent,
    DragulaModule,
    NgFor,
    InlineSVGModule,
    ModalComponent_1,
    ReactiveFormsModule,
    FormGroupComponent,
    LoadingDirective,
    SuccessMessageComponent,
    ConfirmDeleteComponent,
    TranslateModule,
    FormChangeDetectorDirective,
  ],
})
export class DetailGeneralTargetsComponent implements OnInit {
  @ViewChild('edit', { static: true }) private editModal: ModalComponent;
  @Input() project: Project;
  @Input() step: number;
  targetForm: FormGroup;
  targets: Target[] = [];
  Sortable = Sortable;
  formLoading = false;
  editingTarget: Target | null = null;
  targetSaved = false;
  canEdit: boolean = false;
  ProjectStatus = ProjectStatus;

  constructor(
    private targetService: TargetService,
    private errorService: ErrorService,
    private fb: FormBuilder,
    private dragulaService: DragulaService,
    private element: ElementRef,
    private projectService: ProjectService,
    private accessService: AccessService
  ) {
    this.createForm();

    this.dragulaService.createGroup(Sortable.TARGETS, {
      moves: (el, container, handle) => {
        return (
          handle.classList.contains('draggable') ||
          handle.parentElement.classList.contains('draggable') ||
          handle.parentElement.parentElement.classList.contains('draggable')
        );
      },
      mirrorContainer: this.element.nativeElement,
    });
  }

  updateTargetSortOrder(event) {
    let data = {};
    event.forEach((category, index) => {
      data[category.id] = index;
    });
    this.projectService.updateTargetSortOrder(this.project.slug, data);
  }

  async ngOnInit(): Promise<void> {
    this.targetService.updated$.subscribe((item) => this.load());

    if (this.project.create !== true) {
      this.accessService.accessControlList.subscribe(
        (acl) =>
          (this.canEdit = SecurityVoter.canEditProject(acl, this.project))
      );
    } else {
      this.accessService.accessControlList.subscribe(
        (acl) =>
          (this.canEdit =
            SecurityVoter.hasCreateRole(acl) || SecurityVoter.hasCustomers(acl))
      );
    }
  }

  ngOnDestroy() {
    this.dragulaService.destroy(Sortable.TARGETS);
  }

  createForm() {
    this.targetForm = this.fb.group({
      title: ['', Validators.required],
    });
  }

  openCreate() {
    this.editModal.open();
  }

  openEdit(target: Target) {
    this.editingTarget = target;
    this.targetForm.patchValue(target);
    this.editModal.open();
  }

  reset() {
    this.targetForm.reset();
    this.editingTarget = null;
  }

  async saveTarget() {
    this.errorService.markFormGroupTouchedAndDirty(this.targetForm);

    if (!this.targetForm.valid) {
      return;
    }

    this.formLoading = true;

    try {
      const data = this.targetForm.value as Target;

      if (this.editingTarget != null) {
        await this.targetService.update(this.editingTarget.slug, data);

        this.editingTarget.title = data.title;
      } else {
        data.project = this.project;

        await this.targetService.create(data);
      }

      this.targetSaved = true;
      this.editingTarget = null;
      this.editModal.close();
    } catch (error) {
      this.errorService.parseErrorsToForm(this.targetForm, error.error);
    } finally {
      this.formLoading = false;
    }
  }

  async deleteTarget(target: Target) {
    if (target.isGlobal)
      await this.targetService.deleteGlobal(target, this.project);
    else await this.targetService.delete(target);
  }

  private load() {
    this.targetService
      .listAll(this.project)
      .subscribe((items) => (this.targets = items));
  }
}
