<div class="bouwapp-cropBox">
  <div class="bouwapp-cropBox__overlay" (click)="cancel()"></div>
  <div class="bouwapp-cropBox__content">
    <div class="bouwapp-cropBox__content__head">
      <p>{{ 'projects.detail.general.crop.title' | translate }}</p>
      <div
        class="bouwapp-cropBox__content__head__close"
        inlineSVG="/assets/img/icons/close--dark.svg"
        (click)="cancel()"
      ></div>
    </div>
    <div class="bouwapp-cropBox__content__editor">
      <i [hidden]="cropperReady && !imgLoading" class="spinner"></i>
      <div class="bouwapp-cropBox__content__editor__area">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [resizeToWidth]="1920"
          [resizeToHeight]="1920"
          [onlyScaleDown]="true"
          [aspectRatio]="aspectRatio"
          (imageCropped)="imageCropped($event)"
          [output]="'base64'"
          (cropperReady)="cropperIsReady()"
          (loadImageFailed)="loadImageFailed()"
          [disabled]="uploading || imgLoading"
          [canvasRotation]="canvasRotation"
        >
        </image-cropper>
      </div>
    </div>
    <div
      class="bouwapp-cropBox__content__footer"
      [class.disabled]="!cropperReady"
    >
      <button
        class="button button--m button--ghost bg--grey--hover"
        [disabled]="uploading"
        (click)="cancel()"
      >
        {{ 'projects.detail.general.crop.cancel' | translate }}
      </button>

      <button
        class="button button--m button--flat bg--grey"
        [disabled]="imgLoading || uploading"
        (click)="rotate()"
      >
        {{ 'projects.detail.general.crop.rotate' | translate }}
      </button>

      <button
        class="button button--m button--flat bg--orange"
        [appLoading]="uploading"
        (click)="upload()"
      >
        {{ 'projects.detail.general.crop.submit' | translate }}
      </button>
    </div>
  </div>
</div>
