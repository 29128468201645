<app-loader *ngIf="!phases"></app-loader>

    <div class="bouwapp-header">
        <div class="bouwapp-header__text">
            <h1>{{ 'projects.detail.menu.phases' | translate }}</h1>
        </div>
    </div>

<div
  class="bouwapp-phases"
  *ngIf="phases"
  [ngClass]="{ isCompact: project.isCompact }"
>
  <div class="bouwapp-phases__start">
    <div class="bouwapp-phases__start__date" *ngIf="project">
      <div
        [inlineSVG]="'/assets/img/icons/message-START.svg'"
        class="icon"
      ></div>
      <p>{{ project.startDate | dateType : project.startType : 'long' }}</p>
      <div
        [inlineSVG]="'/assets/img/icons/info-dark.svg'"
        (click)="dateMessage = !dateMessage"
        appAccess
        role="manager"
        [project]="project"
      ></div>
    </div>

    <ul class="bouwapp-phases__start__filter">
      <li
        class="compact"
        [class.active]="project.isCompact"
        (click)="toggleView(project)"
      >
        <span></span>
        <span></span>
        <span></span>
      </li>
      <li
        class="expanded"
        [class.active]="!project.isCompact"
        (click)="toggleView(project)"
      >
        <span></span>
        <span></span>
        <span></span>
      </li>
    </ul>

    <p class="bouwapp-phases__start__info" *ngIf="dateMessage">
      {{ 'projects.detail.phase.date.info' | translate }}
    </p>
  </div>

  <div class="bouwapp-phases__overview">
    <div
      class="bouwapp-phases__phase"
      *ngFor="let phase of phases"
      [class.collapsed]="phase.isCollapsed"
      [class.stacked]="phase.isStacked"
      [class.hasMessages]="phase.announcements.length"
    >
      <div class="bouwapp-phases__phase__head" (click)="togglePhase(phase)">
        <p *ngIf="phase.name">
          {{
            phase.name.length > 50
              ? (phase.name | slice : 0 : 50) + '...'
              : phase.name
          }}
          /
          <span>
            {{
              'project.detail.phases.list.phase_title_appendix' +
                (!phase.endDate ? '.without_end' : '')
                | translate
                  : {
                      from: (phase.startDate | dateType : phase.startType),
                      till: (phase.endDate | dateType : phase.endType)
                    }
            }}
          </span>
        </p>
        <span [inlineSVG]="'/assets/img/icons/caret-down-dark.svg'"></span>
      </div>

      <div class="bouwapp-phases__phase__link" *ngIf="phase.isStacked"></div>

      <div class="bouwapp-phases__phase__bar">
        <div class="bouwapp-phases__phase__bar__self"></div>
        <div class="bouwapp-phases__phase__bar__text">
          <span>
            {{ phase.name }}
          </span>
        </div>
      </div>

      <div class="bouwapp-phases__phase__messages">
        <div class="bouwapp-phases__phase__message">
          <div class="bouwapp-phases__phase__message__top">
            <ul>
              <li>
                <span>{{
                  'projects.detail.announcement.tile.name' | translate
                }}</span>
                <span>{{ phase.name }}</span>
              </li>
              <li class="date">
                <span>{{
                  'projects.detail.announcement.tile.start' | translate
                }}</span>
                <span>{{
                  phase.startDate | dateType : phase.startType : 'long'
                }}</span>
              </li>
              <li class="date" [hidden]="!phase.endDate">
                <span>{{
                  'projects.detail.announcement.tile.end' | translate
                }}</span>
                <span>{{
                  phase.endDate | dateType : phase.endType : 'long'
                }}</span>
              </li>
              <li *ngIf="phase.attachments.length">
                <span>{{
                  'projects.detail.announcement.tile.attachment' | translate
                }}</span>
                <span>
                  {{ phase.attachments.length }}
                </span>
              </li>
            </ul>
          </div>

          <div class="bouwapp-phases__phase__message__bottom">
            <div [innerHTML]="phase.text | words : 64 : '...'"></div>
            <div appAccess role="manager" [project]="project">
              <a
                routerLink="/projects/{{ project.slug }}/phases/{{
                  phase.id
                }}/edit"
                >{{ 'projects.detail.announcement.tile.edit' | translate }}</a
              >
              <span
                (click)="
                  confirmDeletePhase.confirmDelete(phase);
                  $event.stopPropagation();
                  (false)
                "
                >{{
                  'projects.detail.announcement.tile.remove' | translate
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="alert alert-info" *ngIf="phases && phases.length == 0">
      {{ 'project.detail.phases.list.empty' | translate }}
    </div>

    <a
      *ngIf="phases && phases.length == 0"
      routerLink="/projects/{{ project.slug }}/phases/new"
      class="button button--l button--flat bg--orange"
      appAccess
      role="manager"
      [project]="project"
    >
      {{ 'project.detail.phases.list.new' | translate }}
    </a>

    <div *ngIf="phases && phases.length > 0" class="bouwapp-bottombar">
      <a
        *ngIf="phases"
        routerLink="/projects/{{ project.slug }}/phases/new"
        class="button button--l button--flat bg--orange"
        appAccess
        role="manager"
        [project]="project"
      >
        {{ 'project.detail.phases.list.new' | translate }}
      </a>
    </div>
  </div>

  <div class="bouwapp-phases__end" *ngIf="project.endDate">
    <div class="bouwapp-phases__end__date">
      <div [inlineSVG]="'/assets/img/icons/finish-flag.svg'" class="icon"></div>
      <p>{{ project.endDate | dateType : project.endType : 'long' }}</p>
      <div
        [inlineSVG]="'/assets/img/icons/info-dark.svg'"
        (click)="dateMessage = !dateMessage"
        appAccess
        role="manager"
        [project]="project"
      ></div>
    </div>

    <p class="bouwapp-phases__end__info" *ngIf="dateMessage">
      {{ 'projects.detail.phase.date.info' | translate }}
    </p>
  </div>
</div>

<app-confirm-delete
  #confirmDeleteAnnouncement
  (confirmed)="deleteAnnouncement($event)"
></app-confirm-delete>
<app-confirm-delete
  #confirmDeletePhase
  (confirmed)="deletePhase($event)"
></app-confirm-delete>
