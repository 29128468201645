<div class="notices__data__filter form-group" [formGroup]="form">
  <div class="notices__data__filter__checkboxes">
    <div class="checkbox">
      <input type="checkbox" id="statusOpen" formControlName="open" />
      <label for="statusOpen">{{
        'smart_reporting.notices.status.open' | translate
      }}</label>
    </div>
    <div class="checkbox">
      <input
        type="checkbox"
        id="statusInProgress"
        formControlName="inProgress"
      />
      <label for="statusInProgress">{{
        'smart_reporting.notices.status.in_progress' | translate
      }}</label>
    </div>
    <div class="checkbox">
      <input type="checkbox" id="statusClosed" formControlName="closed" />
      <label for="statusClosed">{{
        'smart_reporting.notices.status.closed' | translate
      }}</label>
    </div>
    <div class="checkbox">
      <input type="checkbox" id="statusDeleted" formControlName="deleted" />
      <label for="statusDeleted">{{
        'smart_reporting.notices.status.deleted' | translate
      }}</label>
    </div>
  </div>

  <ng-container *ngIf="project">
    <a
      appAccess
      role="manager"
      [project]="project"
      routerLink="/smart-reporting/notices/create/{{ projectSlug }}"
      class="btn notices__data__create"
    >
      {{ 'smart_reporting.notices.create' | translate }}
    </a>
  </ng-container>
  <a
    href="javascript:void(0)"
    (click)="exportCsv()"
    class="btn notices__data__export"
  >
    {{ 'smart_reporting.notices.export' | translate }}
  </a>
</div>

<table class="smart-reporting__notices__table">
  <thead>
    <tr>
      <th width="30%">
        {{ 'smart_reporting.notices.table.theme' | translate }}
      </th>
      <th width="20%">
        {{ 'smart_reporting.notices.table.name' | translate }}
      </th>
      <th width="15%">
        {{ 'smart_reporting.notices.table.date' | translate }}
      </th>
      <th width="10%">
        {{ 'smart_reporting.notices.table.status' | translate }}
      </th>
      <th width="10%">
        {{ 'smart_reporting.notices.table.followers' | translate }}
      </th>
      <th width="15%">
        {{ 'smart_reporting.notices.table.response_date' | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="notices === null">
      <td colspan="6">
        <app-loader [condensed]="true"></app-loader>
      </td>
    </tr>
    <tr *ngIf="notices && notices.length === 0">
      <td colspan="6">
        {{ 'smart_reporting.notices.table.empty' | translate }}
      </td>
    </tr>
    <ng-container *ngIf="notices">
      <tr
        *ngFor="let notice of notices"
        class="smart-reporting__notice"
        routerLink="/smart-reporting/notices/{{ notice.id }}"
      >
        <td>{{ notice.theme.title }}</td>
        <td>{{ notice.name }}</td>
        <td>{{ notice.createdAt | date : 'd MMM y' }}</td>
        <td>
          <span
            class="label"
            [class.label-primary]="
              SmartReportingNoticeStatus.OPEN === notice.status
            "
            [class.label-success]="
              SmartReportingNoticeStatus.CLOSED === notice.status
            "
            [class.label-info]="
              SmartReportingNoticeStatus.IN_PROGRESS === notice.status
            "
            [class.label-secondary]="
              SmartReportingNoticeStatus.DELETED === notice.status
            "
            >{{
              'smart_reporting.notices.table.status.' + notice.status
                | translate
            }}</span
          >
        </td>
        <td>{{ notice.amountOfSubscribers }}</td>
        <td>
          {{
            notice.lastResponseDate
              ? (notice.lastResponseDate | date : 'd MMM y')
              : '-'
          }}
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
