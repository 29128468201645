<div class="app">
  <main class="page">
    <div class="container">
      <div class="row align page__content">
        <div>
          <img [src]="logo" />
        </div>
        <h2>{{ 'two.factor.title' | translate }}</h2>
        <p *ngIf="!firstStep">{{ 'two.factor.description' | translate }}</p>
        <p *ngIf="firstStep">{{ 'two.factor.enter' | translate }}</p>
      </div>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <section class="container profile">
      <div class="row align">
        <div class="col-md-6" *ngIf="!loading">
          <form
            [formGroup]="form"
            *ngIf="firstStep"
            class="form"
            [@fadeInOut]="'in'"
            (ngSubmit)="submit()"
          >
            <label for="phoneNumber">{{
              'smart_reporting.notice_create.phoneNumber' | translate
            }}</label>
            <app-form-group [form]="form" errorPath="phoneNumber">
              <angular-intl-phone
                #phoneComponent
                formControlName="phoneNumber"
                [config]="phoneConfig"
              ></angular-intl-phone>
            </app-form-group>

            <button
              class="button button--m button--flat bg--orange"
              [appLoading]="loading"
            >
              {{ 'conversation.submit' | translate }}
            </button>
          </form>
          <form
            [formGroup]="twoFaForm"
            *ngIf="!firstStep"
            class="form"
            [@fadeInOut]="'in'"
            (ngSubmit)="submit()"
          >
            <app-form-group>
              <div *ngIf="resendSuccess">
                {{ 'two.factor.resendSuccess' | translate }}
              </div>
              <div class="errors">
                <div *ngIf="invalidCode" class="invalidCode">
                  <h3>{{ 'two.factor.invalidCode' | translate }}</h3>
                </div>
                <div *ngIf="invalidCode && loginCounter > 0">
                  {{ 'two.factor.remaining' | translate }}:
                  {{ 3 - loginCounter }}
                </div>
                <div *ngIf="error">
                  {{ 'two.factor.cannotResend' | translate }}
                </div>
              </div>
              <div class="code-input">
                <ng-container
                  *ngFor="let _ of [].constructor(6); let codeIndex = index"
                >
                  <input
                    type="text"
                    class="form-control"
                    [id]="'code' + codeIndex"
                    (keyup)="focusNext($event, codeIndex)"
                    [name]="'code' + codeIndex"
                    [formControlName]="'code' + codeIndex"
                    (paste)="handlePaste($event)"
                    maxlength="1"
                  />
                </ng-container>
              </div>
              <div
                class="invalidTwoFa errors"
                *ngIf="twoFaForm.touched && twoFaForm.invalid"
              >
                {{ 'two.factor.invalid' | translate }}
              </div>
            </app-form-group>
            <button
              class="button button--m button--flat bg--orange"
              [appLoading]="sended"
            >
              {{ 'two.factor.button' | translate }}
            </button>
            <button
              (click)="resend()"
              class="resend button button--m button--flat bg--grey"
              [appLoading]="sended"
            >
              {{ 'two.factor.resend' | translate }}
            </button>
          </form>
        </div>
      </div>
    </section>
  </main>
  <app-footer></app-footer>
</div>
