import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as striptags from 'striptags';
import { AiTextService } from '../services/ai-text.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { Project } from '../interfaces/project';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'app-ai-text',
  templateUrl: './ai-text.component.html',
  standalone: true,
  imports: [InlineSVGModule, RouterLink, TooltipModule, NgFor, TranslateModule]
})
export class AiTextComponent implements OnInit {
  @Input() textControl: FormControl;
  @Input() language: string;
  @Input() project: Project;
  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  languageLevelForm: FormGroup;
  activeActionStack: AiTextComponent.Action[] = [];
  textStack: string[] = [];
  isLoading: boolean = false;
  textLength: number = 0;
  showReportText: boolean = false;

  Object = Object;
  AiTextComponent = AiTextComponent;

  readonly languageLevels = {
    A1: 'ai-text.language-level.A1',
    A2: '',
    B1: 'ai-text.language-level.B1',
    B2: '',
    C1: 'ai-text.language-level.C1',
    C2: '',
  };

  readonly locale_language_map = {
    en: 'english',
    nl: 'dutch',
    de: 'german',
    da: 'danish',
  };

  constructor(
    private aiTextService: AiTextService,
    private formBuilder: FormBuilder
  ) {
    this.languageLevelForm = this.formBuilder.group({
      selectedLanguageLevel: 'B2',
    });
  }

  ngOnInit() {
    this.textControl.valueChanges.subscribe(() => {
      this.activeActionStack.length = 0;
      this.textStack.length = 0;
      this.textLength = striptags(this.textControl.value).length;
    });
  }

  public getLanguageNameByLocale(): string {
    return `projects.detail.general.project.language.${this.locale_language_map[this.language]}`;
  }

  public selectLanguageLevel(level: string) {
    this.languageLevelForm.patchValue({
      selectedLanguageLevel: level,
    });

    this.performAction(AiTextComponent.Action.REWRITE_LANGUAGE_LEVEL);
  }

  public async performAction(action: AiTextComponent.Action) {
    this.showReportText = true;
    this.activeActionStack.push(action);
    this.textStack.push(this.textControl.value);

    this.isLoading = true;
    this.loading.emit(true);

    this.textControl.patchValue(
      await this.getActionResult(action, this.textControl.value),
      { emitEvent: false }
    );

    this.isLoading = false;
    this.loading.emit(false);
  }

  private async getActionResult(
    action: AiTextComponent.Action,
    text: string
  ): Promise<string> {
    try {
      switch (action) {
        case AiTextComponent.Action.IMPROVE_TEXT:
          return await this.aiTextService.improveText(text, this.language);
        case AiTextComponent.Action.IMPROVE_GRAMMAR:
          return await this.aiTextService.improveGrammar(text, this.language);
        case AiTextComponent.Action.REWRITE_LANGUAGE_LEVEL:
          const level =
            this.languageLevelForm.controls.selectedLanguageLevel.value;
          return await this.aiTextService.rewriteLanguageLevel(
            text,
            level,
            this.language
          );
        case AiTextComponent.Action.ADD_TITLE:
          let title = await this.aiTextService.generateTitle(
            text,
            this.language
          );
          title = '<strong>' + title + '</strong><br><br>';
          return title + text;
      }
    } catch (error) {
      return text;
    }
  }

  public back() {
    this.activeActionStack.pop();
    this.textControl.patchValue(this.textStack.pop(), { emitEvent: false });
  }

  public getActiveAction(): AiTextComponent.Action {
    return this.activeActionStack[this.activeActionStack.length - 1];
  }
}

export namespace AiTextComponent {
  export enum Action {
    IMPROVE_TEXT = 'ai-text.action.improve-text',
    IMPROVE_GRAMMAR = 'ai-text.action.improve-grammar',
    REWRITE_LANGUAGE_LEVEL = 'ai-text.action.rewrite-language-level',
    ADD_TITLE = 'ai-text.action.add-title',
  }
}
