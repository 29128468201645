import { Injectable } from '@angular/core';
import { platform } from './platform.service';
import { TranslateService } from '@ngx-translate/core';
import { UserDataService } from './user-data.service';
import { filter, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import * as countries from 'i18n-iso-countries';

@Injectable()
export class LocaleService {
  public currentLocale: string;
  public localizedCountryNames: any = null;
  private initialized = new BehaviorSubject(false);
  private initialized$ = this.initialized.asObservable();
  private availableLocales = ['nl', 'en'];

  constructor(
    private translateService: TranslateService,
    private userDataService: UserDataService,
  ) {
    this.determineLocale();
    this.setupLocalizedCountryNames();
  }

  private async determineLocale() {
    // Get the browser language in 2 characters. e.g. nl, en or de
    let browserLocale = this.translateService.getBrowserLang();

    // We only support nl and en, so if there is a different locale. Default to en
    if(!this.availableLocales.includes(browserLocale)) {
      browserLocale = 'en';
    }

    this.currentLocale = browserLocale;

    // This call doesn't do anything useful, it only prevents a circular DI error.
    // If you remove this, that error will popup.
    await this.userDataService.retrieveLanguage();

    // Set the language
    await this.setLanguage(browserLocale);
    this.initialized.next(true);
    this.setupStyle();
  }

  public async setLanguage(language: string) {
    console.info('[info] Setting language', language);
    this.translateService.setDefaultLang(language);

    await this.translateService.use(language).toPromise();
    moment.locale(language);
  }

  public waitForInit() {
    return this.initialized$
      .pipe(
        filter((it) => !!it),
        take(1)
      )
      .toPromise();
  }

  private setupStyle() {
    document.documentElement.style.setProperty(
      '--primary',
      platform.color
    );
    document.documentElement.style.setProperty(
      '--logo',
      `url(${platform.logo})`
    );
    (document.getElementById('favicon') as HTMLLinkElement).href =
      platform.favicon;
  }

  public async setupLocalizedCountryNames() {
    if (this.currentLocale !== 'en' && countries.getSupportedLanguages().includes(this.currentLocale)) {
      const loadedLocale = await import(`i18n-iso-countries/langs/${this.currentLocale}.json`);
      countries.registerLocale(
        loadedLocale
      );
      const localizedCountries = countries.getNames(this.currentLocale);
      this.localizedCountryNames = {};
      Object.keys(localizedCountries).forEach((key) => {
        this.localizedCountryNames[key.toLowerCase()] = localizedCountries[key];
      });
    }
  }
}
