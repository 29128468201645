<div [ngClass]="{ container: getIsCustomer() }">
  <div *appVersion="1" [ngClass]="{ 'tabs-content': getIsCustomer() }">
    <app-loader *ngIf="!updates"></app-loader>

    <section class="updates" *ngIf="updates">
      <div class="updates__head flex flex-row">
        <h2>{{ 'projects.detail.update.list.title' | translate }}</h2>
        <a
          (click)="newUpdate()"
          class="btn btn-xlg"
          appAccess
          role="manager"
          [project]="project"
          >{{ 'projects.detail.update.list.create' | translate }}</a
        >
      </div>

      <div
        class="updates__list"
        infiniteScroll
        [scrollWindow]="true"
        [infiniteScrollContainer]="'div.app__content'"
        [fromRoot]="true"
        (scrolled)="loadMoreUpdates()"
      >
        <app-default-updates-tile
          [isCustomer]="isCustomer"
          *ngFor="let update of updates"
          [update]="update"
          [confirmDelete]="confirmDelete"
        ></app-default-updates-tile>

        <div class="update" *ngIf="project">
          <div class="update__timeline">
            <div class="date">
              {{ project.startDate | dateType : project.startType : 'short' }}
            </div>
          </div>
          <div class="update__box update__box--start">
            <div
              [inlineSVG]="'/assets/img/icons/flag.svg'"
              class="inline-svg__item flag-image"
            ></div>
            {{ 'projects.detail.update.list.start' | translate }}
          </div>
        </div>
      </div>
    </section>
  </div>

  <div *appVersion="2">
    <div class="bouwapp-header">
      <div class="bouwapp-header__text">
        <h1>{{ 'projects.detail.update.list.title' | translate }}</h1>
      </div>
      <div class="bouwapp-header__buttons">
        <a
          (click)="newUpdate()"
          class="secondary"
          appAccess
          role="manager"
          [project]="project"
        >
          <span
            class="icon"
            [inlineSVG]="'/assets/icons/add--white.svg'"
          ></span>
          <span>{{ 'projects.detail.update.list.create' | translate }}</span></a
        >
      </div>
    </div>

    <form [formGroup]="filterForm">
      <section class="updates">
        <div class="bouwapp-toolbar">
          <div>
            <input
              [attr.placeholder]="
                'projects.detail.update.list.search' | translate
              "
              type="search"
              class="search"
              formControlName="search"
              id="search"
            />
          </div>
        </div>

        <div class="filter">
          <div class="filter__title">
            <h3>{{ 'project.list.filter.title' | translate }}</h3>
          </div>

          <div class="filter__group" formArrayName="statuses">
            <label class="filter__label">{{
              'project.list.filter.status.title' | translate
            }}</label>
            <div class="checkboxes">
              <div
                *ngFor="let status of allStatuses; let index = index"
                class="checkbox"
              >
                <input
                  type="checkbox"
                  id="status{{ status }}"
                  [formControlName]="index"
                />
                <label for="status{{ status }}"
                  >{{ 'project.list.filter.status.' + status | translate }}
                  <span *ngIf="statusCount"
                    >({{ statusCount[status] }})</span
                  ></label
                >
              </div>
            </div>
          </div>

          <div class="filter__group">
            <label class="filter__label">{{
              'project.list.filter.date.title' | translate
            }}</label>
            <div class="dates">
              <label>{{
                'project.list.filter.date.startdate' | translate
              }}</label>
              <input
                formControlName="startDate"
                [value]=""
                type="datetime-local"
              />
              <label>{{
                'project.list.filter.date.enddate' | translate
              }}</label>
              <input
                formControlName="endDate"
                [value]=""
                type="datetime-local"
              />
            </div>
          </div>

          <div
            *ngIf="getIsCustomer()"
            class="filter__group"
            formArrayName="customers"
          >
            <label class="filter__label">{{
              'project.list.filter.customer.title' | translate
            }}</label>
            <div class="checkboxes">
              <div
                class="checkbox"
                *ngFor="let customer of customers; let index = index"
              >
                <input
                  type="checkbox"
                  id="customer{{ customer.id }}"
                  [formControlName]="index"
                />
                <label for="customer{{ customer.id }}">{{
                  customer.name
                }}</label>
              </div>
            </div>
          </div>

          <div
            *ngIf="getIsCustomer()"
            class="filter__group"
            formArrayName="projects"
          >
            <label class="filter__label">{{
              'project.list.projects.title' | translate
            }}</label>
            <div class="checkboxes">
              <div
                class="checkbox"
                *ngFor="let project of projects; let index = index"
              >
                <input
                  type="checkbox"
                  id="project{{ project.id }}"
                  [formControlName]="index"
                />
                <label for="project{{ project.id }}">{{ project.name }}</label>
              </div>
            </div>
          </div>
        </div>

        <div
          class="updates__list"
          infiniteScroll
          [scrollWindow]="true"
          [infiniteScrollContainer]="'div.app__content'"
          [fromRoot]="true"
          (scrolled)="loadMoreUpdates()"
        >
          <p *ngIf="updates && updates.length">
            {{
              'projects.detail.update.list.result.title'
                | translate : { updates: totalItems }
            }}
          </p>

          <app-loader *ngIf="!updates"></app-loader>

          <app-default-updates-tile
            [isCustomer]="isCustomer"
            *ngFor="let update of updates"
            [update]="update"
            [confirmDelete]="confirmDelete"
          ></app-default-updates-tile>

          <div class="no-results" *ngIf="updates && updates.length === 0">
            {{ 'projects.detail.update.list.empty' | translate }}
          </div>
        </div>
      </section>
    </form>
  </div>
</div>

<app-confirm-delete
  #confirmDelete
  (confirmed)="deleteConfirmed($event)"
></app-confirm-delete>
<app-update-picker
  [projects]="projects"
  [customers]="customers"
></app-update-picker>
