import { Component } from '@angular/core';
import { ProjectUser } from '../../../interfaces/project-user';
import { ErrorService } from '../../../services/error.service';
import { Project } from '../../../interfaces/project';
import { ProjectDataBusService } from '../../../services/project-data-bus.service';
import { UserService } from 'src/app/services/user.service';
import { ProjectUserService } from 'src/app/services/project-user.service';
import { ManageUsersService } from 'src/app/components/manage-users/manage-users.service';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorMessageComponent } from '../../../components/error-message.component';
import { SuccessMessageComponent } from '../../../components/success-message.component';
import { ListUsersComponent } from '../../../components/manage-users/list-users.component';
import { ManageUsersComponent } from '../../../components/manage-users/manage-users.component';

@Component({
    selector: 'app-default-projects-detail-settings',
    templateUrl: './detail-settings.component.html',
    standalone: true,
    imports: [ManageUsersComponent, ListUsersComponent, SuccessMessageComponent, ErrorMessageComponent, TranslateModule]
})
export class DetailSettingsComponent {
  public formSaved = false;
  public project: Project;

  public inviteSaved: boolean = false;
  public cannotSave: boolean = false;
  public editSaved: boolean = false;
  public formLoading: boolean = false;
  public deleted: boolean = false;
  public loading: boolean = false;

  public projectUsers: ProjectUser[] = [];
  public editingMapping: ProjectUser;

  public showCreateProjectUser = false;
  public projectUser: ProjectUser;

  constructor(
    private errorService: ErrorService,
    private userService: UserService,
    private projectDataBusService: ProjectDataBusService,
    private projectUserService: ProjectUserService,
    private manageUsersService: ManageUsersService
  ) {
    this.projectDataBusService.projectObservable.subscribe(
      (project) => (this.project = project)
    );
    this.loadUsers();
  }

  public async create(data: { projectUser: ProjectUser, formData: any }): Promise<void> {
    this.cannotSave = false;
    this.inviteSaved = false;
    this.cannotSave = false;

    const { permission } = this.manageUsersService.extractPrefixAndValue(data.formData.permissionType);
    data.formData.permissionType = permission;
    data.formData.project = this.project.id;


    try {
      await this.userService.invite(data.projectUser, data.formData);
    } catch (e) {
      this.cannotSave = true;
    }

    if (!this.cannotSave) {
      this.inviteSaved = true;
    }

    this.loadUsers();
  }

  public async update(data: { projectUser: ProjectUser, formData: any }) {

    this.formLoading = true;
    this.editSaved = false;
    this.cannotSave = false;

    const { prefix, permission } = this.manageUsersService.extractPrefixAndValue(data.formData.permissionType);
    data.formData.permissionType = permission;

    try {
      if (prefix === 'customer') {
        data.formData.customer = this.project.customer.id;
      } else {
        data.formData.project = this.project.id;
      }
      try {
        if (prefix === 'project' && data.projectUser.permission.entity === 'project') {
          await this.userService.updateInvite(data.projectUser.permission.id, data.formData);
        } else {
          await this.userService.updateInviteMultiple(
            data.projectUser,
            data.formData
          );
        }
      } catch (e) {
        this.cannotSave = true;
      }

      if (!this.cannotSave) {
        this.editSaved = true;
      }

      this.loadUsers();
    } catch (error) {
      console.error(error);
    } finally {
      this.formLoading = false;
    }
  }

  public async delete(projectUser: ProjectUser) {
    this.deleted = false;
    await this.userService.undoInvite(projectUser);
    this.loadUsers();
    this.deleted = true;
  }

  private async loadUsers(): Promise<void> {
    try {
      this.loading = true;

      await this.projectUserService
        .fetchListForProject(this.project)
        .then((result) => {
          this.projectUsers = result.map(item => ({
            ...item.projectUser,
            admin: item.project === undefined,
            permission: { permissionType: item.permissionType, id: item.id, entity: item.customer ? 'customer' : 'project' }
          }));
        });
    } catch (error) {
      this.errorService.logError(error);
    } finally {
      this.loading = false;
    }
  }
}
