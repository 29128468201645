<div class="container">
    <div class="bouwapp-header">
        <div class="bouwapp-header__text">
            <h1>{{ 'smart_reporting.notices.title' | translate }}</h1>
            <p>{{ 'smart_reporting.notices.description' | translate }}</p>
        </div>
    </div>
</div>
<div class='container'>
    <app-loader *ngIf="!projectList"></app-loader>

    <div class="notices smart-reporting__grow-container" *ngIf="projectList">
        <div class="notices__projects">
            <div
                *ngFor="let project of projectList"
                class="notices__projects__item"
                [class.notices__projects__item--active]="project.slug === activeProjectSlug"
                routerLink="/smart-reporting/notices/project/{{ project.slug }}"
            >
                <div class="notices__projects__icon">
                    <img
                        *ngIf="project.coverImageThumbnails"
                        [src]="getCoverImageByProject(project)"
                        [alt]="project.name"
                    />
                </div>

                <div class="notices__projects__item__text">
                    {{ project.name }}
                </div>
            </div>
        </div>

        <div class="notices__data">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
