import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { CollectionResponse } from '../interfaces/collection-response';
import { SmartReportingTheme } from '../interfaces/smart-reporting-theme';
import { environment } from '../../environments/environment';
import { Customer } from '../interfaces/customer';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Project } from '../interfaces/project';
import { SmartReportingNotice } from '../interfaces/smart-reporting-notice';
import { map } from 'rxjs/operators';
import { SmartReportingNoticeStatus } from '../enums/smart-reporting-notice-status';
import { SmartReportingNoticeResponse } from '../interfaces/smart-reporting-notice-response';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class SmartReportingService {
  noticeStateChanged = new Subject();
  public noticeStateChanged$ = this.noticeStateChanged.asObservable();
  private static OPEN_NOTICES = 'openNotices';

  constructor(
    private httpClient: HttpClient,
    private storage: LocalStorageService
  ) {}

  public listThemes(customer: Customer) {
    return this.httpClient
      .get<CollectionResponse<SmartReportingTheme>>(
        `${environment.apiUrl}/smart-reporting-themes?customer=${customer.id}`
      )
      .toPromise();
  }

  public createNotice(
    notice: SmartReportingNotice
  ): Promise<SmartReportingNotice> {
    return this.httpClient
      .post(`${environment.apiUrl}/smart-reporting-notices`, notice)
      .toPromise() as Promise<SmartReportingNotice>;
  }

  public createTheme(entity: SmartReportingTheme) {
    return this.httpClient
      .post<SmartReportingTheme>(
        `${environment.apiUrl}/smart-reporting-themes`,
        entity
      )
      .toPromise();
  }

  public editTheme(id: number, fields: SmartReportingTheme) {
    return this.httpClient
      .put<SmartReportingTheme>(
        `${environment.apiUrl}/smart-reporting-themes/${id}`,
        fields
      )
      .toPromise();
  }

  public deleteTheme(id: number) {
    return this.httpClient
      .delete<SmartReportingTheme>(
        `${environment.apiUrl}/smart-reporting-themes/${id}`
      )
      .toPromise();
  }

  public listNotices(
    slug: string,
    statuses: SmartReportingNoticeStatus[] = []
  ): Observable<SmartReportingNotice[]> {
    return this.httpClient
      .get(
        `${
          environment.apiUrl
        }/projects/${slug}/smart-reporting-notices?pagination=false&order[createdAt]=desc${statuses
          .map((status) => `&status[]=${status}`)
          .join('')}`
      )
      .pipe(map((r) => r['hydra:member'])) as Observable<
      SmartReportingNotice[]
    >;
  }

  public fetchNotice(id: number): Promise<SmartReportingNotice> {
    return this.httpClient
      .get(`${environment.apiUrl}/smart-reporting-notices/${id}`)
      .toPromise() as Promise<SmartReportingNotice>;
  }

  public postResponse(
    response: SmartReportingNoticeResponse
  ): Promise<SmartReportingNoticeResponse> {
    return this.httpClient
      .post(`${environment.apiUrl}/smart-reporting-notice-responses`, response)
      .toPromise() as Promise<SmartReportingNoticeResponse>;
  }

  public updateStatus(
    notice: SmartReportingNotice,
    status: SmartReportingNoticeStatus,
    removalReason?: string
  ): Observable<SmartReportingNotice> {
    return this.httpClient.put(
      `${environment.apiUrl}/smart-reporting-notices/${notice.id}/status`,
      {
        status,
        removalReason,
      }
    ) as Observable<SmartReportingNotice>;
  }

  public countOpenNotices() {
    return Observable.create(async (observer) => {
      const openNOtices: number = await this.storage.retrieve(
        SmartReportingService.OPEN_NOTICES
      );

      if (openNOtices != null) {
        observer.next(openNOtices);
      }

      let count: number = (<any>(
        await this.httpClient
          .get(`${environment.apiUrl}/smart-reporting-notices/open/count`)
          .toPromise()
      )).count;
      count = count == null ? 0 : count;

      await this.storage.store(SmartReportingService.OPEN_NOTICES, count);
      observer.next(count);
    });
  }
}
