<app-loader *ngIf="edit && !phase"></app-loader>

<!-- New/Edit Phase -->
<section class="new-phase projects-detail" [hidden]="edit && !phase">
  <form [formGroup]="form" appFormChangeDetector (ngSubmit)="save()">
    <div class="bouwapp-box full">
      <div class="bouwapp-box__title">
        <h2 *ngIf="!edit">
          {{ 'project.detail.phases.edit.new' | translate }}
        </h2>
        <h2 *ngIf="edit">
          {{ 'project.detail.phases.edit.edit' | translate }}
        </h2>
      </div>

      <div class="bouwapp-box__self">
        <app-form-group [form]="form" errorPath="name">
          <label for="name"
            >{{ 'project.detail.phases.edit.name' | translate }}*</label
          >
          <input
            type="text"
            class="input input--m"
            formControlName="name"
            id="name"
            maxlength="42"
          />
        </app-form-group>

        <app-form-group [form]="form" errorPath="text">
          <label>{{ 'project.detail.phases.edit.text' | translate }}*</label>
          <app-quill [formGroup]="form" controlName="text"></app-quill>
        </app-form-group>

        <div class="form-split">
          <div>
            <div class="form-date">
              <p>{{ 'project.detail.phases.edit.start' | translate }}</p>
              <app-form-group [form]="form" errorPath="startDate">
                <app-date-picker
                  formControlName="startDate"
                  [typeControl]="form.get('startType')"
                ></app-date-picker>
              </app-form-group>
            </div>
          </div>
          <div>
            <div class="form-date">
              <p>{{ 'project.detail.phases.edit.end' | translate }}</p>
              <app-form-group [form]="form" errorPath="endDate">
                <app-date-picker
                  formControlName="endDate"
                  [typeControl]="form.get('endType')"
                ></app-date-picker>
              </app-form-group>
            </div>
          </div>
        </div>

        <div *ngIf="!dateRangeValid" class="form-date__notice combi">
          {{ 'project.detail.phases.edit.date.invalid' | translate }}
        </div>

        <div class="form-group multi-attachment">
          <label>{{ 'project.detail.phases.edit.document' | translate }}</label>
          <app-multi-attachment-control-v2
            type="project-phase-document"
            formControlName="attachments"
            [id]="phase?.id"
            previewBasePath="/project-phase/document"
            [withVideo]="false"
            [withDescription]="false"
            (startUploading)="startUploading()"
            (stopUploading)="stopUploading()"
          ></app-multi-attachment-control-v2>
        </div>
      </div>
    </div>

    <div class="bouwapp-bottombar">
      <a
        class="button button--l button--outlined bg--grey--hover"
        routerLink="/projects/{{ project.slug }}/phases"
      >
        {{ 'project.detail.phases.edit.cancel' | translate }}
      </a>
      <button
        class="button button--l button--flat bg--orange"
        [appLoading]="loading || uploading"
      >
        {{ 'project.detail.phases.edit.save' | translate }}
      </button>
    </div>
  </form>
</section>
