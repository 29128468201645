<div class="bouwapp-header">
  <div class="bouwapp-header__text">
    <h1>{{ 'project.detail.targets.title' | translate }}</h1>
    <p *ngIf="project.status == ProjectStatus.CONCEPT">
      {{ 'projects.detail.concept.step.targets.title' | translate }}
    </p>
    <p *ngIf="project.status == ProjectStatus.CONCEPT">
      {{ 'project.detail.targets.description' | translate }}
    </p>
    <p
      [innerHTML]="'project.detail.targets.project.description' | translate"
    ></p>
  </div>

  <div class="bouwapp-header__buttons">
    <button
      class="button button--m button--flat bg--orange"
      (click)="openCreate()"
      appAccess
      role="manager"
      [project]="project"
    >
      {{ 'project.detail.targets.project.create' | translate }}
    </button>
  </div>
</div>

<section class="targets form-group">
  <div class="targets__group">
    <app-loader *ngIf="!targets"></app-loader>

    <ul *ngIf="targets.length === 0">
      <li class="empty">
        <p>{{ 'project.detail.targets.empty' | translate }}</p>
      </li>
    </ul>

    <ul
      [hidden]="!targets"
      [dragula]="Sortable.TARGETS"
      [(dragulaModel)]="targets"
      (dragulaModelChange)="updateTargetSortOrder($event)"
    >
      <li *ngFor="let target of targets" class="isCustom">
        <span
          [inlineSVG]="'/assets/img/icons/handle.svg'"
          class="draggable"
          *ngIf="canEdit"
        ></span>
        <p>{{ target.title }}</p>
        <div appAccess role="manager" [project]="project">
          <div
            [inlineSVG]="'/assets/img/icons/edit-small.svg'"
            (click)="openEdit(target)"
            class="tile-action tile-edit"
            *ngIf="!target.isGlobal"
          ></div>
          <div
            [inlineSVG]="'/assets/img/icons/delete.svg'"
            (click)="confirmDelete.confirmDelete(target)"
            class="tile-action tile-delete"
          ></div>
        </div>
      </li>
    </ul>
  </div>
</section>

<section class="projects targets create">
  <app-modal
    [title]="
      (editingTarget
        ? 'project.detail.targets.edit.edit'
        : 'project.detail.targets.edit.create'
      ) | translate
    "
    #edit
    (onClose)="reset()"
  >
    <div class="modal-text">
      {{ 'project.detail.targets.edit.text' | translate }}
    </div>

    <form [formGroup]="targetForm" appFormChangeDetector>
      <app-form-group errorPath="title" [form]="targetForm">
        <label>{{ 'project.detail.targets.edit.title' | translate }}</label>
        <input type="text" class="input input--m" formControlName="title" />
      </app-form-group>

      <div class="flex buttons">
        <button
          type="button"
          class="button button--l button--flat bg--orange"
          (click)="saveTarget()"
          [appLoading]="formLoading"
        >
          {{ 'project.detail.targets.edit.save' | translate }}
        </button>
      </div>
    </form>
  </app-modal>
</section>

<app-success-message [hidden]="!targetSaved" [onScreen]="true">
  {{ 'project.detail.targets.edit.success' | translate }}
</app-success-message>

<app-confirm-delete
  #confirmDelete
  (confirmed)="deleteTarget($event)"
  [message]="'project.detail.targets.remove.warning' | translate"
></app-confirm-delete>
