<div class="container tickets">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <h1>{{ 'projects.detail.settings.title' | translate }}</h1>
    </div>
  </div>

  <div class="bouwapp-box full">
    <div class="bouwapp-box__title">
      <h2>
        {{ 'project.detail.contact.title' | translate }}
      </h2>
    </div>
    <div class="bouwapp-box__self">
      <app-detail-general-contact
        (formSaved)="formSaved()"
        [withSave]="true"
      ></app-detail-general-contact>
    </div>
  </div>

  <div class="bouwapp-box full">
    <div class="bouwapp-box__title">
      <h2>
        {{ 'project.detail.settings.notifications.title' | translate }}
      </h2>
    </div>
    <div class="bouwapp-box__self">
      <p>
        {{ 'project.detail.settings.notifications.description' | translate }}
      </p>

      <div class="ticket-notification">
        <span
          *ngIf="
            notificationForm.get('ticketContactEmails')['controls'].length > 0
          "
          >{{ 'project.detail.settings.notifications.label' | translate }}</span
        >
        <form
          [formGroup]="notificationForm"
          (submit)="saveNotifications()"
          *ngIf="project"
          appAccess
          role="manager"
          [project]="project"
          property="readonly"
        >
          <app-form-group
            [form]="notificationForm"
            errorPath="ticketContactEmails"
          >
            <ul>
              <li
                *ngFor="
                  let email of notificationForm.get('ticketContactEmails')[
                    'controls'
                  ];
                  let i = index
                "
                formArrayName="ticketContactEmails"
              >
                <div [formGroupName]="i">
                  <input
                    type="email"
                    class="input input--m"
                    [ngClass]="{ invalid: email.invalid }"
                    [attr.placeholder]="
                      'project.detail.settings.notifications.email.placeholder'
                        | translate
                    "
                    formControlName="ticketContactEmail"
                  />
                </div>
                <span
                  *ngIf="i > 0"
                  (click)="deleteContactEmail(i)"
                  inlineSVG="/assets/img/icons/delete.svg"
                ></span>
              </li>
            </ul>
          </app-form-group>
          <div class="flex flex-row">
            <button
              class="button button--m button--flat bg--orange"
              [appLoading]="notificationLoading"
              appAccess
              role="manager"
              [project]="project"
            >
              {{ 'project.detail.settings.notifications.save' | translate }}
            </button>
            <span
              (click)="addEmptyNotificationMail()"
              appAccess
              role="manager"
              [project]="project"
              >{{
                'project.detail.settings.notifications.new' | translate
              }}</span
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
