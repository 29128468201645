<div class="projects-settings__users">
    <ul class="projects-settings__users__head">
        <li>
            {{ 'project.detail.settings.users.table.name.role' | translate }}
        </li>
        <li>
            {{ 'project.detail.settings.users.table.email.phone' | translate }}
        </li>
        <li>
            {{ 'project.detail.settings.users.table.external' | translate }}
        </li>
        <li>
            {{
            'project.detail.settings.users.table.external.type' | translate
            }}
        </li>
        <li>
            {{ 'project.detail.settings.users.table.actions' | translate }}
        </li>
    </ul>
    <app-loader *ngIf="loading"></app-loader>
    <div class="projects-settings__users__content" [hidden]="loading">
        <table>
            <tbody>
                <tr *ngFor="let user of projectUsers">
                    <td [attr.data-th]="'project.detail.settings.users.table.name.role' | translate">
                        <span class="user-type">
                            {{ user.firstName }}
                            {{ user.lastName }} <br />
                            <span [class]="getIconForPermission(user).type">
                                <span [inlineSVG]="getIconForPermission(user).icon"></span>
                                <span>{{ 'project.detail.settings.users.table.type.' + getIconForPermission(user).type |
                                    translate }}</span>
                            </span>
                        </span>
                    </td>
                    <td [attr.data-th]="'project.detail.settings.users.table.email.phone' | translate">
                        {{ user.user.email }} <br />
                        {{ user.phoneNumber }}
                    </td>
                    <td [attr.data-th]="'project.detail.settings.users.table.external' | translate">
                        <span *ngIf="user.lastLogEntry">{{
                            user.lastLogEntry.executedAt
                            | date : 'HH:mm dd-MM-yyyy'
                            }}</span>
                        <span *ngIf="!user.lastLogEntry">-</span>
                    </td>
                    <td [attr.data-th]="'project.detail.settings.users.table.external.type' | translate">
                        <span *ngIf="user.lastLogEntry">{{ user.lastLogEntry.entityType | translate }}
                            {{ user.lastLogEntry.action | translate }}</span>
                        <span *ngIf="!user.lastLogEntry">-</span>
                    </td>
                    <td [attr.data-th]="'project.detail.settings.users.table.actions' | translate">
                        <ng-container *ngIf="user.id !== projectUser.id && hasPermission(user)">
                            <div [inlineSVG]="'/assets/img/icons/edit-small.svg'" (click)="triggerEdit(user)"
                                class="tile-action tile-edit inline-svg__item"></div>
                            <div [inlineSVG]="'/assets/img/icons/delete.svg'" (click)="triggerDelete(user)"
                                class="tile-action tile-delete inline-svg__item"></div>
                        </ng-container>
                    </td>
                </tr>
                <tr *ngIf="isEmpty()">
                    <td colspan="4">
                        {{ 'project.detail.settings.users.table.empty' | translate }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>